import React, { useState } from "react";
import {
  CalendarDays,
  ShoppingBasket,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import moment from "moment";
import BasketModal from "./BasketModal";
import LoadingSpinner from "../../../components/LoadingSpinner";

const BasketCard = ({
  basketName,
  lastUpdated,
  description,
  basketId,
  onAccept,
  trades,
  funds,
  date,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="bg-[#2F4F7D] relative font-poppins w-full rounded-xl shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 flex flex-col min-h-[300px]">
        <div className="flex-grow">
          <div className="flex flex-col items-start justify-between mx-5 border-b border-[#ffffff]/20">
            <div className="flex space-x-2 py-2">
              <p className="text-[14px] font-poppins text-[#ffffff]/80 font-light capitalize">
                Basket Order
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start justify-between px-6 py-3">
            <div className="flex flex-row items-center space-x-3 max-h-[30px]">
              <div className="flex flex-row items-center gap-3 ">
                <div className=" bg-[#4A7AAF] p-2 rounded-full ">
                  <ShoppingBasket size={18} className="text-white" />
                </div>
                <p className=" text-xl font-medium font-poppins text-[#ffffff] capitalize">
                  {basketName}
                </p>
              </div>

              <div className="flex flex-col">
                {/* <p className="text-xl font-medium font-poppins text-[#ffffff] capitalize">
                  {basketName}
                </p> */}
                <span className="text-[12px] text-[#ffffff]/80 font-light">
                  {description?.split(" ").slice(0, 6).join(" ")}
                </span>
              </div>
            </div>
          </div>

          <div className="px-6 mt-2">
            <div className="bg-[#1D3A5F] rounded-md p-3">
              <div
                className={`space-y-1 h-12 max-h-20 ${
                  expanded ? "h-20 overflow-y-auto custom-scroll" : ""
                }`}
              >
                {trades
                  .slice(0, expanded ? trades.length : 2)
                  .map((trade, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <div className="w-2 h-2 bg-[#4A7AAF] rounded-full"></div>
                      <p className="text-[14px] font-poppins font-normal text-[#ffffff]/90">
                        {trade.searchSymbol} | {trade.Strike} |{" "}
                        {trade.OptionType}
                      </p>
                    </div>
                  ))}
              </div>
              {trades.length > 2 && (
                <button
                  className="w-full text-[12px] text-[#ffffff]/80 mt-3 flex items-center justify-center transition-colors duration-200 hover:text-[#ffffff]"
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? (
                    <>
                      View Less <ChevronUp size={16} className="ml-1" />
                    </>
                  ) : (
                    <>
                      View More <ChevronDown size={16} className="ml-1" />
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 px-6 pb-4">
          <div className="flex flex-row items-center justify-between pb-3">
            <div className="text-[#ffffff] text-xs font-medium flex flex-row items-center">
              <CalendarDays size={16} className="text-[#ffffff]/80" />
              <span className="ml-2">{moment(date).format("Do MMM YYYY")}</span>
              <span className="mx-2">|</span>
              <span>{moment(date).format("HH:mm A")}</span>
            </div>
          </div>
          <button
            className="w-full h-10 px-4 rounded-[4px] bg-white text-[#1D3A5F] text-xs sm:text-[13px] lg:text-[13px] font-poppins font-semibold flex items-center justify-center transition-colors duration-200 hover:bg-[#F0F0F0]"
            onClick={() =>
              onAccept({
                basketName,
                lastUpdated,
                description,
                basketId,
                trades,
              })
            }
          >
            <span className="w-full h-full flex items-center justify-center">
              {loading === true ? <LoadingSpinner /> : "Accept Basket"}
            </span>
          </button>
        </div>
      </div>

      <BasketModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        basketName={basketName}
        lastUpdated={lastUpdated}
        description={description}
        basketId={basketId}
        onAccept={onAccept}
        loading={loading}
        funds={funds}
      />
    </>
  );
};

export default BasketCard;
