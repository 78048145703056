import React from "react";
import { Link } from "react-router-dom";
import { AlertCircle } from "lucide-react";
import PositionCard from "./PositionCard";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";

const OpenPositions = ({ data }) => {
  const { getLTPForSymbol } = useWebSocketCurrentPrice(data);

  return (
    <section className="h-full lg:h-[calc(100vh-210px)] lg:mt-0">
      {data.length !== 0 ? (
        <div className="bg-white hidden lg:block w-full h-[calc(100vh-380px)] pb-4 lg:pb-2 lg:h-[calc(100vh-280px)] overflow-auto custom-scroll">
          <table className="w-full">
            <thead className="bg-gray-50 text-sm text-left sticky top-0 z-10">
              <tr className="border-b border-gray-200">
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Stock
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Qty
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  LTP
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Avg. Cost
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Invested
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Type
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Unrealized P&L
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data?.map((ele, i) => {
                const ltp = getLTPForSymbol(ele?.Symbol);
                const value = ltp - ele?.tradedPrice;
                const pnl = value * ele?.tradedQty;
                return (
                  <tr
                    key={i}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {ele?.Symbol || "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      {ele?.tradedQty || "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{parseFloat(getLTPForSymbol(ele?.Symbol)).toFixed(2)}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{ele?.tradedPrice || "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{ele?.tradedQty && ele?.tradedPrice
                        ? (ele?.tradedQty * ele?.tradedPrice).toFixed(2)
                        : "0.00"}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-center">
                      {ele?.Type.trim().toLowerCase() === "buy" ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-emerald-100 text-emerald-800 capitalize">
                          {ele?.Type.trim().toLowerCase()}
                        </span>
                      ) : (
                        ele?.Type.trim().toLowerCase() === "sell" && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 capitalize">
                            {ele?.Type.trim().toLowerCase()}
                          </span>
                        )
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-center">
                      {ele?.Symbol ? (
                        pnl > 0 ? (
                          <span className="text-emerald-600">
                            +₹{pnl.toFixed(2)}
                          </span>
                        ) : pnl < 0 ? (
                          <span className="text-red-600">
                            -₹{Math.abs(pnl).toFixed(2)}
                          </span>
                        ) : (
                          <span className="text-gray-600">₹0.00</span>
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="hidden lg:flex flex-col items-center justify-center h-[calc(100vh-360px)]">
          <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            No Active Positions
          </h3>
          <p className="text-gray-500 text-center mb-6">
            Start placing orders to view your active positions here
          </p>
          <Link
            to="/stock-recommendation"
            className="inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
          >
            View Trade Advices
          </Link>
        </div>
      )}

      {/* Mobile View */}
      <div className="lg:hidden flex flex-col w-full h-full">
        {data.length !== 0 ? (
          data.map((ele, i) => {
            const ltp = getLTPForSymbol(ele?.Symbol);
            const value = ltp - ele?.tradedPrice;
            const pnl = value * ele?.tradedQty;
            return (
              <PositionCard
                key={i}
                qty={ele?.tradedQty}
                avgPrice={ele?.tradedPrice}
                symbol={ele?.Symbol}
                pnl={ele?.Symbol ? pnl : 0}
                totalInvested={ele?.tradedQty * ele?.buyPrice}
                ltp={ltp}
                type={ele.Type}
              />
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center px-4 py-16">
            <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              No Active Positions
            </h3>
            <p className="text-gray-500 text-center mb-6">
              Start placing orders to view your active positions here
            </p>
            <Link
              to="/stock-recommendation"
              className="inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            >
              View Trade Advices
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default OpenPositions;