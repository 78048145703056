import React from "react";
import DatePicker from "../ui/date-picker";

const InputField = ({
  label,
  id,
  name,
  type = "text",
  value,
  onChange,
  required = false,
  placeholder,
  disabled = false,
}) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      // className={`px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
      //   disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white"
      // }`}
      className="text-black px-3 py-2 border border-gray-300 rounded-md shadow-sm"
    />
  </div>
);

const SelectField = ({
  label,
  id,
  name,
  value,
  onChange,
  options,
  required = false,
  placeholder,
}) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className="text-black px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export const ClientFormFields = ({
  data,
  onInputChange,
  setExpiryDate,
  expiryDate,
  dateOfBirth,
  setDateOfBirth,
  emailEdit = false,
}) => {
  const kycStatusOptions = [
    { value: "Verified", label: "Verified" },
    { value: "Unverified", label: "Unverified" },
  ];

  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 gap-6">
      <InputField
        label="Email"
        id="clientEmail"
        name="clientEmail"
        type="email"
        value={data.clientEmail}
        onChange={onInputChange}
        required
        placeholder="Enter Email"
        disabled={emailEdit}
        className="text-black"
      />

      <InputField
        label="Name"
        id="clientName"
        name="clientName"
        value={data.clientName}
        onChange={onInputChange}
        required
        placeholder="Enter Name"
        className="text-black"
      />

      <InputField
        label="Country Code"
        id="countryCode"
        name="countryCode"
        value={data.countryCode}
        onChange={onInputChange}
        required
        placeholder="e.g. +91"
        className="text-black"
      />

      <InputField
        label="Phone"
        id="phone"
        name="phone"
        type="tel"
        value={data.phone}
        onChange={onInputChange}
        required
        placeholder="Enter Phone"
        className="text-black"
      />

      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Date of Birth
        </label>
        <DatePicker
          date={dateOfBirth}
          setDate={setDateOfBirth}
          placeholder="Choose Date of Birth"
          className="w-full text-black"
        />
      </div>

      <InputField
        label="Location"
        id="location"
        name="location"
        value={data.location}
        onChange={onInputChange}
        placeholder="Enter Location"
        className="text-black"
      />

      <InputField
        label="Telegram ID"
        id="telegram"
        name="telegram"
        value={data.telegram}
        onChange={onInputChange}
        placeholder="Enter Telegram ID"
        className="text-black"
      />

      <InputField
        label="PAN/KYC"
        id="pan"
        name="pan"
        value={data.pan}
        onChange={onInputChange}
        placeholder="Enter PAN/KYC Number"
        className="text-black"
      />

      <SelectField
        label="KYC Validation Status"
        id="kycStatus"
        name="kycStatus"
        value={data.kycStatus}
        onChange={onInputChange}
        options={kycStatusOptions}
        placeholder="Select KYC status"
        className="text-black"
      />

      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Plan Expiry Date
          <span className="ml-1 text-red-500">*</span>
        </label>
        <DatePicker
          date={expiryDate}
          setDate={setExpiryDate}
          placeholder="Choose Plan Expiry Date"
          className="w-full text-black"
          expirySet={true}
        />
      </div>
    </div>
  );
};

export default ClientFormFields;
