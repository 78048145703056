import { useState } from "react"
import { RotateCw, Eye, CheckCircle2, Link } from "lucide-react"
import FormatDateTime from "../../utils/formatDateTime"
import LoadingSpinner from "../../components/LoadingSpinner"

const StatusIndicator = ({ count, total, onClick = null }) => {
  const percentage = (count / total) * 100
  const isComplete = percentage === 100

  return (
    <button
      onClick={onClick}
      className={`w-8 h-8 rounded-full flex items-center justify-center transition-colors ${
        onClick ? "hover:bg-gray-100" : ""
      } ${isComplete ? "text-emerald-500" : percentage > 0 ? "text-blue-500" : "text-gray-300"}`}
    >
      {isComplete ? <CheckCircle2 className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
    </button>
  )
}

export const TableRow = ({
  item,
  setNotificationModal,
  setSelectedItem,
  handleResendAdvice,
  loadingAdviceId,
  advisorSpecifier
}) => {
  const isLoading = loadingAdviceId === item.adviseId;
  const metrics = item?.metricDetails;
  const [pdfFile, setPdfFile] = useState(null);
  const [showLinkPopup, setShowLinkPopup] = useState(false);
  const [uploadedLink, setUploadedLink] = useState("");

  const handleStatusClick = () => {
    if (!item.action) {
      setSelectedItem(item)
      setNotificationModal(true)
    }
  }

  const handleAddLink = () => {
    setShowLinkPopup(true)
  }

  const handleLinkSubmit = (link) => {
    setUploadedLink(link)
    setShowLinkPopup(false)
    console.log("Link submitted:", link)
  }

  return (
    <>
      <tr className="hover:bg-gray-50 transition-colors">
        <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
          {FormatDateTime(item.date)}
        </td>
        <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
          {item.Symbol}
        </td>
        {advisorSpecifier === "RIA" && (
          <td className="px-4 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
            {item.symbolDetails.quantity}
          </td>
        )}
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.reco_save_count || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.sent_whatsapp_count || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-16">
          <div className="flex justify-center">
            <StatusIndicator
              count={metrics?.total_sent_mails || 0}
              total={metrics?.reco_save_count || 1}
              onClick={handleStatusClick}
            />
          </div>
        </td>
        <td className="px-4 py-3 w-24">
          <div className="flex justify-center">
            {item.action ? (
              <span className="text-sm font-medium text-emerald-600 whitespace-nowrap">
                Complete
              </span>
            ) : (
              <button
                onClick={() => handleResendAdvice(item.unsentEmails, item.symbolDetails, item.metricDetails)}
                disabled={isLoading}
                className="inline-flex items-center px-2.5 py-1.5 text-sm font-medium text-white bg-[#2D87D6] rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors whitespace-nowrap"
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <RotateCw className="w-4 h-4 mr-1.5" />
                    Resend
                  </>
                )}
              </button>
            )}
          </div>
        </td>
        <td className="px-1 py-3 w-10">
          <div className="flex items-center justify-center">
            <button
              onClick={handleAddLink}
              className="inline-flex items-center px-2.5 py-1.5 text-sm font-medium text-white bg-[#2D87D6] rounded-md hover:bg-gray-800 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
            >
              <Link className="w-4 h-4 mr-1.5" />
              Add
            </button>
          </div>
        </td>
        <td className="px-4 py-3 w-fit">
          <div className="flex ">
            {uploadedLink ? (
              <a
                href={uploadedLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800 underline"
              >
                View Link
              </a>
            ) : (
              <span className="text-gray-400">No link </span>
            )}
          </div>
        </td>
      </tr>
      {showLinkPopup && (
        <tr>
          <td colSpan="9">
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 w-96">
                <h2 className="text-xl font-semibold mb-4">Add Link</h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    handleLinkSubmit(e.target.link.value)
                  }}
                >
                  <input
                    type="url"
                    name="link"
                    placeholder="Enter link here"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => setShowLinkPopup(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

