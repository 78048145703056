import React from "react";
import formatCurrency from "../../utils/formatCurrency";

const OpenPositonAccountData = ({
  style,
  allHoldingsData,
  profitAndLoss,
  pnlPercentage,
}) => {
  return (
    <>
      <div className="relative px-2 lg:px-0 py-4 lg:py-0 mt-0 lg:mt-4">
        <div className="grid grid-cols-2 gap-x-3 gap-y-3 lg:flex lg:gap-x-4">
          {/* Total Invested */}
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Total Invested</div>
            <div className={`${style.analyticsValue}`}>
              {allHoldingsData
                ? `₹${formatCurrency(parseInt(allHoldingsData?.totalinvvalue))}`
                : "-"}
            </div>
          </div>

          {/* Total Current */}
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Total Current</div>
            <div className={`${style.analyticsValue}`}>
              {allHoldingsData
                ? `₹${formatCurrency(parseInt(allHoldingsData?.totalholdingvalue))}`
                : "-"}
            </div>
          </div>

          {/* Total Net Returns */}
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white w-full px-4 py-2 lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>Total Net Returns</div>
            {allHoldingsData?.totalprofitandloss > 0 ? (
              <div className="flex items-center">
                <div className="text-[#16A085] text-[15px] sm:text-[16px] md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  +₹{formatCurrency(Math.abs(profitAndLoss))}
                </div>
                <sub className="text-[12px] text-[#16A085] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                  +{Math.abs(pnlPercentage)}%
                </sub>
              </div>
            ) : allHoldingsData?.totalprofitandloss < 0 ? (
              <div className="flex items-center">
                <div className="text-[#E43D3D] text-[15px] sm:text-[16px] md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  -₹{formatCurrency(Math.abs(profitAndLoss))}
                </div>
                <sub className="text-[12px] text-[#E43D3D] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                  -{Math.abs(pnlPercentage)}%
                </sub>
              </div>
            ) : (
              <div className="flex items-center font-semibold font-poppins">
                <div className="text-[#000000] text-[15px] sm:text-[16px] md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  ₹{allHoldingsData ? formatCurrency(profitAndLoss) : "-"}
                </div>
                <sub className="text-[12px] font-semibold ml-[5px]">
                  {allHoldingsData && allHoldingsData?.totalpnlpercentage ? (
                    <span
                      className={
                        allHoldingsData.totalpnlpercentage > 0
                          ? "text-[#16A085]"
                          : "text-[#E43D3D]"
                      }
                    >
                      {pnlPercentage}%
                    </span>
                  ) : (
                    <sub className="text-[#000000]/80 text-[12px]">0.00%</sub>
                  )}
                </sub>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenPositonAccountData;