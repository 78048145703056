import React from "react";
import { useNavigate } from "react-router-dom";
import Alpha100 from "../../assests/alpha-100.png";
import server from "../../utils/serverConfig";
import { Info, TrendingUp, Clock, AlertTriangle } from "lucide-react";
import { Card, CardHeader, CardContent, CardFooter } from "../../components/ui/card";

const StrategyCard = ({ fileName, overView, ele }) => {
  const navigate = useNavigate();

  const handleViewStrategy = () => {
    navigate(`/admin/admin-strategy/${fileName.toLowerCase().replace(/ /g, "_")}`);
  };

  // Formatting helper for currency
  const formatCurrency = (value) => {
    if (!value && value !== 0) return '0';
    return value.toLocaleString('en-IN');
  };

  const StatItem = ({ label, value, isGreen }) => (
    <div className="flex flex-col items-center justify-center flex-1 p-3">
      <span className="text-xs text-gray-600 font-medium mb-1">{label}</span>
      <span className={`text-lg font-bold ${isGreen ? 'text-emerald-600' : 'text-gray-900'}`}>
        {value}
      </span>
    </div>
  );

  const InfoItem = ({ icon: Icon, text, gradient = false }) => (
    <div className={`flex items-center space-x-2 p-3 rounded-lg ${
      gradient ? 'bg-gradient-to-r from-blue-50 to-indigo-50' : ''
    }`}>
      <Icon size={16} className="text-blue-600" />
      <span className="text-sm font-medium text-gray-700">{text}</span>
    </div>
  );

  // Get the correct fees and duration from ele object structure
  const fees = ele?.amount || ele?.fees;
  const duration = ele?.duration || ele?.plan_duration;
  const minInvestment = ele?.minInvestment || ele?.min_investment;

  return (
    <Card className="w-full bg-gradient-to-b from-white to-gray-50 transition-all duration-200 hover:shadow-lg border border-gray-200">
      <CardHeader className="pb-0 ">
        <div className="w-full bg-[#EEF4FF]  shadow-sm text-black text-sm font-poppins font-semibold border-1  uppercase text-center py-2.5 ">
          Model Portfolio
        </div>
      </CardHeader>

      <CardContent className="p-4">
        <div className="flex items-start space-x-4 cursor-pointer" onClick={handleViewStrategy}>
          <div className="flex-shrink-0 w-14 h-14 rounded-lg overflow-hidden shadow-md">
            <img
              src={ele?.image ? `${server.server.baseUrl}${ele?.image}` : Alpha100}
              alt={fileName}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex-1">
            <h2 className="text-xl font-semibold text-gray-900 mb-1 bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
              {fileName}
            </h2>
            <p className="text-sm text-gray-500 line-clamp-2">
              {overView.split(" ").slice(0, 10).join(" ")}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-px bg-gradient-to-r from-blue-100 to-indigo-100 rounded-xl mt-6 overflow-hidden p-0.5">
          <div className="bg-white rounded-lg">
            <StatItem
              label="CAGR"
              value="-"
              isGreen={true}
            />
          </div>
          <div className="bg-white rounded-lg">
            <StatItem
              label="2Y Returns"
              value="-"
              isGreen={true}
            />
          </div>
          <div className="bg-white rounded-lg">
            <StatItem
              label="Min. Investment"
              value={`₹${formatCurrency(minInvestment)}`}
              isGreen={false}
            />
          </div>
        </div>

        <div className="mt-6 space-y-2">
          <InfoItem
            icon={TrendingUp}
            text={`Fees: ₹ ${formatCurrency(fees)}`}
            gradient={true}
          />
          <InfoItem
            icon={Clock}
            text={`Duration: ${duration || 'N/A'} days`}
            gradient={true}
          />
          <InfoItem
            icon={AlertTriangle}
            text="Subject to market risk"
            gradient={true}
          />
        </div>
      </CardContent>

      <CardFooter className="p-4 pt-0">
        <button
          onClick={handleViewStrategy}
          className="w-full py-2.5 px-4 bg-[#0070D0] text-white rounded-lg font-medium
                   transition-all duration-200 hover:from-blue-700 hover:to-indigo-700 focus:outline-none
                   focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-md hover:shadow-lg"
        >
          View Details
        </button>
      </CardFooter>
    </Card>
  );
};

export default StrategyCard;