import React from "react";
import { cn } from "../../lib/utils";
import ModalPFCard from "./ModalPFCard";
import { LayoutDashboard, Info } from "lucide-react";

interface ModalPFListProps {
  className?: string;
  modelPortfolioStrategy: any[];
  modelPortfolioRepairTrades: any[];
  userEmail: string;
}

const ModalPFList: React.FC<ModalPFListProps> = ({
  className,
  modelPortfolioStrategy,
  modelPortfolioRepairTrades,
  userEmail,
}) => {
  const renderPortfolioCards = (portfolios) => {
    if (!portfolios?.length) return null;

    return portfolios.map((ele, i) => {
      const allRebalances = ele?.model?.rebalanceHistory || [];
      const sortedRebalances = allRebalances.sort(
        (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
      );
      const latest = sortedRebalances[0];

      if (!latest) return null;

      const matchingFailedTrades = modelPortfolioRepairTrades?.find(
        (trade) =>
          trade.modelId === latest?.model_Id &&
          trade.failedTrades.length !== 0
      );

      return (
        <ModalPFCard
          key={i}
          modelName={ele?.model_name}
          data={latest}
          repair={matchingFailedTrades ? "repair" : null}
          userEmail={userEmail}
        />
      );
    });
  };

  if (!modelPortfolioStrategy?.length) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-8 text-center">
        <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <LayoutDashboard className="w-8 h-8 text-gray-400" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900 mb-2">No Portfolio Models</h3>
        <p className="text-gray-500 max-w-md mx-auto">
          There are currently no portfolio models available. Check back later for updates.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      {/* Header */}
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <LayoutDashboard className="w-5 h-5 text-gray-500" />
            <h2 className="text-lg font-semibold text-gray-900">Portfolio Models</h2>
          </div>
          <button className="group relative p-2 hover:bg-gray-100 rounded-lg transition-colors">
            <Info className="w-5 h-5 text-gray-400" />
            <div className="hidden group-hover:block absolute right-0 top-full mt-2 w-64 p-3 bg-white rounded-lg shadow-xl text-sm text-gray-600 z-10">
              View and manage your portfolio models and their performance metrics
            </div>
          </button>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:block">
        {/* Table Header */}
        <div className={cn("bg-gray-50 px-6 py-3", className)}>
          <div className="grid grid-cols-7 gap-4">
            <div className="col-span-2">
              <span className="text-sm font-medium text-gray-500">Model Name</span>
            </div>
            <div className="text-center">
              <span className="text-sm font-medium text-gray-500">Total Invested</span>
            </div>
            <div className="text-center">
              <span className="text-sm font-medium text-gray-500">Current Value</span>
            </div>
            <div className="text-center">
              <span className="text-sm font-medium text-gray-500">Returns</span>
            </div>
            <div className="text-center">
              <span className="text-sm font-medium text-gray-500">CAGR</span>
            </div>
            <div className="text-center">
              <span className="text-sm font-medium text-gray-500">Actions</span>
            </div>
          </div>
        </div>

        {/* Table Body */}
        <div className="divide-y divide-gray-800">
          {renderPortfolioCards(modelPortfolioStrategy)}
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <div className="divide-y divide-gray-200">
          {renderPortfolioCards(modelPortfolioStrategy)}
        </div>
      </div>
    </div>
  );
};

export default ModalPFList;