import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// Default Firebase configuration values
const defaultConfig = {
  apiKey: "AIzaSyDR0RsT5LSv11APTJdhcDvInesOFeHb2qw",
  authDomain: "alphaquark-64c38.firebaseapp.com",
  projectId: "alphaquark-64c38",
  storageBucket: "alphaquark-64c38.appspot.com",
  messagingSenderId: "700438699014",
  appId: "1:700438699014:web:1ee2a00a419f9a909ef787",
  measurementId: "G-EQK21ENC7S"
};

// Check if any required Firebase configuration variable is missing
const requiredEnvVars = [
  'REACT_APP_FIREBASE_API_KEY',
  'REACT_APP_FIREBASE_AUTH_DOMAIN',
  'REACT_APP_FIREBASE_PROJECT_ID',
  'REACT_APP_FIREBASE_STORAGE_BUCKET',
  'REACT_APP_FIREBASE_MESSAGING_SENDER_ID',
  'REACT_APP_FIREBASE_APP_ID'
];

// Check if ANY required env variable is missing
const isAnyEnvVarMissing = requiredEnvVars.some(varName => !process.env[varName]);

// Use either ALL env variables or ALL defaults
const firebaseConfig = isAnyEnvVarMissing ? defaultConfig : {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || defaultConfig.measurementId
};

// Log which configuration is being used
if (isAnyEnvVarMissing) {
  console.warn(
    'One or more environment variables are missing. Using default Firebase configuration.'
  );
}

// Initialize Firebase only if it hasn't been initialized already
const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

// Initialize Firestore and Auth services
const db = app.firestore();
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();

// Export the initialized services
export { db, auth, provider };

// Export the config for testing purposes
export const config = firebaseConfig;