import React, { useState } from 'react';
import { PlusIcon, XIcon } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import server from '../../utils/serverConfig';

const AddClientForm = ({
  existingGroups = [],
  onClose,
  userId,
  setIsDataFetching,
  getAllClientsData
}) => {
  const [formData, setFormData] = useState({
    clientName: "",
    email: "",
    phone: "",
    groups: ["All Client"],
    location: "",
    telegram: "",
    pan: "",
    creationDate: new Date().toISOString(),
    subscriptions: []
  });
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showGroupSelector, setShowGroupSelector] = useState(false);
  const [validationError, setValidationError] = useState("");

  const kycStatusOptions = [
    { value: "Verified", label: "Verified" },
    { value: "Unverified", label: "Unverified" }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGroupSelect = (group) => {
    if (group === "All Client") return; // Prevent modifying All Client

    if (!selectedGroups.includes(group)) {
      // Add group
      setSelectedGroups(prev => [...prev, group]);

      // Add subscription for new group
      const newSubscription = {
        startDate: new Date().toISOString(),
        plan: group,
        capital: "",
        charges: 0,
        invoice: "",
        expiry: new Date(new Date().setHours(23, 59, 59, 999)).toISOString()
      };

      setFormData(prev => ({
        ...prev,
        subscriptions: [...prev.subscriptions, newSubscription]
      }));
    }
  };

  const handleGroupRemove = (groupToRemove) => {
    if (groupToRemove === "All Client") return; // Prevent removing All Client

    setSelectedGroups(prev => prev.filter(group => group !== groupToRemove));

    // Remove subscription for the removed group
    setFormData(prev => ({
      ...prev,
      subscriptions: prev.subscriptions.filter(sub => sub.plan !== groupToRemove)
    }));
  };

  const validateForm = () => {
    if (!formData.email || !formData.clientName || !formData.phone) {
      setValidationError("Please fill in all required fields");
      return false;
    }
    if (selectedGroups.length === 0) {
      setValidationError("Please select at least one group");
      return false;
    }
    setValidationError("");
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const payload = {
      userId: userId,
      clientData: {
        ...formData,
        groups: ["All Client", ...selectedGroups],
        creationDate: new Date().toISOString(),
        subscriptions: formData.subscriptions
      },
      DateofBirth: dateOfBirth,
      advisorName: process.env.REACT_APP_ADVISOR_SPECIFIC_TAG
    };

    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setIsDataFetching(true);
        getAllClientsData(userId);
        onClose();
        toast.success(`New Client ${payload.clientData.clientName}  added successfully !!`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      } else {
        throw new Error(result.message || "Failed to add client");
      }
    } catch (error) {
      console.error("Error adding client:", error);
      toast.error(`Error adding new client: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Add New Client</h2>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded">
            <XIcon className="h-5 w-5" />
          </button>
        </div>

        {validationError && (
          <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
            {validationError}
          </div>
        )}

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter email"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              name="clientName"
              value={formData.clientName}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter name"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Country Code <span className="text-red-500">*</span>
            </label>
            <input
              name="countryCode"
              value={formData.countryCode}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="e.g. +91"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Phone <span className="text-red-500">*</span>
            </label>
            <input
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter phone number"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Date of Birth
            </label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">Location</label>
            <input
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter location"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">Telegram ID</label>
            <input
              name="telegramId"
              value={formData.telegramId}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter Telegram ID"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">PAN/KYC</label>
            <input
              name="pan"
              value={formData.pan}
              onChange={handleInputChange}
              className="border rounded p-2"
              placeholder="Enter PAN/KYC number"
            />
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">KYC Validation Status</label>
            <select
              name="kycStatus"
              value={formData.kycStatus}
              onChange={handleInputChange}
              className="border rounded p-2"
            >
              <option value="">Select KYC status</option>
              {kycStatusOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-sm font-medium mb-1">
              Plan Expiry Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              name="planExpiryDate"
              value={formData.planExpiryDate}
              onChange={handleInputChange}
              className="border rounded p-2"
            />
          </div>
        </div>

        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <label className="text-sm font-medium">Selected Groups</label>
            <button
              onClick={() => setShowGroupSelector(true)}
              className="flex items-center text-sm text-blue-600 hover:text-blue-800"
            >
              <PlusIcon className="h-4 w-4 mr-1" />
              Add Group
            </button>
          </div>

          <div className="flex flex-wrap gap-2">
            {selectedGroups.map(group => (
              <div key={group} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
                <span className="text-sm">{group}</span>
                <button
                  onClick={() => handleGroupRemove(group)}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                >
                  <XIcon className="h-3 w-3" />
                </button>
              </div>
            ))}
          </div>
        </div>

        {showGroupSelector && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-4 w-80">
              <div className="flex justify-between items-center mb-4">
                <h3 className="font-medium">Select Groups</h3>
                <button
                  onClick={() => setShowGroupSelector(false)}
                  className="p-1 hover:bg-gray-100 rounded"
                >
                  <XIcon className="h-4 w-4" />
                </button>
              </div>
              <div className="space-y-2 max-h-60 overflow-y-auto">
                {existingGroups.map(group => (
                  <button
                    key={group}
                    onClick={() => {
                      handleGroupSelect(group);
                      setShowGroupSelector(false);
                    }}
                    className="w-full text-left px-3 py-2 hover:bg-gray-100 rounded"
                    disabled={selectedGroups.includes(group)}
                  >
                    {group}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-black text-white rounded hover:bg-black/90"
          >
            Add Client
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClientForm;