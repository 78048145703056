// TableHeader.jsx
import React from "react";
import { AppWindow, MessageCircle, Mail } from "lucide-react";

const HeaderCell = ({ children, className = "" }) => (
  <th className={`px-4 py-3 text-sm font-medium text-gray-600 ${className}`}>
    {children}
  </th>
);

export const TableHeader = ({ advisorSpecifier }) => (
  <thead className="bg-white border-b border-gray-200">
    <tr>
      <HeaderCell className="whitespace-nowrap text-left">Date</HeaderCell>
      <HeaderCell className="whitespace-nowrap text-left">Symbol</HeaderCell>
      {advisorSpecifier === "RIA" && (
        <HeaderCell className="whitespace-nowrap">Quantity</HeaderCell>
      )}
      <HeaderCell className="w-20 text-center">
        <div className="flex items-center justify-center">
          <AppWindow className="w-5 h-5" />
        </div>
      </HeaderCell>
      <HeaderCell className="w-20 text-center">
        <div className="flex items-center justify-center">
          <MessageCircle className="w-5 h-5" />
        </div>
      </HeaderCell>
      <HeaderCell className="w-20 text-center">
        <div className="flex items-center justify-center">
          <Mail className="w-5 h-5" />
        </div>
      </HeaderCell>
      <HeaderCell className="w-24 text-center whitespace-nowrap">
        Action
      </HeaderCell>
      <HeaderCell className="w-32 text-center whitespace-nowrap">
        Research Report Link
      </HeaderCell>
    </tr>
  </thead>
);
