
import React, { useState, useEffect, useRef } from "react"
import { X } from "lucide-react"
import server from "../../../utils/serverConfig"

export const PreviousBasketAdvices = ({ onClose, onSelect }) => {
  const [adviceData, setAdviceData] = useState([])
  const [selectedAdvices, setSelectedAdvices] = useState(new Set())
  const [isAllSelected, setIsAllSelected] = useState(false)
  const modalRef = useRef(null)


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/process.env.REACT_APP_ADVISOR_SPECIFIC_TAG`)
  //       const data = await response.json()
  //       setAdviceData(data.reco || [])
  //       console.log(data.reco)
  //     } catch (error) {
  //       console.error("Error fetching data:", error)
  //     }
  //   }

  //   fetchData()
  // }, [])


//filtered data for Basket key = true
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}`)
        const data = await response.json()
        const filteredData = (data.reco || []).filter((advice) => advice.Basket === true)
        setAdviceData(filteredData)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  const handleCheckboxChange = (id) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected)
      if (newSelected.has(id)) {
        newSelected.delete(id)
      } else {
        newSelected.add(id)
      }
      setIsAllSelected(newSelected.size === adviceData.length)
      return newSelected
    })
  }

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set())
    } else {
      const allIds = new Set(adviceData.map((advice) => advice.advice_reco_id))
      setSelectedAdvices(allIds)
    }
    setIsAllSelected(!isAllSelected)
  }

  const handleSelectAdvices = () => {
    const selectedAdviceData = adviceData.filter((advice) => selectedAdvices.has(advice.advice_reco_id))
    onSelect(selectedAdviceData)
  }

  return (
    <div
      ref={modalRef}
      className="relative w-full max-w-[75rem] h-[88vh] bg-white rounded-xl shadow-xl overflow-hidden font-sans"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-6 border-b bg-gray-50 sticky top-0 z-10">
          <h2 className="text-2xl font-bold text-gray-800">Previous Basket Advices</h2>
          <button
            onClick={onClose}
            className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="flex-grow overflow-auto">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-100 sticky top-0 z-10">
              <tr>
                <th className="py-3 px-4 border-b text-left font-poppins">
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                    className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                  />
                </th>
                <th className="py-3 px-4 border-b text-left font-poppins">ID</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Basket Name</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Symbol</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Type</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Strike</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Option Type</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Order Type</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Lots</th>
                <th className="py-3 px-4 border-b text-left font-poppins">Date</th>
              </tr>
            </thead>
            <tbody>
              {adviceData.map((advice) => (
                <tr key={advice.advice_reco_id} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border-b">
                    <input
                      type="checkbox"
                      checked={selectedAdvices.has(advice.advice_reco_id)}
                      onChange={() => handleCheckboxChange(advice.advice_reco_id)}
                      className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    />
                  </td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.advice_reco_id}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.basketName}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.symbol}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.foType}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.strike}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.optionType}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.orderType}</td>
                  <td className="py-2 px-4 border-b font-poppins">{advice.lots}</td>
                  <td className="py-2 px-4 border-b font-poppins">{new Date(advice.date).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="p-6 border-t bg-gray-50 sticky bottom-0 z-10">
          <button
            onClick={handleSelectAdvices}
            disabled={selectedAdvices.size === 0}
            className={`px-4 py-2 font-poppins text-white rounded-lg float-right ${
              selectedAdvices.size === 0 ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-gray-800"
            }`}
          >
            Add Selected Advices
          </button>
        </div>
      </div>
    </div>
  )
}

export default PreviousBasketAdvices

