import React, { useState, useEffect } from "react";
import StrategyCard from "./StrategyCard";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { GitForkIcon } from "lucide-react";
import server from "../../utils/serverConfig";
import { motion, AnimatePresence } from "framer-motion";

const style = {
  selected: "flex items-center text-[18px] font-sans text-gray-900 font-bold lg:leading-[42px] cursor-pointer hover:text-black transition-colors duration-200",
  unselected: "flex items-center text-[18px] font-sans font-medium text-gray-400 lg:leading-[42px] cursor-pointer hover:text-gray-600 transition-colors duration-200",
};

const StrategyList = ({ advisorName }) => {
  const [allModalPortfolioList, setAllModalPortdfolioList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllModalPortfolioList = () => {
    if (advisorName) {
      axios
        .get(`${server.server.baseUrl}api/model-portfolio/portfolios/${advisorName}`)
        .then((res) => {
          setAllModalPortdfolioList(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getAllModalPortfolioList();
  }, [advisorName]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-gray-50 to-white">
      <Toaster position="top-center" reverseOrder={true} />

      {/* Header Section */}
      <div className="py-6 px-6 border-b border-gray-200 backdrop-blur-sm bg-white/50">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center">
            <h1 className={`${style.selected} text-2xl md:text-3xl lg:text-4xl bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600`}>
              Model Portfolios Rebalancing
            </h1>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {allModalPortfolioList.length !== 0 ? (
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
              className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
            >
              {allModalPortfolioList.map((ele, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: i * 0.1 }}
                >
                  <StrategyCard
                    fileName={ele.model_name}
                    overView={ele.overView}
                    ele={ele}
                  />
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        ) : (
          <div className="flex justify-center min-h-[calc(100vh-200px)]">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="relative w-16 h-16">
                  <div className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-gray-200"></div>
                  <div className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-gray-800 border-t-transparent animate-spin"></div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center max-w-2xl mx-auto text-center p-6">
                <div className="relative w-32 h-32 lg:w-40 lg:h-40 mb-8">
                  <div className="absolute inset-0 bg-gradient-to-r from-gray-100 to-gray-200 rounded-full animate-pulse"></div>
                  <div className="relative flex items-center justify-center h-full">
                    <GitForkIcon className="w-16 h-16 lg:w-20 lg:h-20 text-gray-600" />
                  </div>
                </div>

                <h2 className="text-2xl lg:text-3xl font-bold text-gray-900 mb-4">
                  View and manage your existing model portfolio here
                </h2>

                <p className="text-lg text-gray-600 leading-relaxed max-w-xl">
                  Create a personalized model portfolio based strategy from scratch to meet your goals.
                  Create model portfolio under the Plan tab.
                </p>

                <p className="mt-4 text-sm text-gray-500">
                  Contact your admin for more details
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StrategyList;