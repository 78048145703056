import axios from "axios";
import toast from "react-hot-toast";
const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;

export const createPlaceOrderFunction = ({
  setLoading,
  userDetails,
  getAllTrades,
  getAllTradesUpdate,
  getCartAllStocks,
  stockDetails,
  broker,
  clientCode,
  apiKey,
  secretKey,
  jwtToken,
  viewToken,
  sid,
  serverId,
  server,
  setShowOtherBrokerModel,
  setOpenReviewTrade,
  setOrderPlacementResponse,
  setOpenSucessModal,
  updatePortfolioData,
  isReturningFromOtherBrokerModal,
  setIsReturningFromOtherBrokerModal,
}) => {
  return async () => {
    setLoading(true);
    const getOrderPayload = () => {
      const basePayload = {
        trades: stockDetails,
        user_broker: broker,
      };

      switch (broker) {
        case "IIFL Securities":
          return { ...basePayload, clientCode };
        case "ICICI Direct":
          return { ...basePayload, apiKey, secretKey, jwtToken };
        case "Upstox":
          return { ...basePayload, apiKey, jwtToken, secretKey };
        case "Kotak":
          return {
            ...basePayload,
            apiKey,
            secretKey,
            jwtToken,
            viewToken,
            sid,
            serverId,
          };
        case "Hdfc Securities":
          return { ...basePayload, apiKey, jwtToken };
        case "Dhan":
          return { ...basePayload, clientCode, jwtToken };
        case "AliceBlue":
          return { ...basePayload, clientCode, jwtToken, apiKey };
        case "Fyers":
          return { ...basePayload, clientCode, jwtToken };
        case "Angel One":
          return {
            ...basePayload,
            apiKey: angelOneApiKey,
            secretKey,
            jwtToken,
          };
        default:
          return { ...basePayload, apiKey, jwtToken };
      }
    };

    const allBuy = stockDetails.every(
      (stock) => stock.transactionType === "BUY"
    );
    const allSell = stockDetails.every(
      (stock) => stock.transactionType === "SELL"
    );

    const isMixed =
      stockDetails?.some((stock) => stock.transactionType === "BUY") &&
      stockDetails?.some((stock) => stock.transactionType === "SELL");

    const specialBrokers = [
      "IIFL Securities",
      "ICICI Direct",
      "Upstox",
      "Kotak",
      "Hdfc Securities",
      "AliceBlue",
    ];

    try {
      const response = await axios.request({
        method: "post",
        url: `${server.server.baseUrl}api/process-trades/order-place`,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(getOrderPayload()),
      });

      setOrderPlacementResponse(response.data.response);
      setLoading(false);

      const rejectedSellCount = response.data.response.reduce(
        (count, order) => {
          const isRejected = [
            "Rejected",
            "rejected",
            "cancelled",
            "CANCELLED",
            "Cancelled",
          ].includes(order?.orderStatus);
          return isRejected && order.transactionType === "SELL"
            ? count + 1
            : count;
        },
        0
      );

      if (!isReturningFromOtherBrokerModal && specialBrokers.includes(broker)) {
        if (allBuy) {
          setOpenSucessModal(true);
          setOpenReviewTrade(false);
        } else if (
          (allSell || isMixed) &&
          !userDetails?.is_authorized_for_sell &&
          rejectedSellCount >= 1
        ) {
          setShowOtherBrokerModel(true);
          setOpenReviewTrade(false);
          setLoading(false);
          return;
        } else {
          setOpenSucessModal(true);
          setOpenReviewTrade(false);
        }
      } else {
        setOpenSucessModal(true);
      }
      setOpenReviewTrade(false);

      await Promise.all([
        updatePortfolioData(broker, userDetails?.email),
        getAllTrades(),
        getAllTradesUpdate?.(),
        getCartAllStocks(),
      ]);
    } catch (error) {
      console.error("Error placing order:", error);
      setLoading(false);
      toast.error(
        "There was an issue in placing the trade, please try again after sometime or contact your advisor",
        {
          duration: 6000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
    setIsReturningFromOtherBrokerModal(false);
  };
};
