import React, { useState } from "react";
import { Plus, XIcon } from "lucide-react";

const DerivativeSendAdviceTable = ({
  handleAddDerivativesEntry,
  adviceDerivativesEntries,
  setAdviceDerivativesEntries,
  handleDerivativeChange,
  handleDerivativesInputChange,
  handleDerivativesSymbolSelect,
  handleRemoveDerivativesEntry,
  setShowPreviousAdvices
}) => {
  return (
    <div className="mt-4 p-4 border border-gray-200 rounded-lg">
      <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200 ">
        <button
          onClick={handleAddDerivativesEntry}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Add
        </button>


        <button
          onClick={() => setShowPreviousAdvices(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
        >
          Send Previous Entries
        </button>
      </div>
      <span className="text-sm text-gray-500 ml-2">
        {adviceDerivativesEntries.length} entr
        {adviceDerivativesEntries.length === 1 ? "y" : "ies"}
      </span>
      <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
        <table className="min-w-full divide-y divide-gray-200">
          <colgroup>
            <col className="w-[200px]" /> {/* Segment */}
            <col className="w-[250px]" /> {/* Symbol */}
            <col className="w-[180px]" /> {/* Strike */}
            <col className="w-[200px]" /> {/* Lots */}
            <col className="w-[250px]" /> {/* Option Type */}
            <col className="w-[250px]" /> {/* Type */}
            <col className="w-[250px]" /> {/* Order type */}
            <col className="w-[200px]" /> {/* Product Type */}
            <col className="w-[100px]" /> {/* Basket Name */}
            <col className="w-[200px]" /> {/* Rationale */}
            <col className="w-[200px]" /> {/* Comments */}
            <col className="w-[200px]" /> {/* Extended Comment */}
            <col className="w-[80px]" /> {/* Actions */}
          </colgroup>

          <thead className="bg-gray-50">
            <tr className="h-16">
              <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                Segment <span className="text-red-500">*</span>
              </th>
              <th className="px-3 py-2 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                Symbol <span className="text-red-500">*</span>
              </th>

              {adviceDerivativesEntries.some(
                (entry) => entry.foType === "OPTIONS"
              ) && (
                  <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                    Strike / (CE/PE) <span className="text-red-500">*</span>
                  </th>
                )}

              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                Lots <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                Option Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                Order Type <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                Product Type <span className="text-red-500">*</span>
              </th>

              {/* <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                                 Basket Name{" "}
                                 <span className="text-red-500">*</span>
                               </th> */}

              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                Rationale <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                Comment
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                Extended Comment
              </th>
              <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {adviceDerivativesEntries.map((entry, index) => (
              <tr
                key={entry.id}
                className="hover:bg-gray-50 transition-colors min-h-[120px]"
              >
                {/* Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.foType || "OPTIONS"}
                    onChange={(e) =>
                      handleDerivativeChange(index, "foType", e.target.value)
                    }
                    className="w-full min-w-[180px] font-poppins text-base px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select Type</option>
                    {["OPTIONS", "FUTURES"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Symbol */}
                <td className="py-2">
                  <div className="relative">
                    <input
                      type="text"
                      value={entry.searchSymbol || ""}
                      onChange={(e) =>
                        handleDerivativesInputChange(index, e.target.value)
                      }
                      className="w-full min-w-[230px] px-3 font-poppins py-1.5 border text-black border-gray-300 rounded-md"
                      placeholder="Enter symbol"
                    />
                    {entry.symbols &&
                      entry.symbols.length > 0 &&
                      entry.showDropdown && (
                        <div className="absolute z-10 font-poppins w-full mt-1 bg-white rounded-md shadow-lg">
                          {Array.from(
                            new Set(
                              entry.symbols.map((symbol) => symbol.searchSymbol)
                            )
                          )
                            .filter(
                              (uniqueSymbol) =>
                                uniqueSymbol !== entry.searchSymbol
                            )

                            .map((uniqueSymbol, idx) => {
                              const symbol = entry.symbols.find(
                                (s) => s.searchSymbol === uniqueSymbol
                              );
                              return (
                                <div
                                  key={idx}
                                  onClick={() =>
                                    handleDerivativesSymbolSelect(
                                      index,
                                      symbol,
                                      symbol.lotsize,
                                      symbol.strike,
                                      symbol.exchange,
                                      symbol.optionType,
                                      symbol.strikes,
                                      symbol.optionTypes
                                    )
                                  }
                                  className="px-4 py-2.5 text-black hover:bg-gray-100 font-poppins cursor-pointer"
                                >
                                  {symbol.searchSymbol}
                                </div>
                              );
                            })}
                        </div>
                      )}
                  </div>
                </td>

                {/* Strike (CE/PE) */}

                {entry.foType === "OPTIONS" && (
                  <td className="px-4 py-4">
                    <div className="flex items-center">

                      <select
                        value={entry.strike}
                        onChange={(e) =>
                          handleDerivativeChange(
                            index,
                            "strike",
                            e.target.value
                          )
                        }
                        className="ml-2 px-3 font-poppins w-full min-w-[120px] py-1.5 border text-black border-gray-300 rounded-md"
                      >
                        <option value="">Select</option>
                        {(entry.strikes || []).map((strike, idx) => (
                          <option key={idx} value={strike}>
                            {strike}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                )}

                {/* Lots */}
                <td className="px-4 py-4 w-[120px]">
                  <div className="flex flex-row font-poppins items-center space-x-2">
                    {/* Lot Multiplier Input */}
                    <input
                      type="number"
                      value={entry.lotMultiplier}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        const validatedValue =
                          isNaN(value) || value <= 0 ? 0 : value;
                        handleDerivativeChange(
                          index,
                          "lotMultiplier",
                          validatedValue
                        );
                      }}
                      className="w-[60px] font-poppins text-center text-base px-1 py-1 border text-black border-gray-300 rounded-md"
                      min="1"
                    />
                    <span className="text-sm text-gray-500">lot</span>
                    <span className="text-sm text-gray-500">=</span>
                    {/* Calculated Lots Input */}
                    <input
                      type="number"
                      value={entry.lotMultiplier * entry.lots} // Show the exact calculated value
                      readOnly
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        const validatedValue =
                          isNaN(value) || value < 0 ? 0 : value;
                        const newLots = Math.max(
                          Math.floor(validatedValue / entry.lotMultiplier),
                          0
                        );
                        setAdviceDerivativesEntries((prevEntries) =>
                          prevEntries.map((item, i) =>
                            i === index ? { ...item, lots: newLots } : item
                          )
                        );
                      }}
                      className="w-[80px] font-poppins text-base px-2 py-1 border text-black border-gray-300 rounded-md"
                      min="0"
                    />
                    <span className="text-sm text-gray-500">qty</span>
                  </div>
                </td>

                {/* Option Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.optionType}
                    onChange={(e) =>
                      handleDerivativeChange(
                        index,
                        "optionType",
                        e.target.value
                      )
                    }
                    className="ml-2 px-3 w-full font-poppins min-w-[120px] py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {(entry.optionTypes || []).map((type, idx) => (
                      <option key={idx} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Order Type */}
                <td className="px-4 py-4">
                  <select
                    value={entry.orderType}
                    onChange={(e) =>
                      handleDerivativeChange(index, "orderType", e.target.value)
                    }
                    className="w-full min-w-[180px] font-poppins text-base px-3 py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select Type</option>
                    {["BUY", "SELL"].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                </td>

                {/* ORDER TYPE */}
                <td className="px-4 py-4">
                  <select
                    value={entry.order || "MARKET"}
                    onChange={(e) =>
                      handleDerivativeChange(index, "order", e.target.value)
                    }
                    className="ml-2 px-3 w-full font-poppins min-w-[120px] py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {["MARKET", "LIMIT"].map((order, idx) => (
                      <option key={idx} value={order}>
                        {order}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Product TYPE */}
                <td className="px-4 py-4">
                  <select
                    value={entry.productType || "CARRYFORWARD"}
                    onChange={(e) =>
                      handleDerivativeChange(
                        index,
                        "productType",
                        e.target.value
                      )
                    }
                    className="ml-2 px-2 font-poppins max-w-[180px] py-1.5 border text-black border-gray-300 rounded-md"
                  >
                    <option value="">Select</option>
                    {["CARRYFORWARD", "INTRADAY"].map((productType, idx) => (
                      <option key={idx} value={productType}>
                        {productType}
                      </option>
                    ))}
                  </select>
                </td>

                {/* Rationale */}
                <td className="px-4 py-4">
                  <textarea
                    value={entry.rationale}
                    onChange={(e) =>
                      handleDerivativeChange(index, "rationale", e.target.value)
                    }
                    className="w-full min-w-[280px] font-poppins px-3 py-1.5 border text-black border-gray-300 rounded-md"
                    rows="3"
                    placeholder="Enter rationale..."
                  />
                </td>

                {/* Comments */}

                <td className="relative px-4 py-4">
                  <textarea
                    value={entry.comments}
                    onChange={(e) =>
                      handleDerivativeChange(index, "comments", e.target.value)
                    }
                    className="w-full min-w-[200px] px-3 font-poppins py-1.5 border text-black border-gray-300 rounded-md"
                    rows={3}
                    placeholder="Enter comments..."
                  />
                </td>
                <td className="px-4 py-4">
                  <textarea
                    value={entry.extendedComment}
                    onChange={(e) =>
                      handleDerivativeChange(
                        index,
                        "extendedComment",
                        e.target.value
                      )
                    }
                    className="w-full min-w-[200px] font-poppins px-3 py-1.5 border text-black border-gray-300 rounded-md"
                    rows="3"
                    placeholder="Enter extended comment..."
                  />
                </td>

                {/* Actions */}
                <td className="px-4 py-4">
                  {adviceDerivativesEntries.length > 1 && (
                    <button
                      onClick={() => handleRemoveDerivativesEntry(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
};
export default DerivativeSendAdviceTable;
