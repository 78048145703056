import React, { useState } from "react";
import EquityAdvice from "./EquityAdvice/EquityAdvice";
import { Toaster } from "react-hot-toast";
import DerivativeAdvice from "./DerivativeAdvice/DerivativeAdvice";
import BasketAdvice from "./DerivativeAdvice/BasketAdvice";

const PreviousAdviceLogs = () => {
  const [activeMainTab, setActiveMainTab] = useState("equity");
  const [activeDerivativeTab, setActiveDerivativeTab] =
    useState("derivative-advice");

  const renderContent = () => {
    switch (activeMainTab) {
      case "equity":
        return (
          <div className="flex-1 overflow-auto">
            <EquityAdvice />
          </div>
        );
      case "derivatives":
        return (
          <div className="flex-1 overflow-auto">
            <div className="flex border-b bg-white">
              <button
                className={`px-4 py-2 font-poppins ${
                  activeDerivativeTab === "derivative-advice"
                    ? "border-b-2 border-black text-black"
                    : "text-gray-500 hover:text-black"
                }`}
                onClick={() => setActiveDerivativeTab("derivative-advice")}
              >
                Derivative Advice
              </button>
              <button
                className={`px-4 py-2 font-poppins ${
                  activeDerivativeTab === "basket-advice"
                    ? "border-b-2 border-black text-black"
                    : "text-gray-500 hover:text-black"
                }`}
                onClick={() => setActiveDerivativeTab("basket-advice")}
              >
                Basket Advice
              </button>
            </div>
            <div className="p-4">
              {activeDerivativeTab === "derivative-advice" ? (
                <div className="flex-1 overflow-auto">
                  <DerivativeAdvice />
                </div>
              ) : (
                <div className="flex-1 overflow-auto">
                  <BasketAdvice />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full w-full">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="bg-white border-b">
        <button
          className={`px-6 py-3 font-poppins ${
            activeMainTab === "equity"
              ? "border-b-2 border-black text-black"
              : "text-gray-500 hover:text-black"
          }`}
          onClick={() => setActiveMainTab("equity")}
        >
          Equity
        </button>
        <button
          className={`px-6 py-3 font-poppins ${
            activeMainTab === "derivatives"
              ? "border-b-2 border-black text-black"
              : "text-gray-500 hover:text-black"
          }`}
          onClick={() => setActiveMainTab("derivatives")}
        >
          Derivatives
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export default PreviousAdviceLogs;
