import React, { useState } from "react";
import { XIcon, RotateCw, Circle as CircleX } from "lucide-react";
import Logo from "../../assests/Logo.jpg";
import Whatsapp from "../../assests/whatsapp.png";
import Mail from "../../assests/Email.svg";
import { motion, AnimatePresence } from "framer-motion";
import ProgressCircle from "./ProgressCircle";
import LoadingSpinner from "../../components/LoadingSpinner";
// import { DatePicker } from "../../components/ui/date-picker";
// import server from "../../utils/serverConfig";

const SendNotificationModal = ({
  setNotificationModal,
  selectedItem,
  handleResendAdvice,
  loading,
}) => {
  const [selectedTab, setSelectedTab] = useState("platform");

  const getTabSpecificData = () => {
    if (selectedTab === "whatsapp") {
      return selectedItem?.metricDetails?.unsent_whatsapp_emails || [];
    } else if (selectedTab === "gmail") {
      return selectedItem?.metricDetails?.unsupported_email_count > 0
        ? Array.from(
            { length: selectedItem?.metricDetails?.unsupported_email_count },
            (_, i) => `Unsupported email #${i + 1}`
          )
        : [];
    }
    return [];
  };

  const tabData = getTabSpecificData();

  const TabButton = ({ id, icon, progress, count, total }) => (
    <button
      onClick={() => setSelectedTab(id)}
      className={`px-6 py-3 flex items-center space-x-3 transition-all ${
        selectedTab === id
          ? "border-b-4 border-blue-700"
          : "border-b-4 border-transparent hover:border-gray-200"
      }`}
    >
      <img src={icon} alt={id} className="w-8 h-8 object-contain" />
      <div className="flex items-center justify-center px-4 py-1.5 rounded-full border-2 border-gray-200 min-w-[120px]">
        <ProgressCircle percentage={progress} />
        <span className="ml-2 text-sm font-medium text-gray-700">
          {count}/{total}
        </span>
      </div>
    </button>
  );

  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center bg-black/75 z-50 p-4">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="w-full max-w-3xl"
        >
          <div className="bg-white rounded-xl shadow-2xl overflow-hidden">
            {/* Header */}
            <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900">
                Advice Sent Status
              </h2>
              <button
                onClick={() => setNotificationModal(false)}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              >
                <XIcon className="w-5 h-5 text-gray-500" />
              </button>
            </div>

            {/* Tabs */}
            <div className="flex border-b border-gray-200">
              <TabButton
                id="platform"
                icon={Logo}
                progress={selectedItem?.platformProgress}
                count={selectedItem?.metricDetails?.reco_save_count}
                total={selectedItem?.metricDetails?.reco_save_count}
              />
              <TabButton
                id="whatsapp"
                icon={Whatsapp}
                progress={selectedItem?.progress}
                count={selectedItem?.metricDetails?.sent_whatsapp_count}
                total={selectedItem?.metricDetails?.reco_save_count}
              />
              <TabButton
                id="gmail"
                icon={Mail}
                progress={selectedItem?.emailProgress}
                count={selectedItem?.metricDetails?.total_sent_mails}
                total={selectedItem?.metricDetails?.reco_save_count}
              />
            </div>

            {/* Content */}
            <div className="px-6 py-8">
              {tabData?.length > 0 ? (
                <div className="space-y-3">
                  {tabData.map((email, i) => (
                    <div
                      key={i}
                      className="flex items-center p-3 rounded-lg bg-gray-50 text-gray-700"
                    >
                      <CircleX className="w-5 h-5 text-red-500 flex-shrink-0" />
                      <span className="ml-3">{email}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  No data available
                </div>
              )}
            </div>

            {/* Footer */}
            {tabData?.length > 0 && (
              <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setNotificationModal(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      handleResendAdvice(
                        selectedItem.unsentEmails,
                        selectedItem.symbolDetails,
                        selectedItem.metricDetails
                      )
                    }
                    disabled={loading}
                    className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 transition-colors disabled:bg-blue-300 disabled:cursor-not-allowed"
                  >
                    {loading ? (
                      <LoadingSpinner />
                    ) : (
                      <>
                        <RotateCw className="h-4 w-4 mr-2" />
                        Send Again
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default SendNotificationModal;