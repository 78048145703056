import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { TableRow } from "./TableRow";
import { TableHeader } from "./TableHeader";
import SendNotificationModal from "./SendNotificationModal";
import server from "../../utils/serverConfig";
import PreviousAdviceLogs from "./previousAdviceLogs/previousAdviceLogs";

const AdminAdviceDetails = ({ advisorSpecifier, adminEmail }) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const [notificationModal, setNotificationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState("send-previous");
  const [loadingAdvice, setLoadingAdvice] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingAdviceId, setLoadingAdviceId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [alertModal, setAlertModal] = useState(false);

  const itemsPerPage = 12;

  const getAllHistoryData = async () => {
    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/send-reco-history`,
        {
          advisorName: advisorTag,
        }
      );

      const mappedData = Object.entries(response?.data?.metrics)
        .filter(([adviseId, metrics]) => {
          return (
            metrics && metrics.length > 0 && response?.data?.symbols[adviseId]
          );
        })
        .map(([adviseId, metrics]) => {
          const metric = metrics[0];
          const symbolData = response?.data?.symbols[adviseId];
          const symbols = Array.isArray(symbolData) ? symbolData : [symbolData];

          return symbols.map((symbol) => {
            const formattedSymbol = {
              advisedRangeHigher: symbol.Advised_Range_Higher,
              advisedRangeLower: symbol.Advised_Range_Lower,
              exchange: symbol.Exchange,
              orderType: symbol.OrderType,
              price: symbol.Price,
              quantity: symbol.Quantity,
              segment: symbol.Segment,
              productType: symbol.ProductType,
              symbol: symbol.Symbol,
              type: symbol.Type,
              rationale: symbol.rationale,
              trade_given_by: symbol.trade_given_by,
              adviceRecoId: symbol.advice_reco_id,
              price_when_send_advice: symbol.price_when_send_advice,
              comments: symbol?.comments ? symbol?.comments : "",
              comment2: symbol?.comment2 ? symbol?.comment2 : "",
            };

            const whatsappProgress =
              (metric.sent_whatsapp_count / metric.reco_save_count) * 100;
            const emailProgress =
              (metric.total_sent_mails / metric.reco_save_count) * 100;
            const platformProgress =
              (metric.reco_save_count / metric.reco_save_count) * 100;

            const isComplete =
              metric.unsent_whatsapp_count === 0 &&
              metric.unsupported_email_count === 0;

            return {
              date: new Date(metric.created_datetime),
              Symbol: formattedSymbol.symbol,
              action: isComplete,
              progress: whatsappProgress,
              emailProgress: emailProgress,
              platformProgress: platformProgress,
              unsentEmails: metric.unsent_whatsapp_emails || [],
              adviseId: adviseId,
              metricDetails: metric,
              symbolDetails: formattedSymbol,
            };
          });
        })
        .flat();

      setTableData(mappedData);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleResendAdvice = (unsentEmails, symbolDetails, metricDetails) => {
    setLoading(true);
    setLoadingAdviceId(metricDetails?.advise_id);

    const adviceData = unsentEmails.map((email) => {
      const baseData = {
        email: email,
        userName: metricDetails?.name ? metricDetails?.name : "",
        phoneNumber: metricDetails?.phone,
        country_code: metricDetails?.country_code,
        advisor_name: metricDetails?.advisor_name,
        Symbol: symbolDetails.symbol,
        Exchange: symbolDetails.exchange,
        Type: symbolDetails.type,
        OrderType: symbolDetails.orderType,
        ProductType: symbolDetails.productType,
        Segment: symbolDetails.segment,
        Price: symbolDetails.orderType === "MARKET" ? 0 : symbolDetails.price,
        date: metricDetails.created_datetime,
        Advised_Range_Lower: symbolDetails.advisedRangeLower,
        Advised_Range_Higher: symbolDetails.advisedRangeHigher,
        rationale: symbolDetails?.rationale,
        comments: symbolDetails?.comments,
        comment2: symbolDetails?.comment2,
        trade_given_by: symbolDetails?.trade_given_by,
        advisorType: advisorSpecifier,
        advise_id: symbolDetails?.adviceRecoId,
      };

      // Only add quantity if advisorSpecifier is RIA
      if (advisorSpecifier === "RIA") {
        baseData.Quantity = symbolDetails.quantity;
      }

      return baseData;
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setLoadingAdviceId(null);
        toast.success("Advice has been resent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        getAllHistoryData();
        setNotificationModal(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAdviceId(null);
        toast.error("Error in resending Advice!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllHistoryData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingAdvice(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return tableData.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages + 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage <= 3) {
        for (let i = 2; i <= 5; i++) pages.push(i);
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) pages.push(i);
      } else {
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) pages.push(i);
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  return (
    <div className="w-full h-screen overflow-auto bg-white">
      <div className="min-w-full">
        {/* Header */}
        <div className="flex flex-row bg-[#131A29] text-white px-6 py-4 w-full justify-between">
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Investment Advice Management System
            </h2>
            <p className="text-blue-100 text-sm">
              Track and manage investment recommendations across multiple
              channels. Monitor delivery status, resend communications, and
              ensure all clients receive timely updates.
            </p>
          </div>
          {/*<button*/}
          {/*  onClick={() => setAlertModal(true)}*/}
          {/*  className="px-4 py-1 bg-yellow-500 h-[40px] text-white rounded-md  hover:bg-yellow-600"*/}
          {/*>*/}
          {/*  Alert Me*/}
          {/*</button>*/}
        </div>
        {/* Instructions Panel */}
        <div className="bg-white px-6 py-4 border-b w-full">
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-900 mb-2">
              Quick Instructions
            </h3>
            <ul className="text-sm text-gray-600 space-y-1">
              <li>
                • View delivery status for Platform, WhatsApp, and Email
                channels
              </li>
              <li>
                • Click on status icons to see detailed delivery information
              </li>
              <li>• Use "Send Again" to retry failed deliveries</li>
              <li>• Switch tabs to monitor historical advice records</li>
            </ul>
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-white px-6 py-4 border-b w-full">
          <div className="flex space-x-2">
            <button
              onClick={() => setActiveTab("advice-status")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                activeTab === "advice-status"
                  ? "bg-blue-600 text-white shadow-sm"
                  : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200"
              }`}
            >
              Advice Status
            </button>
            <button
              onClick={() => setActiveTab("send-previous")}
              className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
                activeTab === "send-previous"
                  ? "bg-blue-600 text-white shadow-sm"
                  : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200"
              }`}
            >
              Close Open Advice
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 w-full">
          {activeTab === "advice-status" ? (
            <div className="w-full">
              {loadingAdvice ? (
                <div className="flex items-center justify-center p-12">
                  <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
                </div>
              ) : tableData?.length > 0 ? (
                <div className="w-full relative">
                  {/* Sticky Header */}

                  {/* Table Content */}
                  <div className="min-w-max">
                    <table className="w-full">
                      <TableHeader advisorSpecifier={advisorSpecifier} />
                      <tbody className="divide-y divide-gray-200">
                        {getPaginatedData()?.map((item, i) => (
                          <TableRow
                            key={i}
                            item={item}
                            advisorSpecifier={advisorSpecifier}
                            setNotificationModal={setNotificationModal}
                            setSelectedItem={setSelectedItem}
                            handleResendAdvice={handleResendAdvice}
                            loading={loading}
                            loadingAdviceId={loadingAdviceId}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  {totalPages > 1 && (
                    <div className="border-t bg-gray-50 py-4 px-6">
                      <nav className="flex items-center justify-center gap-1">
                        <button
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                          disabled={currentPage === 1}
                          className={`p-2 rounded text-sm font-medium ${
                            currentPage === 1
                              ? "text-gray-400 cursor-not-allowed"
                              : "text-gray-700 hover:bg-gray-100"
                          }`}
                        >
                          Previous
                        </button>

                        <div className="flex items-center gap-1">
                          {getPageNumbers().map((pageNum, index) => (
                            <button
                              key={index}
                              onClick={() => {
                                if (typeof pageNum === "number") {
                                  setCurrentPage(pageNum);
                                }
                              }}
                              className={`w-10 h-10 flex items-center justify-center rounded text-sm font-medium ${
                                pageNum === currentPage
                                  ? "bg-blue-600 text-white"
                                  : pageNum === "..."
                                  ? "cursor-default"
                                  : "text-gray-700 hover:bg-gray-100"
                              }`}
                            >
                              {pageNum}
                            </button>
                          ))}
                        </div>

                        <button
                          onClick={() =>
                            setCurrentPage((prev) =>
                              Math.min(prev + 1, totalPages)
                            )
                          }
                          disabled={currentPage === totalPages}
                          className={`p-2 rounded text-sm font-medium ${
                            currentPage === totalPages
                              ? "text-gray-400 cursor-not-allowed"
                              : "text-gray-700 hover:bg-gray-100"
                          }`}
                        >
                          Next
                        </button>
                      </nav>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center py-12">
                  <h3 className="text-lg font-medium text-gray-900">
                    No Recommended Stocks
                  </h3>
                  <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
                    You haven't recommended any stocks yet. Explore and send
                    stock recommendations to populate this list.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <PreviousAdviceLogs />
          )}
        </div>
      </div>

      {notificationModal && (
        <SendNotificationModal
          setNotificationModal={setNotificationModal}
          selectedItem={selectedItem}
          handleResendAdvice={handleResendAdvice}
          loading={loading}
        />
      )}

      {alertModal && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-6 rounded-md shadow-md w-96 max-w-full mx-4">
            <h2 className="text-xl font-semibold mb-4">Set Alert</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Infy goes above
              </label>
              <input
                type="number"
                className="border rounded px-2 py-1 w-full"
                placeholder="Enter price"
              />
              <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full">
                Alert
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Infy goes below
              </label>
              <input
                type="number"
                className="border rounded px-2 py-1 w-full"
                placeholder="Enter price"
              />
              <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 w-full">
                Alert
              </button>
            </div>
            <button
              onClick={() => setAlertModal(false)}
              className="mt-4 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAdviceDetails;
