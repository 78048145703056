import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";

import Logo from "../assests/Logo.jpg";
import { auth, provider } from "../firebase";
import server from "../utils/serverConfig";
import SignInEmail from "./SignInEmail";
import SignUpEmail from "./SignUpEmail";
import ForgotPassword from "./forgotPassword";

const WithoutLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const [showforgotPassword, setShowForgotPassord] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const signIn = async () => {
    try {
      const response = await auth.signInWithPopup(provider);
      if (response) {
        const result = await axios.post(`${server.server.baseUrl}api/user/`, {
          email: response.additionalUserInfo.profile.email,
          name: response.additionalUserInfo.profile.name,
          firebaseId: response.additionalUserInfo.profile.id,
          imageUrl: response.additionalUserInfo.profile.picture,
          phoneNumber: 0,
          telegramId: "",
          profileCompletion: 50,
          user_onBoard_from: whiteLabelText,
        });

        const intendedDestination = sessionStorage.getItem(
          "intendedDestination"
        );
        // Check if the intended destination is either /subscriptions or /pricing
        const shouldKeepDestination =
          intendedDestination === "/subscriptions" ||
          intendedDestination === "/pricing";

        navigate(
          shouldKeepDestination ? intendedDestination : "/stock-recommendation"
        );
        navigate(
          intendedDestination === "/subscriptions"
            ? "/subscriptions"
            : "/stock-recommendation"
        );
        sessionStorage.removeItem("intendedDestination");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Store the current path when the component mounts
  useEffect(() => {
    // Don't store the path if we're already on the login page
    if (location.pathname !== "/user") {
      sessionStorage.setItem("intendedDestination", location.pathname);
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1700);

    return () => clearTimeout(timer);
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div className="relative w-full min-h-screen bg-[#f9f9f9] py-24 text-3xl font-semibold font-sans flex items-center justify-center">
        <svg
          className="h-10 w-10 text-[#000000] animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <div className="min-h-screen flex">
      {/* Left Welcome Panel */}
      <div className="hidden lg:flex w-1/2 bg-black text-white">
        <div className="max-w-2xl mx-auto px-8 py-12 flex flex-col justify-center">
          <h1 className="text-4xl font-bold mb-6">
            Welcome to {whiteLabelText}
          </h1>
          <p className="text-lg mb-8 text-gray-300">
            Congratulations, you are on right track on your financial journey.
            Track all your investments in one place while following your
            advisor. Make money while you sleep! Get personalized
            recommendations and make informed investment decisions.
          </p>
          <div className="bg-white/10 p-6 rounded-xl backdrop-blur-sm shadow-xl shadow-black/10 space-y-4">
            <p className="text-gray-300">
              Join a community of smart investors and get access to:
            </p>
            <ul className="space-y-2 text-gray-300">
              <li className="flex items-center">
                • Portfolio construction, tracking, and management
              </li>
              <li className="flex items-center">
                • Recommendations from your expert advisor
              </li>
              <li className="flex items-center">
                • Seamless execution of your advisor's recommendation in any
                broker
              </li>
            </ul>
            <div className="mt-6 pt-4 border-t border-white/10">
              <p className="text-gray-300">
                Have questions? Contact our support team at:
              </p>
              <a
                href="mailto:hello@alphaquark.in"
                className="text-blue-400 hover:text-blue-300 mt-2 block"
              >
                hello@alphaquark.in
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Right Auth Panel */}
      <div className="w-full lg:w-1/2 min-h-screen bg-gradient-to-b from-gray-50 to-white flex items-center justify-center p-6">
        <div className="w-full max-w-xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={
                showSignUp ? "signup" : showforgotPassword ? "forgot" : "signin"
              }
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.12)] p-8 lg:p-12"
            >
              {showSignUp ? (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900 text-center">
                      Join {whiteLabelText}
                    </h2>
                  </div>
                  <SignUpEmail
                    showSignUp={showSignUp}
                    setShowSignUp={setShowSignUp}
                  />
                </div>
              ) : showforgotPassword ? (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-900 text-center">
                      Reset Password
                    </h2>
                  </div>
                  <ForgotPassword
                    setShowForgotPassord={setShowForgotPassord}
                    showforgotPassword={showforgotPassword}
                  />
                </div>
              ) : (
                <div className="space-y-8">
                  <div className="flex flex-col items-center space-y-4">
                    <div className="w-28 h-28 rounded-full overflow-hidden ring-4 ring-gray-100 shadow-lg">
                      <img
                        src={Logo}
                        alt={`${whiteLabelText} Logo`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="text-center">
                      <h1 className="text-2xl font-bold text-gray-900">
                        {whiteLabelText}
                      </h1>
                      <p className="mt-2 text-lg text-gray-600 font-poppins">
                        Sign in to manage your investments
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={signIn}
                    className="w-full flex items-center justify-center space-x-3 py-4 px-6 bg-white border border-gray-200 rounded-xl hover:bg-gray-50 transition-colors duration-200 shadow-sm hover:shadow group"
                  >
                    <FcGoogle className="w-7 h-7" />
                    <span className="text-gray-700 font-base text-base sm:text-lg group-hover:text-gray-900 font-poppins">
                      Continue With Google
                    </span>
                  </button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-base">
                      <span className="px-4 text-gray-500 bg-white font-poppins">
                        or continue with email
                      </span>
                    </div>
                  </div>

                  <SignInEmail
                    setShowForgotPassord={setShowForgotPassord}
                    showforgotPassword={showforgotPassword}
                  />

                  <p className="text-center text-gray-600 text-base font-poppins">
                    No account?{" "}
                    <button
                      onClick={() => setShowSignUp(true)}
                      className="text-blue-600 hover:text-blue-700  font-medium font-poppins"
                    >
                      Sign up here
                    </button>
                  </p>

                  <div className="pt-6 border-t border-gray-200 font-poppins">
                    <p className="text-center text-sm text-gray-500  font-poppins">
                      By signing in, you agree to our{" "}
                      <Link
                        to="/terms-and-conditions-by-alphaquark"
                        className="text-blue-600 hover:text-blue-700  font-poppins"
                      >
                        Terms & Conditions
                      </Link>
                      ,{" "}
                      <Link
                        to="/privacy-policy"
                        className="text-blue-600 hover:text-blue-700"
                      >
                        Privacy Policy
                      </Link>
                      , and{" "}
                      <Link
                        to="/contact-us"
                        className="text-blue-600 hover:text-blue-700"
                      >
                        Contact Us
                      </Link>{" "}
                      for more info.
                    </p>
                  </div>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default WithoutLogin;
