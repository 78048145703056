import React, { useState, useRef, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import EmojiPicker from "emoji-picker-react";
import { Smile, X as XIcon } from "lucide-react";

const CommentModal = ({
  isOpen,
  onClose,
  initialValue = "",
  onSave,
  position,
  maxLength = 256,
}) => {
  const [comment, setComment] = useState(initialValue);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const modalRef = useRef(null);
  const textareaRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const emojiPickerRef = useRef(null);

  useEffect(() => {
    setComment(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isOpen && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleGlobalClick = (e) => {
      if (!isOpen) return;

      const isEmojiButton = emojiButtonRef.current?.contains(e.target);
      const isEmojiPicker = emojiPickerRef.current?.contains(e.target);
      const isModal = modalRef.current?.contains(e.target);

      if (!isModal && !isEmojiPicker && !isEmojiButton) {
        onClose();
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleGlobalClick);
    return () => document.removeEventListener("mousedown", handleGlobalClick);
  }, [isOpen, onClose]);

  const handleEmojiClick = useCallback((emojiData) => {
    setComment((prev) => prev + emojiData.emoji);
    // Close emoji picker after selecting emoji
    setShowEmojiPicker(false);
  }, []);

  const toggleEmojiPicker = useCallback((e) => {
    e.stopPropagation();
    setShowEmojiPicker((prev) => !prev);
  }, []);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        if (showEmojiPicker) {
          setShowEmojiPicker(false);
        } else {
          onClose();
        }
      }
    },
    [onClose, showEmojiPicker]
  );

  const handleSave = useCallback(() => {
    onSave(comment);
    onClose();
    setShowEmojiPicker(false);
  }, [comment, onSave, onClose]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }
  }, [isOpen, handleKeyDown]);

  if (!isOpen) return null;

  const characterCount = comment.length;
  const isOverLimit = characterCount > maxLength;

  return (
    <div
      className="fixed inset-0 z-50"
      onMouseDown={(e) => e.stopPropagation()}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        ref={modalRef}
        className="fixed bg-white rounded-lg shadow-xl border border-gray-200"
        style={{
          top: position.top,
          left: position.left,
          width: "320px",
          zIndex: 1000,
        }}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h3 className="text-sm font-medium text-gray-700">Edit Comment</h3>
          <button
            onClick={() => {
              onClose();
              setShowEmojiPicker(false);
            }}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <XIcon className="h-4 w-4 text-gray-500" />
          </button>
        </div>

        <div className="p-4">
          <div className="relative">
            <textarea
              ref={textareaRef}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="w-full p-3 border text-black border-gray-300 rounded-md focus:ring-2 focus:ring-black focus:border-transparent text-sm"
              rows="4"
              placeholder="Enter your comment..."
              maxLength={maxLength}
            />

            <button
              ref={emojiButtonRef}
              type="button"
              onClick={toggleEmojiPicker}
              className="absolute right-2 bottom-2 p-1.5 rounded-full hover:bg-gray-100 transition-colors"
            >
              <Smile
                className={`h-4 w-4 ${
                  showEmojiPicker ? "text-black" : "text-gray-500"
                }`}
              />
            </button>
          </div>

          <div className="mt-2 flex items-center justify-between">
            <span
              className={`text-xs ${
                isOverLimit ? "text-red-500" : "text-gray-500"
              }`}
            >
              {characterCount}/{maxLength} characters
            </span>
          </div>

          <AnimatePresence>
            {showEmojiPicker && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.15 }}
                ref={emojiPickerRef}
                className="absolute top-0 right-0 mt-2"
                style={{ zIndex: 1001 }}
              >
                <div className="bg-white rounded-lg shadow-xl">
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    width={280}
                    height={350}
                    previewConfig={{ showPreview: false }}
                    searchPlaceHolder="Search emoji..."
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="flex justify-end gap-2 p-4 border-t border-gray-200 bg-gray-50">
          <button
            onClick={() => {
              onClose();
              setShowEmojiPicker(false);
            }}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={isOverLimit}
            className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
          >
            Save
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default CommentModal;
