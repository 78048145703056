import React, { useState, useEffect } from "react";
import { GitForkIcon, Info } from "lucide-react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import { Loader2 } from "lucide-react";
import { auth } from "../../firebase";
import Alpha100 from "../../assests/alpha-100.png";
import server from "../../utils/serverConfig";
import UserStrategySubscribeModal from "./UserStrategySubscribeModal";
import formatCurrency from "../../utils/formatCurrency";
import ConnectBroker from "../LivePortfolioSection/connectBroker";
import RecommendationSuccessModal from "../StockRecommendation/RecommendationSuccessModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import { fetchFunds } from "../../FunctionCall/fetchFunds";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import Meter from "../../assests/Icon/meter.svg";
import PlanSubscribeModal from "../PricingSection/PlanSubscribeModal";
import PaymentSuccessModal from "../PricingSection/PaymentSuccessModal";

import ModelPFCardDetailsSection from "../ModelPortfolioSection/ModelPFCardDetailsSection";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px]",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const StrategyList = () => {
  const navigate = useNavigate();
  const rootUrl = process.env.REACT_APP_URL;
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
  const [allStrategy, setAllStrategy] = useState([]);
  const [openSubscribeModel, setOpenSubscribeModel] = useState(false);
  const [brokerModel, setBrokerModel] = useState(false);
  const [openSuccessModal, setOpenSucessModal] = useState(false);
  const [orderPlacementResponse, setOrderPlacementResponse] = useState();
  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [invetAmount, setInvestAmount] = useState();
  const [showAfterPlaceOrderDdpiModal, setShowAfterPlaceOrderDdpiModal] =
    useState(false);
  const advisorName = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;

  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [userDetails, setUserDetails] = useState();

  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDetails();
  }, [userEmail, server.server.baseUrl]);

  const [broker, setBroker] = useState("");

  const clientCode = userDetails && userDetails?.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const jwtToken = userDetails && userDetails?.jwtToken;
  const my2pin = userDetails && userDetails?.my2Pin;
  const secretKey = userDetails && userDetails?.secretKey;
  const userId = userDetails && userDetails?._id;

  const [name, setName] = useState(userDetails ? userDetails?.name : null);
  const [mobileNumber, setMobileNumber] = useState(
    userDetails ? userDetails?.phone_number : null
  );
  const [panNumber, setPanNumber] = useState(
    userDetails ? userDetails?.panNumber : null
  );

  const [birthDate, setBirthDate] = useState("");
  const dateString = userDetails && userDetails.token_expire;
  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  useEffect(() => {
    if (userDetails) {
      setBroker(userDetails.user_broker);
      setMobileNumber(userDetails?.phone_number);
      setPanNumber(userDetails?.panNumber);
      setName(userDetails?.name);
    }
  }, [userDetails]);
  const [openTokenExpireModel, setOpenTokenExpireModel] = useState(null);

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const getAllStrategy = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/model portfolio/${userEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAllStrategy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllStrategy();
  }, []);

  // payment start
  const [paymentModal, setPaymentModal] = useState(false);
  const [specificPlan, setSpecificPlan] = useState();
  const [specificPlanDetails, setSpecificPlanDetails] = useState();
  const handlePricingCardClick = (modelName) => {
    setPaymentModal(true);
    setSpecificPlan(modelName);
    setSpecificPlanDetails(modelName);
    setFileName(modelName?.name);
  };

  const [isPostPaymentProcessing, setIsPostPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [selectedCard, setSelectedCard] = useState(null); // To track the selected card
  const [planDetails, setPlanDetails] = useState(null);

  const getSpecificPlan = () => {
    if (specificPlan) {
      axios
        .get(
          `${server.server.baseUrl}api/admin/plan/detail/specific/${specificPlan._id}/${userEmail}`
        )
        .then((res) => {
          setPlanDetails(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (specificPlan) {
      getSpecificPlan();
    }
  }, [specificPlan]);

  const formattedName = specificPlanDetails?.name
    ? specificPlanDetails.name.includes(" ") // Check if there are spaces
      ? specificPlanDetails.name.toLowerCase().replace(/\s+/g, "_") // If spaces, replace them
      : specificPlanDetails.name.toLowerCase() // If no spaces, just lowercase
    : "";

  const handleCardClickSelect = (item) => {
    setSelectedCard(item); // Set the selected card
    // Call your subscribe function
  };
  const [inputValue, setInputValue] = useState("");
  const clientId = userDetails?.clientId || uuidv4().slice(0, 7);

  const handleOk = () => {
    const sip_amount = inputValue;

    // Format the data before making API call
    const formatUserData = () => {
      // Safely format countryCode
      let formattedCountryCode = null;
      if (countryCode) {
        // Check if countryCode is already a number
        formattedCountryCode =
          typeof countryCode === "number"
            ? countryCode
            : parseInt(countryCode.toString().replace("+", ""));
      }

      return {
        email: userEmail,
        phoneNumber: mobileNumber ? parseInt(mobileNumber) : null,
        countryCode: formattedCountryCode,
        telegramId: "",
        userName: name || "",
        profileCompletion: 75,
        // clientId: clientId || "",
        advisorName: advisorName,
      };
    };

    if (planDetails?.frequency?.length !== 0) {
      subscribeToPlan(selectedCard, sip_amount);

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/user/update/user-details`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(formatUserData()),
      };

      axios
        .request(config)
        .then((response) => {
          console.log("User details updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error updating user details:", error);
          toast.error("Failed to update user details");
        });
    } else {
      handleSinglePayment(planDetails?.amount);

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/user/update/user-details`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(formatUserData()),
      };

      axios
        .request(config)
        .then((response) => {
          console.log("User details updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error updating user details:", error);
          toast.error("Failed to update user details");
        });
    }
  };

  const sendEmailNotification = async (
    email,
    planDetails,
    userName,
    panNumber,
    advisorName,
    data
  ) => {
    try {
      const latestPayment = data?.subscription?.payment_history?.sort(
        (a, b) => new Date(b?.payment_date) - new Date(a?.payment_date)
      )[0];

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      const emailData = [
        {
          template_name: "new_plan2",
          template_body_values: [
            userName || email,
            `${
              latestPayment?.payment_type === "extension" &&
              latestPayment?.previous_end_date
                ? latestPayment?.previous_end_date
                : latestPayment?.payment_date
            }`,
            `${latestPayment?.new_end_date}`,
          ],
          trade_given_by: advisorName,
          recipient_email: email,
          plan_name: planDetails.name,
          pan: panNumber,
        },
      ];

      // Send email using template
      const emailResponse = await axios.post(
        `${server.ccxtServer.baseUrl}comms/email/send-template-messages/supported-broker`,
        emailData
      );

      if (emailResponse.data.status !== 0) {
        console.error("Email sending failed:", emailResponse.data);
      }

      return emailResponse;
    } catch (error) {
      console.error("Email notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        email: email,
      });
      // Don't throw error to prevent disrupting the main flow
    }
  };

  // Function to add days to a date and return ISO string
  const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  };

  // Example usage in your WhatsApp notification function
  const sendWhatsAppNotification = async (
    phoneNumber,
    countryCode,
    planDetails,
    userName,
    email,
    advisorName,
    data
  ) => {
    try {
      const latestPayment = data.subscription.payment_history.sort(
        (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
      )[0];
      // Format phone number - remove any non-digit characters
      let formattedPhone: *;
      formattedPhone = phoneNumber.toString().replace(/\D/g, "");
      let formattedCountryCode = countryCode; //.toString().replace(/\D/g, '');
      // Then add '+' prefix if it doesn't exist
      formattedCountryCode = formattedCountryCode.startsWith("+")
        ? formattedCountryCode
        : `+${formattedCountryCode}`;

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      if (latestPayment?.payment_type === "extension") {
        // console.log("calling", planDetails.isRenewal);
        await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          {
            phone_number: formattedPhone,
            template_name: "new_plan2",
            template_body_values: [
              userName || email,
              latestPayment?.payment_type === "extension"
                ? "renewed"
                : "subscribed",
              `${
                latestPayment?.payment_type === "extension" &&
                latestPayment?.previous_end_date
                  ? latestPayment?.previous_end_date
                  : latestPayment?.payment_date
              }`,
              `${latestPayment?.new_end_date}`,
              advisorName,
            ],
            template_button_values: [getAdvisorCodomain(advisorName)],
            template_header_values: [
              latestPayment?.payment_type === "extension"
                ? "Renewed"
                : "Subscribed",
            ],
            country_code: countryCode,
            callback_data: "Standard Callback",
            language_code: "en",
          }
        );
      } else {
        const trackUserResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/track-user`,
          {
            phone_number: formattedPhone,
            country_code: formattedCountryCode,
            user_traits: {
              name: userName || email,
              email: email,
              advisor: advisorName,
              advisor_codomain: rootUrl,
              whatsapp_opted_in: true,
            },
            tags: [advisorName, "internal_team"],
          }
        );

        if (trackUserResponse.data.result.result === true) {
          // Then send template message
          await axios.post(
            `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
            {
              phone_number: formattedPhone,
              template_name: "new_plan2",
              template_body_values: [
                userName || email,
                latestPayment?.payment_type === "extension"
                  ? "renewed"
                  : "subscribed",
                `${
                  latestPayment?.payment_type === "extension" &&
                  latestPayment?.previous_end_date
                    ? latestPayment?.previous_end_date
                    : latestPayment?.payment_date
                }`,
                `${latestPayment?.new_end_date}`,
                advisorName,
              ],
              template_button_values: [getAdvisorCodomain(advisorName)],
              template_header_values: [
                latestPayment?.payment_type === "extension"
                  ? "Renewed"
                  : "Subscribed",
              ],
              country_code: countryCode,
              callback_data: "Standard Callback",
              language_code: "en",
            }
          );
        }
      }
      // First track the user
    } catch (error) {
      console.error("WhatsApp notification error:", error);
      // Don't throw error to prevent disrupting the main flow
    }
  };

  const sendNotifications = async (notificationData) => {
    const {
      email,
      phoneNumber,
      countryCode,
      panNumber,
      planDetails,
      userName,
      advisorName,
      data,
    } = notificationData;

    try {
      // Send email first
      await sendEmailNotification(
        email,
        planDetails,
        userName,
        panNumber,
        advisorName,
        data
      );

      // Then send WhatsApp notification
      await sendWhatsAppNotification(
        phoneNumber,
        countryCode,
        planDetails,
        userName,
        email,
        advisorName,
        data
      );
    } catch (error) {
      console.error("Notification error:", error);
      // Don't throw error to prevent disrupting the main flow
    }
  };

  const getStrategyDetails = () => {
    if (fileName !== null) {
      axios
        .get(
          `${
            server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
          setLoading(null);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  function calculateNewExpiryDate(currentExpiry, plan) {
    const newExpiry = new Date(currentExpiry);

    if (plan.frequency) {
      // For recurring subscriptions
      switch (plan.frequency) {
        case "monthly":
          newExpiry.setMonth(newExpiry.getMonth() + 1);
          break;
        case "quarterly":
          newExpiry.setMonth(newExpiry.getMonth() + 3);
          break;
        case "yearly":
          newExpiry.setFullYear(newExpiry.getFullYear() + 1);
          break;
        default:
          newExpiry.setMonth(newExpiry.getMonth() + 1); // Default to monthly
      }
    } else {
      // For one-time payments
      // Add the plan duration (assuming it's in days)
      newExpiry.setDate(newExpiry.getDate() + (plan.duration || 30)); // Default to 30 days if not specified
    }

    return newExpiry;
  }
  async function subscribeToPlan(frequency, sip_amount) {
    try {
      setLoading(true);
      // Fetch subscription details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription`,
        {
          plan_id: planDetails._id,
          frequency,
          user_email: userEmail,
          sip_amount,
          advisor: advisorTag,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const subscriptionData = response.data.data;

      console.log(subscriptionData, "subscriptionData");

      if (subscriptionData.razorpay_subscription_id) {
        // Initialize Razorpay with the subscription details
        const options = {
          key: razorPayKey, // Your Razorpay Key ID
          subscription_id: subscriptionData.razorpay_subscription_id, // The subscription ID from Razorpay
          name: subscriptionData.plan_id.name, // Plan or product name
          description: subscriptionData.plan_id.description, // Description of the plan
          amount: subscriptionData.amount, // Amount in smallest unit (paise for INR)
          currency: "INR", // Currency (e.g., INR)
          handler: function (response) {
            // This will execute after successful payment
            console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            console.log(
              "Razorpay Subscription ID:",
              response.razorpay_subscription_id
            );
            console.log("Razorpay Signature:", response.razorpay_signature);

            // Now, you can call the backend to save the subscription status and other details
            if (userId) {
              setIsPostPaymentProcessing(true);
              completeSubscription(response);
            }
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "", // User's name
            email: userEmail, // User's email
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching subscription data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error subscribing to plan:", error);
      setLoading(true);
    }
  }

  const logPayment = async (type, data) => {
    try {
      await axios.post(`${server.server.baseUrl}api/log-payment`, {
        type,
        data,
      });
    } catch (error) {
      console.error("Failed to log payment:", error);
    }
  };
  // Function to handle the backend call after successful payment
  async function completeSubscription(paymentDetails) {
    try {
      // Send payment details to the backend to finalize the subscription
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/complete-payment`,
        paymentDetails
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("SUBSCRIPTION_PAYMENT_SUCCESS", {
        subscriptionId: data.subscription.razorpay_subscription_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: userEmail,
        plan: formattedName,
        planType: specificPlan?.frequency,
        duration:
          specificPlan?.frequency === "monthly"
            ? "30"
            : specificPlan?.frequency === "quarterly"
            ? "90"
            : "365",
      });
      setPaymentSuccess(true);
      setRefresh((prev) => !prev);

      // Send notifications (email and WhatsApp)
      try {
        await sendNotifications({
          email: userEmail,
          phoneNumber: mobileNumber,
          countryCode: countryCode,
          panNumber: panNumber,
          planDetails: {
            isRenewal: false,
            duration:
              specificPlan?.frequency === "monthly"
                ? "30"
                : specificPlan?.frequency === "quarterly"
                ? "90"
                : "365",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
            frequency: specificPlan?.frequency,
            data: data,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // Add this parameter
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
        // Continue execution - notification failure shouldn't stop the process
      }

      const newSubscription = {
        startDate: new Date(),
        plan: formattedName || "", // Assuming the response contains a plan
        capital: data.subscription.capital || 0, // Assuming the response contains capital
        charges: data.subscription.amount || 0, // Assuming the response contains charges
        invoice: data.subscription.razorpay_subscription_id || "", // Assuming the response contains invoice
        expiry: new Date(data.expiry), // Assuming the response contains expiry date
      };

      const newClientData = {
        clientName: name || "", // Assuming the response contains a client name
        email: data.subscription.user_email || "", // Assuming the response contains an email
        phone: mobileNumber || "", // Assuming the response contains a phone number
        groups: [`All Client`, formattedName], // Add formatted name dynamically
        location: data.location || "", // Assuming the response contains a location
        telegram: data.telegram || "", // Assuming the response contains a Telegram ID
        pan: panNumber || "", // Assuming the response contains a PAN number
        comments: data.comments || "", // Assuming the response contains comments
        subscriptions: [
          {
            ...newSubscription, // Attach the new subscription here
          },
        ],
      };

      try {
        // Send a POST request to add the new client
        const response = await fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: specificPlanDetails?.adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: newClientData,
            }),
          }
        );

        const result = await response.json();

        await logPayment("SUBSCRIPTION_CLIENT_ADDED", {
          clientName: newClientData.clientName,
          plan: formattedName,
          subscriptionId: newSubscription.subId,
          subscriptionDetails: {
            startDate: newSubscription.startDate,
            expiry: newSubscription.expiry,
            amount: newSubscription.charges,
          },
        });
      } catch (error) {
        console.error("Error adding client:", error);
        await logPayment("SUBSCRIPTION_CLIENT_ADD_ERROR", {
          error: error.message,
          clientName: data.subscription.name,
          email: data.subscription.user_email,
        });
      }

      let payloadData = JSON.stringify({
        email: userEmail,
        action: "subscribe",
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payloadData,
      };

      axios
        .request(config)
        .then((response) => {
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });

      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: latestRebalance.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      axios
        .request(config2)
        .then(async (response) => {
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });
      getAllStrategy();
    } catch (error) {
      console.error("Error completing subscription:", error);
      await logPayment("SUBSCRIPTION_PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail,
        amount: specificPlan?.amount,
        plan: formattedName,
        planType: specificPlan?.frequency,
      });
    }
  }
  // payment end

  const handleCardClick = (modelName) => {
    navigate(`/model-portfolio/${modelName.toLowerCase().replace(/ /g, "_")}`);
  };
  const handleViewMore = (modelName) => {
    navigate(
      `/model-portfolio/subscribed/${modelName
        .toLowerCase()
        .replace(/ /g, "_")}`
    );
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const [funds, setFunds] = useState({});

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const [loading, setLoading] = useState(null);

  async function handleSinglePayment(amount) {
    try {
      setLoading(true);
      // Fetch one-time payment details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription`,
        {
          plan_id: planDetails?._id,
          user_email: userEmail,
          amount,
          advisor: advisorTag,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const paymentData = response.data.data;

      console.log(response.data, "paymentData");

      if (paymentData.razorpay_order_id) {
        // Initialize Razorpay with the order details
        const options = {
          key: razorPayKey,
          order_id: paymentData.razorpay_order_id,
          name: paymentData.plan_id.name,
          description: paymentData?.razorpay_order_id,
          amount: paymentData.amount,
          currency: "INR",
          handler: function (response) {
            console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            console.log("Razorpay Order ID:", response.razorpay_order_id);
            console.log("Razorpay Signature:", response.razorpay_signature);

            if (userId) {
              setIsPostPaymentProcessing(true);
              completeSinglePayment(response);
            }
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "",
            email: userEmail,
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching one-time payment data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
      setLoading(false);
    }
  }

  async function completeSinglePayment(paymentDetails) {
    try {
      // First, check for existing payment and plan
      const existingPayment = await axios.post(
        `${server.server.baseUrl}api/subscription-check/check-payment-status`,
        {
          razorpay_order_id: paymentDetails.razorpay_order_id,
          plan_id: specificPlan?._id,
          user_email: userEmail,
        }
      );

      if (existingPayment.data.orderExists) {
        throw new Error("This payment has already been processed");
      }

      let expiryDate;
      let isSubscriptionExtension = false;

      // Check for existing subscription with same plan
      const existingSubscription = await axios.get(
        `${server.server.baseUrl}api/subscription-check/user/${userEmail}/plan/${specificPlan?._id}`
      );

      if (existingSubscription.data.subscription) {
        isSubscriptionExtension = true;
        // Calculate new expiry based on existing subscription
        expiryDate = calculateNewExpiryDate(
          existingSubscription.data.subscription.end_date,
          specificPlan
        );
      }

      // Complete payment with backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription/complete-one-time-payment`,
        {
          ...paymentDetails,
          user_email: userEmail,
          advisor_email: specificPlan?.advisor_email,
          plan_id: specificPlan?._id,
          amount: specificPlan?.amount,
          end_date:
            expiryDate ||
            new Date(
              new Date().setDate(
                new Date().getDate() + (specificPlan?.duration || 30)
              )
            ),
          newExpiryDate: expiryDate,
        }
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("PAYMENT_SUCCESS", {
        orderId: data.subscription.razorpay_order_id,
        amount: specificPlan?.amount,
        clientName: name,
        email: userEmail,
        plan: formattedName,
      });
      setPaymentSuccess(true);

      // Send notifications
      try {
        await sendNotifications({
          email: userEmail,
          phoneNumber: mobileNumber,
          panNumber: panNumber,
          countryCode: countryCode || "+91",
          planDetails: {
            isRenewal: isSubscriptionExtension,
            duration: specificPlan?.duration || "30",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // or 'unsupported-broker' based on your needs
          data: data,
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
        // Continue execution - notification failure shouldn't stop the process
      }

      if (strategyDetails) {
        let data2 = JSON.stringify({
          userEmail: userEmail,
          model: strategyDetails?.model_name,
          advisor: strategyDetails?.advisor,
          model_id: latestRebalance.model_Id,
          userBroker: broker ? broker : "",
          subscriptionAmountRaw: [
            {
              amount: invetAmount,
              dateTime: new Date(),
            },
          ],
        });

        let config2 = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data2,
        };

        axios
          .request(config2)
          .then(async (response) => {
            let whatsappData = JSON.stringify({
              phone_number: mobileNumber,
              template_name: "model_pf_sub_3c",
              template_body_values: [
                `${name}`,
                `${strategyDetails?.model_name}`,
                `${advisorTag}`,
              ],
              template_button_values: [`${rootUrl}`],
              country_code: countryCode,
              callback_data: "Standard Callback",
              language_code: "en",
            });

            let whatsappConfig = {
              method: "post",
              url: `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
              headers: {
                "Content-Type": "application/json",
              },
              data: whatsappData,
            };

            await axios.request(whatsappConfig);
            getStrategyDetails();
          })
          .catch((error) => {
            console.log(error);
          });
        updateStrategySubscription();
      }

      const newSubscription = {
        startDate: new Date(),
        plan: formattedName || "",
        capital: invetAmount || 0,
        charges: specificPlan?.amount || 0,
        invoice: paymentDetails.razorpay_order_id || "",
        expiry: expiryDate || data.subscription.end_date,
      };

      // Update client data in same way as before
      const clientResponse = await handleClientUpdate(
        isSubscriptionExtension,
        newSubscription,
        specificPlan?.adminId,
        data.subscription
      );

      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: latestRebalance.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      axios
        .request(config2)
        .then(async (response) => {
          let whatsappData = JSON.stringify({
            phone_number: mobileNumber,
            template_name: "model_pf_sub_3c",
            template_body_values: [
              `${name}`,
              `${strategyDetails?.model_name}`,
              `${advisorTag}`,
            ],
            template_button_values: [`${rootUrl}`],
            country_code: countryCode,
            callback_data: "Standard Callback",
            language_code: "en",
          });

          let whatsappConfig = {
            method: "post",
            url: `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
            headers: {
              "Content-Type": "application/json",
            },
            data: whatsappData,
          };

          await axios.request(whatsappConfig);
          getStrategyDetails();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error completing payment:", error);
      await logPayment("PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail,
        amount: specificPlan?.amount,
      });
      toast.error(`Error processing payment: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  }

  // Helper function to handle client data updates
  async function handleClientUpdate(
    isExtension,
    newSubscription,
    adminId,
    subscriptionData
  ) {
    const clientData = {
      clientName: name || "",
      email: subscriptionData.user_email || "",
      phone: mobileNumber || "",
      groups: [`All Client`, formattedName],
      location: "",
      telegram: "",
      pan: panNumber || "",
      creationDate: new Date(),
      subscriptions: [newSubscription],
    };

    try {
      const checkClientResponse = await fetch(
        `${server.server.baseUrl}api/add-subscriptions/check-client`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: adminId,
            email: clientData.email,
          }),
        }
      );

      const checkClientResult = await checkClientResponse.json();
      await logPayment("CLIENT_ADDED", {
        // clientId: clientData.clientId,
        clientName: name,
        plan: formattedName,
        subscriptionDetails: newSubscription,
      });
      if (checkClientResult.clientExists) {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
          }
        );
      } else {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: specificPlan?.adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
          }
        );
      }
    } catch (error) {
      console.error("Error updating client data:", error);
      await logPayment("CLIENT_ADD_FAILURE", {
        error: error.message,
        clientName: name,
        email: userEmail,
      });
      throw error;
    }
  }

  // Existing subscribeToPlan and completeSubscription functions remain unchanged

  async function updateStrategySubscription() {
    try {
      let payloadData = JSON.stringify({
        email: userEmail,
        action: "subscribe",
      });

      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payloadData,
      };

      await axios.request(config);
      getStrategyDetails();
      getAllStrategy();
      let data2 = JSON.stringify({
        userEmail: userEmail,
        model: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: strategyDetails?.model_Id,
        userBroker: broker ? broker : "",
        subscriptionAmountRaw: [
          {
            amount: invetAmount,
            dateTime: new Date(),
          },
        ],
      });

      let config2 = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/insert-user-doc`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data2,
      };

      await axios.request(config2);
      getStrategyDetails();
    } catch (error) {
      console.error("Error updating strategy subscription:", error);
    }
  }
  const isSubscriptionActive = (subscription) => {
    if (!subscription?.end_date) return false;
    const endDate = new Date(subscription.end_date);
    const now = new Date();
    return endDate > now;
  };

  const isNearExpiry = (subscription) => {
    if (!subscription?.end_date) return false;
    const endDate = new Date(subscription.end_date);
    const now = new Date();
    const daysUntilExpiry = Math.ceil((endDate - now) / (1000 * 60 * 60 * 24));
    return daysUntilExpiry <= 7 && daysUntilExpiry > 0;
  };

  const formatDate = (date) => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const [countryCode, setCountryCode] = useState("+91");
  const [isBlurred, setIsBlurred] = useState(true);

  const handleConsentAccept = () => {
    setIsBlurred(false);
  };

  return (
    <div className="min-h-screen w-full bg-[#F9F9F9] overflow-x-hidden">
      <Toaster position="top-center" reverseOrder={true} />
      {isLoading ? (
        <div className="flex flex-col relative  w-full min-h-screen  bg-[#f9f9f9]">
          <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
            <svg
              className="h-10 w-10 text-[#000000] animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <div>
          <div className="px-4  md:px-10 flex flex-row  justify-between border-b border-[#000000]/20  h-[50px] lg:h-[60px] font-poppins">
            <div className="flex flex-row space-x-6 lg:space-x-2">
              <div
                className={`
              ${style.selected} flex items-center justify-center`}
              >
                All Model Portfolio
              </div>
            </div>
          </div>

          <ModelPFCardDetailsSection
            handlePricingCardClick={handlePricingCardClick}
            allPricingPlan={allStrategy}
          />
        </div>
      )}

      {openSubscribeModel === true && latestRebalance !== null ? (
        <UserStrategySubscribeModal
          setOpenSubscribeModel={setOpenSubscribeModel}
          userEmail={userEmail}
          getStrategyDetails={getAllStrategy}
          strategyDetails={strategyDetails}
          fileName={fileName}
          latestRebalance={latestRebalance}
          userDetails={userDetails}
          setOpenSucessModal={setOpenSucessModal}
          setOrderPlacementResponse={setOrderPlacementResponse}
          setBrokerModel={setBrokerModel}
          clientCode={clientCode}
          apiKey={apiKey}
          secretKey={secretKey}
          jwtToken={jwtToken}
          broker={broker}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          todayDate={todayDate}
          expireTokenDate={expireTokenDate}
        />
      ) : null}

      {openSuccessModal && (
        <RecommendationSuccessModal
          setOpenSucessModal={setOpenSucessModal}
          orderPlacementResponse={orderPlacementResponse}
          setStockDetails={setStrategyDetails}
          openSuccessModal={openSuccessModal}
          userDetails={userDetails}
          setShowAfterPlaceOrderDdpiModal={setShowAfterPlaceOrderDdpiModal}
        />
      )}
      {brokerModel === true ? (
        <ConnectBroker
          uid={userDetails && userDetails._id}
          userDetails={userDetails && userDetails}
          setBrokerModel={setBrokerModel}
          getUserDetails={getUserDetails}
          broker={broker}
          setBroker={setBroker}
          brokerModel={brokerModel}
        />
      ) : null}
      {openTokenExpireModel === true ? (
        <TokenExpireBrokarModal
          openTokenExpireModel={openTokenExpireModel}
          setOpenTokenExpireModel={setOpenTokenExpireModel}
          userId={userId && userId}
          apiKey={apiKey}
          secretKey={secretKey}
          checkValidApiAnSecret={checkValidApiAnSecret}
          clientCode={clientCode}
          my2pin={my2pin}
          panNumber={panNumber}
          mobileNumber={mobileNumber}
          broker={broker}
          getUserDetails={getUserDetails}
        />
      ) : null}

      {paymentModal === true ? (
        <PlanSubscribeModal
          userEmail={userEmail}
          strategyDetails={planDetails}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleCardClick={handleCardClickSelect}
          selectedCard={selectedCard}
          handleOk={handleOk}
          loading={loading}
          invetAmount={invetAmount}
          setInvestAmount={setInvestAmount}
          setName={setName}
          name={name}
          setMobileNumber={setMobileNumber}
          mobileNumber={mobileNumber}
          setPanNumber={setPanNumber}
          panNumber={panNumber}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
        />
      ) : null}
      {isPostPaymentProcessing && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we confirm your subscription...
            </p>
          </div>
        </div>
      )}
      {paymentSuccess === true ? (
        <PaymentSuccessModal
          specificPlanDetails={specificPlanDetails}
          setPaymentSuccess={setPaymentSuccess}
          setSelectedCard={setSelectedCard}
          setOpenSubscribeModel={setOpenSubscribeModel}
        />
      ) : null}
    </div>
  );
};

export default StrategyList;
