import React from "react";
import { PlusIcon } from "lucide-react";

const ClientListDetailsTableHeader = ({ handleAddRow }) => {
  return (
    <thead className="bg-[#f5f5f5] sticky top-0 z-20">
      <tr>
        <th className="w-[50px]  py-3 font-medium text-[13px] font-poppins text-white border-r-[1px] border-[#000000]/10">
          <div className="flex justify-center">
            <div
              onClick={handleAddRow}
              className="w-5 h-5 bg-[#000000] rounded-[2px] flex items-center justify-center cursor-pointer"
            >
              <PlusIcon
                strokeWidth={3}
                className="text-[#ffffff] w-[18px] h-[18px]"
              />
            </div>
          </div>
        </th>
        <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Client Name
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>
        <th className="px-3 py-3 w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Email Id
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>

        <th className="px-3 py-3 max-w-[200px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Group Name(s)
        </th>
        <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Country Code
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>
        <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Phone No.
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>
        <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Date Of Birth
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>
        <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Location
        </th>
        <th className="px-3 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          Telegram Id
        </th>
        <th className="px-2 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          PAN Number
        </th>
        <th className="px-2 py-3 min-w-[120px] font-medium text-[13px] font-poppins text-[#000000]/80  text-left border-r-[1px] border-[#000000]/10">
          KYC validated?
        </th>
        <th className="px-3 py-3 min-w-[150px] font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
          Creation Date
          <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
        </th>
        {/* <th className="px-3 py-3 min-w-[150px] font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
            Plan Expiry Date
            <span className="ml-1 text-[#E6626F] text-[14px]">*</span>
          </th> */}
        <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
          Plans
        </th>
        <th className="px-3 py-3 font-medium text-[13px] font-poppins text-[#000000]/80  text-center border-r-[1px] border-[#000000]/10">
          Actions
        </th>
      </tr>
    </thead>
  );
};

export default ClientListDetailsTableHeader;
