import React from "react";
import { TableHeader } from "../../TableHeader";

const ClosureAdvicePopup = ({ isOpen, onClose, selectedAdvices, onSend }) => {
  return (
    <div
      className={`fixed inset-0 bg-black/50 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-5xl max-h-[90vh] flex flex-col">
        <h2 className="text-xl font-bold mb-4">Send Closure Advice</h2>
        <div className="flex-grow overflow-auto">
          <p className="mb-2">Selected advices:</p>
          <table className="w-full mb-4">
            <TableHeader />
            <tbody>
              {selectedAdvices?.map((advice, index) => (
                <tr key={index}>
                  <td className="py-2 px-4">{advice?.symbol}</td>
                  <td className="py-2 px-4">{advice?.type}</td>
                  <td className="py-2 px-4">
                    {advice?.price_when_send_advice}
                  </td>
                  <td className="py-2 px-4">
                    <input
                      type="number"
                      readOnly
                      value={advice?.quantity}
                      className="border rounded px-2 py-1 w-20"
                    />
                  </td>
                  <td className="py-2 px-4">{advice?.exchange}</td>
                  <td className="py-2 px-4">{advice?.order_type}</td>
                  <td className="py-2 px-4">{advice?.product_type}</td>
                  <td className="py-2 px-4">
                    <textarea
                      value={advice?.comments}
                      readOnly
                      className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                    />
                  </td>
                  <td className="py-1 px-4">
                    <textarea
                      value={advice?.comment2}
                      readOnly
                      className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                    />
                  </td>
                  <td className="py-1 px-4">
                    <textarea
                      value={advice?.rationale}
                      readOnly
                      className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end space-x-2 mt-4 pt-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onSend}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClosureAdvicePopup;
