import React from "react";

const NoTradeFailureModal = () => {
  return (
    <div className="flex flex-col items-center justify-center p-6 mx-auto w-full max-w-2xl min-h-[20rem] bg-white rounded-lg shadow-lg">
      <div className="w-full flex justify-center mb-6">
        {/* Using a placeholder image since we can't import external assets */}
        <img
          src="/api/placeholder/180/180"
          alt="See if your investments are being missed by subscribers"
          className="w-32 md:w-44 lg:w-48 rounded-md"
        />
      </div>

      <h3 className="text-xl md:text-2xl text-black font-semibold mb-4 text-center">
        No Trade Failure & Inconsistency
      </h3>

      <p className="text-sm md:text-base text-center text-black/60 max-w-md mx-auto">
        All trades processed successfully with no Trade Failures &
        Inconsistencies.
      </p>
    </div>
  );
};

export default NoTradeFailureModal;