import React from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Toaster } from "react-hot-toast";
import { Eye, FileSpreadsheet, Users } from "lucide-react";
import GroupCard from "./GroupCard";
import moment from "moment";

const GroupListBlock = ({
  userId,
  allClientsData = [],
  allGroupsData = [],
  adminEmail,
  advisorName,
  email_password,
  adminRationale,
  advisorSpecifier,
}) => {
  const csvHeaders = [
    { label: "client_id", key: "client_id" },
    { label: "client_name", key: "client_name" },
    { label: "email", key: "email" },
    { label: "phone", key: "phone" },
    { label: "groups", key: "groups" },
    { label: "location", key: "location" },
    { label: "telegram", key: "telegram" },
    { label: "pan", key: "pan" },
    { label: "comments", key: "comments" },
    { label: "subscription_id", key: "subscription_id" },
    { label: "start_date", key: "start_date" },
    { label: "plan", key: "plan" },
    { label: "capital", key: "capital" },
    { label: "charges", key: "charges" },
    { label: "invoice", key: "invoice" },
    { label: "expiry", key: "expiry" },
    { label: "acc_creation_date", key: "acc_creation_date" },
    { label: "updated_at", key: "updated_at" },
  ];

  const FormatDateForCSV = (date) => {
    if (!date) return "";
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const flattenClientsData = (clients = []) => {
    if (!Array.isArray(clients) || clients.length === 0) return [];

    return clients.flatMap((client) => {
      if (!client || !Array.isArray(client.subscriptions)) {
        return [{
          client_id: client?.clientId || "",
          client_name: client?.clientName || "",
          email: client?.email || "",
          phone: client?.phone || "",
          groups: client?.groups || [],
          location: client?.location || "",
          telegram: client?.telegram || "",
          pan: client?.pan || "",
          comments: client?.comments || "",
          subscription_id: "",
          start_date: "",
          plan: "",
          capital: "",
          charges: "",
          invoice: "",
          expiry: "",
          acc_creation_date: client?.creationDate ? FormatDateForCSV(client?.creationDate) : "",
          updated_at: client?.updatedAt ? FormatDateForCSV(client?.updatedAt) : "",
        }];
      }

      if (client.subscriptions.length === 0) {
        return [{
          client_id: client.clientId,
          client_name: client.clientName,
          email: client.email,
          phone: client.phone,
          groups: client.groups || [],
          location: client.location,
          telegram: client.telegram,
          pan: client.pan,
          comments: client.comments,
          subscription_id: "",
          start_date: "",
          plan: "",
          capital: "",
          charges: "",
          invoice: "",
          expiry: "",
          acc_creation_date: FormatDateForCSV(client?.creationDate),
          updated_at: FormatDateForCSV(client?.updatedAt),
        }];
      }

      return client.subscriptions.map((subscription) => ({
        client_id: client.clientId,
        client_name: client.clientName,
        email: client.email,
        phone: client.phone,
        groups: client.groups || [],
        location: client.location,
        telegram: client.telegram,
        pan: client.pan,
        comments: client.comments,
        subscription_id: subscription?.subId || "",
        start_date: FormatDateForCSV(subscription?.startDate) || "",
        plan: subscription?.plan || "",
        capital: subscription?.capital || "",
        charges: subscription?.charges || "",
        invoice: subscription?.invoice || "",
        expiry: FormatDateForCSV(subscription?.expiry) || "",
        acc_creation_date: FormatDateForCSV(client?.creationDate),
        updated_at: FormatDateForCSV(client?.updatedAt),
      }));
    });
  };

  const combinedClientDataOfAllGroups = flattenClientsData(allClientsData);

  return (
      <div className="flex flex-col h-[calc(105vh-440px)]">

        <Toaster position="top-center" reverseOrder={true}/>

        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <Users className="w-5 h-5 text-blue-600"/>
              <h2 className="text-lg font-semibold text-gray-900">
                Send Advice To Groups
              </h2>
            </div>

            <div className="flex items-center space-x-2">
              <Link
                  to="/admin/send-recommendation-status"
                  className="group relative p-2 text-gray-600 hover:text-gray-900 hover:bg-gray-50 rounded-lg transition-colors"
              >
                <Eye className="w-5 h-5"/>
                <div
                    className="absolute hidden group-hover:block w-32 px-2 py-1 -top-8 left-1/2 -translate-x-1/2 text-xs font-medium text-white bg-gray-900 rounded">
                  All Sent Advices
                  <div
                      className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"/>
                </div>
              </Link>

              <CSVLink
                  data={combinedClientDataOfAllGroups}
                  headers={csvHeaders}
                  filename={`combined_client_list_${moment().format("YYYYMMDD_HHmmss")}.csv`}
                  className="group relative p-2 text-emerald-600 hover:text-emerald-700 hover:bg-emerald-50 rounded-lg transition-colors"
              >
                <FileSpreadsheet className="w-5 h-5"/>
                <div
                    className="absolute hidden group-hover:block w-48 px-2 py-1 -top-8 left-1/2 -translate-x-1/2 text-xs font-medium text-white bg-gray-900 rounded">
                  Download Combined Client List CSV
                  <div
                      className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"/>
                </div>
              </CSVLink>
            </div>
          </div>
        </div>

        {/* Table Header */}
        <div className="grid grid-cols-12 px-6 py-3 bg-gray-50 border-b border-gray-100">
          <div className="col-span-7 text-sm font-medium text-gray-500">List Name</div>
          <div className="col-span-2 text-sm font-medium text-gray-500 text-center">Active Members</div>
          <div className="col-span-3 text-sm font-medium text-gray-500 text-center">Actions</div>
        </div>

        {/* Table Body - Fixed height with custom scrollbar */}
        <div className="flex-1 overflow-auto custom-scroll">
          <div className="divide-y divide-gray-100">
            {allGroupsData?.map((ele, i) => (
                <GroupCard
                    key={ele?._id}
                    csvHeaders={csvHeaders}
                    groupName={ele.groupName}
                    allClientDataByGroup={allClientsData.filter((item) =>
                        (item.groups || []).includes(ele.groupName)
                    )}
                    adminEmail={adminEmail}
                    advisorName={advisorName}
                    userId={userId}
                    email_password={email_password}
                    adminRationale={adminRationale}
                    advisorSpecifier={advisorSpecifier}
                />
            ))}
          </div>
        </div>
      </div>
  );
};

export default GroupListBlock;