import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  ChevronLeft,
  ChevronUp,
  ChevronDown,
  ArrowDownWideNarrow,
  PieChart,
  TrendingUp,
  TrendingDown,
  Calendar,
  Clock,
  RefreshCcw,
  DollarSign,
  PiggyBank,
  AlertTriangle,
} from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";

import Alpha100 from "../../assests/alpha-100.png";
import PigIcon from "../../assests/pig_icon.svg";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import formatCurrency from "../../utils/formatCurrency";
import RebalanceTimeLineModal from "../../AdminDashboard/StrategyDetailsPageSection/RebalanceTimeLineModal";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { TerminateStrategyModal } from "./TerminateStrategyModal";
import { ModifyInvestmentModal } from "./ModifyInvestmentModal";
import LoadingSpinner from "../../components/LoadingSpinner";

const StrategyDetailsWithPortfolioData = () => {
  const { fileName } = useParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user?.email;

  // Strategy Details State
  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const hasFetchedStrategy = useRef(false);

  // User Details State
  const [userDetails, setUserDetails] = useState(null);

  // Modal States
  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] = useState(false);
  const [terminateModal, setTerminateModal] = useState(false);
  const [modifyInvestmentModal, setModifyInvestmentModal] = useState(false);

  // Loading State
  const [portfolioLoading, setPortfolioLoading] = useState(true);

  // Subscription Data State
  const [subscriptionAmount, setSubscriptionAmount] = useState(null);
  const hasFetchedSubscriptionData = useRef(false);

  // Navigation Handler
  const handleGoBack = () => navigate(-1);

  // Check if user is subscribed
  const subscribed = strategyDetails?.subscribed_by?.includes(userEmail);

  // Fetch Strategy Details
  const getStrategyDetails = async () => {
    if (fileName && !hasFetchedStrategy.current) {
      try {
        const response = await axios.get(
          `${server.server.baseUrl}api/model-portfolio/portfolios/strategy/${fileName.replaceAll(
            /_/g,
            " "
          )}`
        );
        const portfolioData = response.data[0].originalData;
        setStrategyDetails(portfolioData);

        if (portfolioData?.model?.rebalanceHistory?.length > 0) {
          const latest = portfolioData.model.rebalanceHistory.sort(
            (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
          )[0];
          setLatestRebalance(latest);
        }

        hasFetchedStrategy.current = true;
      } catch (error) {
        console.error("Error fetching strategy details:", error);
      }
    }
  };

  // Fetch User Details
  const getUserDetails = async () => {
    if (!userEmail) return;

    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/user/getUser/${userEmail}`
      );
      setUserDetails(response.data.User);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  // Fetch Subscription Data
  const getSubscriptionData = async () => {
    if (!userEmail || !strategyDetails || hasFetchedSubscriptionData.current) return;

    try {
      const response = await axios.get(
        `${
          server.server.baseUrl
        }api/model-portfolio-db-update/subscription-raw-amount?email=${encodeURIComponent(
          userEmail
        )}&modelName=${encodeURIComponent(strategyDetails?.model_name)}`
      );
      setSubscriptionAmount(response.data.data);
      hasFetchedSubscriptionData.current = true;
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  // Get latest portfolio data
  const sortedRebalances = subscriptionAmount?.subscription_amount_raw?.sort(
    (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
  );

  const net_portfolio_updated = subscriptionAmount?.user_net_pf_model?.sort(
    (a, b) => new Date(b.execDate) - new Date(a.execDate)
  )[0];

  // WebSocket hook for real-time prices
  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    net_portfolio_updated?.order_results
  );

  // Calculate portfolio metrics
  const totalUpdatedQty = net_portfolio_updated?.order_results?.reduce(
    (total, ele) => total + (ele?.quantity || 0),
    0
  ) || 0;

  const totalInvested = net_portfolio_updated?.order_results?.reduce(
    (total, stock) => total + parseFloat(stock.averagePrice) * stock.quantity,
    0
  ) || 0;

  const totalCurrent = net_portfolio_updated?.order_results?.reduce(
    (total, stock) => total + parseFloat(getLTPForSymbol(stock?.symbol)) * stock.quantity,
    0
  ) || 0;

  const totalNetReturns = net_portfolio_updated?.order_results?.reduce(
    (total, stock) => {
      return (
        total +
        (parseFloat(getLTPForSymbol(stock?.symbol)) -
          parseFloat(stock.averagePrice)) *
          stock.quantity
      );
    },
    0
  ) || 0;

  // Table data for holdings
  const tableData = net_portfolio_updated?.order_results?.map((stock) => ({
    symbol: stock.symbol,
    currentPrice: parseFloat(getLTPForSymbol(stock?.symbol)).toFixed(2),
    avgBuyPrice: parseFloat(stock?.averagePrice).toFixed(2),
    returns: parseFloat(
      ((getLTPForSymbol(stock?.symbol) - stock?.averagePrice) /
        stock?.averagePrice) *
        100
    ).toFixed(2),
    weights: parseFloat((stock?.quantity / totalUpdatedQty) * 100).toFixed(2),
    shares: stock?.quantity,
  })) || [];

  // Effects
  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  useEffect(() => {
    getUserDetails();
  }, [userEmail]);

  useEffect(() => {
    if (strategyDetails) {
      getSubscriptionData();
    }
  }, [strategyDetails, userEmail]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPortfolioLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const closeRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      <Toaster position="top-center" reverseOrder={true} />

      {/* Header Section */}
      <div className="bg-white border-b border-gray-200 sticky top-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <button
                onClick={handleGoBack}
                className="p-2 text-gray-500 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-xl font-semibold text-gray-900">
                  {fileName?.replaceAll(/_/g, " ")}
                </h1>
                <p className="text-sm text-gray-500">
                  Invested since {moment(strategyDetails?.createdAt).format("Do MMM, YYYY")}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              {subscribed && (
                <button
                  onClick={() => setModifyInvestmentModal(true)}
                  className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Modify Investment
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Portfolio Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          {/* Total Invested */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-500">Total Invested</span>
              <DollarSign className="w-5 h-5 text-blue-500" />
            </div>
            <div className="text-2xl font-semibold text-gray-900">
              ₹{formatCurrency(parseInt(totalInvested.toFixed(2)))}
            </div>
          </div>

          {/* Total Current */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-500">Total Current</span>
              <PiggyBank className="w-5 h-5 text-green-500" />
            </div>
            <div className="text-2xl font-semibold text-gray-900">
              {totalCurrent === 0 ? "Fetching..." : `₹${formatCurrency(parseInt(totalCurrent.toFixed(2)))}`}
            </div>

          </div>

          {/* Current Returns */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-500">Current Returns</span>
              {totalNetReturns > 0 ? (
                  <TrendingUp className="w-5 h-5 text-emerald-500"/>
              ) : (
                  <TrendingDown className="w-5 h-5 text-red-500"/>
              )}
            </div>
            <div
                className={`text-2xl font-semibold ${
                    totalNetReturns > 0
                        ? "text-emerald-600"
                        : totalNetReturns < 0
                            ? "text-red-600"
                            : "text-gray-900"
                }`}
            >
              {totalNetReturns === 0
                  ? "Fetching..."
                  : `${totalNetReturns > 0 ? "+" : ""}₹${formatCurrency(
                      Math.abs(totalNetReturns.toFixed(2))
                  )}`}
            </div>
          </div>

          {/* CAGR */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-500">CAGR</span>
              <PieChart className="w-5 h-5 text-purple-500"/>
            </div>
            <div className="text-2xl font-semibold text-gray-900">
              TBC
            </div>
          </div>
        </div>

        {/* Rebalance Info */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 mb-8">
          <div className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Rebalance Information</h2>
              <button
                onClick={() => setShowRebalanceTimelineModal(true)}
                className="text-sm text-blue-600 hover:text-blue-700 font-medium"
              >
                View Rebalance History
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex items-center space-x-4">
                <Calendar className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm text-gray-500">Last Rebalance</p>
                  <p className="text-sm font-medium text-gray-900">
                    {moment(strategyDetails?.last_updated).format("MMM DD, YYYY")}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <Clock className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm text-gray-500">Next Rebalance</p>
                  <p className="text-sm font-medium text-gray-900">
                    {strategyDetails?.frequency === "Need Basis"
                      ? "Need Basis"
                      : moment(strategyDetails?.nextRebalanceDate).format("MMM DD, YYYY")}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <RefreshCcw className="w-5 h-5 text-gray-400" />
                <div>
                  <p className="text-sm text-gray-500">Rebalance Frequency</p>
                  <p className="text-sm font-medium text-gray-900">
                    {strategyDetails?.frequency}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Holdings Table */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900">Portfolio Holdings</h2>
          </div>

          {net_portfolio_updated ? (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Stock
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Current Price
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Avg. Buy Price
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Returns
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Weight
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Shares
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {net_portfolio_updated.order_results.map((stock, index) => {
                    const value = getLTPForSymbol(stock?.symbol) - stock?.averagePrice;
                    const pnlPercent = (value / stock?.averagePrice) * 100;

                    return (
                      <tr key={index} className="hover:bg-gray-50 transition-colors">
                        <td className="px-6 py-4 text-sm font-medium text-gray-900">
                          {stock.symbol}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 text-right">
                          ₹{parseFloat(getLTPForSymbol(stock?.symbol)).toFixed(2)}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 text-right">
                          ₹{parseFloat(stock?.averagePrice).toFixed(2)}
                        </td>
                        <td className={`px-6 py-4 text-sm font-medium text-right ${
                          pnlPercent > 0 
                            ? "text-emerald-600" 
                            : pnlPercent < 0 
                              ? "text-red-600" 
                              : "text-gray-900"
                        }`}>
                          {pnlPercent > 0 ? "+" : ""}
                          {parseFloat(pnlPercent).toFixed(2)}%
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 text-right">
                          {parseFloat((stock?.quantity / totalUpdatedQty) * 100).toFixed(2)}%
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-900 text-right">
                          {stock?.quantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-12">
              {portfolioLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <AlertTriangle className="w-12 h-12 text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">No Data Available</h3>
                  <p className="text-sm text-gray-500">
                    There are currently no holdings in this portfolio.
                  </p>
                </>
              )}
            </div>
          )}
        </div>

        {/* SIP Card */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
          <div className="lg:col-span-2">
            {/* Additional content can go here */}
          </div>

          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <div className="flex flex-col items-center text-center">
                <img src={PigIcon} alt="SIP" className="w-24 h-24 mb-4" />
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Start a SIP
                </h3>
                <p className="text-sm text-gray-500 mb-4">
                  Unlock stability with regular investments in this portfolio to handle market volatility.
                </p>
                <button className="w-full px-4 py-2 bg-gray-900 text-white text-sm font-medium rounded-lg hover:bg-gray-800 transition-colors">
                  SIP (Coming Soon)
                </button>
              </div>
            </div>

            <button
              onClick={() => setTerminateModal(true)}
              className="w-full px-4 py-2 border border-red-200 text-red-600 text-sm font-medium rounded-lg hover:bg-red-50 transition-colors"
            >
              Terminate Portfolio
            </button>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showRebalanceTimelineModal && (
        <RebalanceTimeLineModal
          closeRebalanceTimelineModal={closeRebalanceTimelineModal}
          strategyDetails={strategyDetails}
        />
      )}

      {terminateModal && (
        <TerminateStrategyModal
          setTerminateModal={setTerminateModal}
          terminateModal={terminateModal}
          userEmail={userEmail}
          strategyDetails={strategyDetails}
          userDetails={userDetails}
          getStrategyDetails={getStrategyDetails}
          tableData={tableData}
          totalInvested={totalInvested}
          totalCurrent={totalCurrent}
        />
      )}

      {modifyInvestmentModal && (
        <ModifyInvestmentModal
          modifyInvestmentModal={modifyInvestmentModal}
          setModifyInvestmentModal={setModifyInvestmentModal}
          userEmail={userEmail}
          strategyDetails={strategyDetails}
          getStrategyDetails={getStrategyDetails}
          amount={sortedRebalances.length > 0 ? sortedRebalances[0]["amount"] : 0}
          latestRebalance={latestRebalance}
          userBroker={userDetails?.user_broker}
        />
      )}
    </div>
  );
};

export default StrategyDetailsWithPortfolioData;