import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Toaster } from "react-hot-toast";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/ui/tooltip";

import { auth } from "../../firebase";
import server from "../../utils/serverConfig";
import Holdings from "./Holdings";
import InfoHoverWrapper from "./tooltipComponet";
import OpenPositions from "./OpenPositions";
import PortfolioEmptyStateIcon from "../../assests/Empty_State_Portfolio.svg";
import OpenPositonAccountData from "./OpenPositonAccountData";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import BespokePortfolioAccountData from "./BespokePortfolioAccountData";
import ModalPFList from "../ModelPortfolioSection/ModalPFList";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

const style = {
  analyticsHead:
    " text-[12px] lg:text-sm text-[#000000]/80 leading-[22px] font-poppins",
  analyticsValue:
    "text-black text-[18px] lg:text-[26px] font-semibold font-poppins",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
  selected:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px]  font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px]  text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const HomePage = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const [userDetails, setUserDetails] = useState();
  const [advisorText, setAdvisorText] = useState("advisor");

  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (!userEmail) return;
    getUserDetails();
  }, [userEmail]);

  const [brokerStatus, setBrokerStatus] = useState(
    userDetails ? userDetails.connect_broker_status : null
  );

  useEffect(() => {
    if (userDetails && userDetails.user_broker !== undefined) {
      setBrokerStatus(userDetails && userDetails.connect_broker_status);
    }
  }, [userDetails, brokerStatus]);

  const clientCode = userDetails && userDetails.clientCode;
  const apiKey = userDetails && userDetails?.apiKey;
  const broker = userDetails && userDetails.user_broker;
  const jwtToken = userDetails && userDetails.jwtToken;
  const my2pin = userDetails && userDetails.my2Pin;
  const secretKey = userDetails && userDetails.secretKey;
  const viewToken = userDetails && userDetails?.viewToken;
  const sid = userDetails && userDetails?.sid;
  const serverId = userDetails && userDetails?.serverId;
  const mobileNumber = userDetails && userDetails?.phone_number;
  const panNumber = userDetails && userDetails?.panNumber;
  // user login
  const dateString = userDetails && userDetails.token_expire;

  // Format the moment object as desired
  const expireTokenDate = moment(dateString).format("YYYY-MM-DD HH:mm:ss");
  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const userId = userDetails && userDetails._id;

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const [allHoldingsData, setAllHoldingsData] = useState();

  const getAllHoldings = () => {
    if (broker === "IIFL Securities") {
      if (clientCode) {
        let data = JSON.stringify({
          clientCode: clientCode,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}iifl/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "ICICI Direct") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          sessionToken: jwtToken,
          secretKey: checkValidApiAnSecret(secretKey),
          exchange: "NSE",
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}icici/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Upstox") {
      if (apiKey && jwtToken && secretKey) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
          apiSecret: checkValidApiAnSecret(secretKey),
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}upstox/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Angel One") {
      if (apiKey && jwtToken) {
        let data = JSON.stringify({
          apiKey: apiKey,
          jwtToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}angelone/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Zerodha") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: zerodhaApiKey,
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}zerodha/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Kotak") {
      if (jwtToken) {
        let data = JSON.stringify({
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          sid: sid,
          serverId: serverId,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}kotak/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // console.log("res", response);
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Hdfc Securities") {
      if (jwtToken) {
        let data = JSON.stringify({
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}hdfc/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Dhan") {
      if (jwtToken) {
        let data = JSON.stringify({
          clientId: clientCode,
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}dhan/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "AliceBlue") {
      if (jwtToken) {
        let data = JSON.stringify({
          clientId: clientCode,
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}aliceblue/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (broker === "Fyers") {
      if (jwtToken && clientCode) {
        let data = JSON.stringify({
          clientId: clientCode,
          accessToken: jwtToken,
        });
        let config = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}fyers/all-holdings`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            setAllHoldingsData(response.data.totalHoldings);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    getAllHoldings();
  }, [userDetails]);

  const [funds, setFunds] = useState({});

  const getAllFunds = async () => {
    const fetchedFunds = await fetchFunds(
      broker,
      clientCode,
      apiKey,
      jwtToken,
      secretKey,
      sid,
      viewToken,
      serverId
    );
    if (fetchedFunds) {
      setFunds(fetchedFunds);
    } else {
      console.error("Failed to fetch funds.");
    }
  };

  useEffect(() => {
    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getAllFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const profitAndLoss = parseFloat(allHoldingsData?.totalprofitandloss).toFixed(
    2
  );
  const pnlPercentage = parseFloat(allHoldingsData?.totalpnlpercentage).toFixed(
    2
  );

  const [holdingsData, setHoldingsData] = useState([]);
  const [positionsData, setpositionsData] = useState([]);
  const hasFetchedHoldings = useRef(false); // Track holdings API call
  const hasFetchedPositions = useRef(false); // Track positions API call

  const getAllHoldingsData = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/portfolio/specific-user?email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setHoldingsData(
          response.data.filteredData ? response.data.filteredData : []
        );
        hasFetchedHoldings.current = true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllPositionsData = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/positions/specific-user?email=${userEmail}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setpositionsData(response.data.data ? response.data.data : []);
        hasFetchedPositions.current = true;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (!userEmail) return;
    if (!hasFetchedHoldings.current) {
      getAllHoldingsData();
    }
    if (!hasFetchedPositions.current) {
      getAllPositionsData();
    }
  }, [userEmail]);

  const last_updated_date =
    holdingsData.length !== 0 ? holdingsData?.[0]["last_updated"] : today;

  const [openTokenExpiryModel, setOpenTokenExpiryModel] = useState(true);
  const [selectedTab, setSelectedTab] = useState("holdings");

  const [stockRecoNotExecuted, setStockRecoNotExecuted] = useState([]);

  const getAllTrades = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/user/trade-reco-for-user?user_email=${userEmail}`,
    };
    axios
      .request(config)
      .then((response) => {
        const filteredTrades = response?.data?.trades.filter((trade) => {
          return trade.trade_place_status === "recommend";
        });

        setStockRecoNotExecuted(filteredTrades);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllTrades();
  }, []);

  const isFundsEmpty =
    userDetails?.user_broker === "Kotak"
      ? todayDate > expireTokenDate
      : funds?.status === false || funds?.status === 1;

  const holdingDataLength =
    holdingsData &&
    holdingsData?.filter(
      (item) => item.user_broker === userDetails?.user_broker
    );
  const positionsDataLength =
    positionsData &&
    positionsData?.filter(
      (item) => item.user_broker === userDetails?.user_broker
    );

  const [modelPortfolioStrategy, setModelPortfolioStrategy] = useState([]);
  const hasCalledModelPortfolioStrategy = useRef(false);

  const getModelPortfolioStrategyDetails = () => {
    if (userEmail && !hasCalledModelPortfolioStrategy.current) {
      axios
        .get(
          `${server.server.baseUrl}api/model-portfolio/subscribed-strategies/${userEmail}`
        )
        .then((res) => {
          setModelPortfolioStrategy(res.data);
          hasCalledModelPortfolioStrategy.current = true;
        })
        .catch((err) =>
          console.log("Error fetching model portfolio strategy:", err)
        );
    }
  };

  useEffect(() => {
    if (userEmail && !hasCalledModelPortfolioStrategy.current) {
      getModelPortfolioStrategyDetails();
    }
  }, [userEmail]);

  const modelNames = modelPortfolioStrategy.map((item) => item.model_name);
  const [modelPortfolioRepairTrades, setModelPortfolioRepairTrades] = useState(
    []
  );
  const hasCalledRebalanceRepair = useRef(false);

  const getRebalanceRepair = () => {
    let repairData = JSON.stringify({
      modelName: modelNames,
      advisor: modelPortfolioStrategy[0]["advisor"],
      userEmail: userEmail,
      userBroker: broker,
    });
    let config2 = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/get-repair`,
      headers: {
        "Content-Type": "application/json",
      },
      data: repairData,
    };
    axios
      .request(config2)
      .then((response) => {
        setModelPortfolioRepairTrades(response.data.models);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      modelPortfolioStrategy.length !== 0 &&
      broker !== undefined &&
      !hasCalledRebalanceRepair.current
    ) {
      hasCalledRebalanceRepair.current = true; // Mark as called
      getRebalanceRepair();
    }
  }, [modelPortfolioStrategy]);

  useEffect(() => {
    if (process.env.REACT_APP_ADVISOR_SPECIFIER === "RA") {
      setAdvisorText("Research Analyst");
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
          <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      ) : (
        <>
          {brokerStatus === "connected" ? (
            <div className="w-full min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
              <Toaster position="top-center" reverseOrder={true} />

              {/* Hero Section */}
              <div className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white">
                <div className="w-full max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-12">
                  <h1 className="text-3xl font-bold mb-4">Investment Portfolio Dashboard</h1>
                  <p className="text-blue-100 text-lg max-w-3xl">
                    Manage and track portfolio advice of your analyst all in one place (excluding your discretionary investment)
                  </p>
                </div>
              </div>

              {/* Main Content */}
              <div className="w-full max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-6">
                {/* Portfolio Overview Section */}
                {modelPortfolioStrategy?.length === 0 &&
                  (holdingDataLength.length !== 0 || positionsDataLength.length !== 0) && (
                    <div className="space-y-2">
                      <h2 className="text-2xl font-bold text-gray-900">
                        Portfolio Overview
                      </h2>
                      <p className="text-gray-600">
                        Get instant insight into your investments with a comprehensive portfolio overview.
                      </p>
                    </div>
                )}

                {/* Account Data */}
                <OpenPositonAccountData
                  style={style}
                  allHoldingsData={allHoldingsData}
                  profitAndLoss={profitAndLoss}
                  pnlPercentage={pnlPercentage}
                  funds={funds?.data?.availablecash}
                  last_updated_date={last_updated_date}
                />

                {/* Portfolio Content */}
                {(holdingDataLength.length !== 0 || positionsDataLength.length !== 0) && (
                  <div className="w-full bg-white rounded-xl shadow-lg overflow-hidden border border-gray-200">
                    {/* Tabs */}
                    <div className="flex border-b border-gray-200">
                      <TabButton
                        active={selectedTab === "holdings"}
                        onClick={() => setSelectedTab("holdings")}
                        count={holdingDataLength.length}
                      >
                        Holdings
                      </TabButton>
                      <TabButton
                        active={selectedTab === "positions"}
                        onClick={() => setSelectedTab("positions")}
                        count={positionsDataLength?.length}
                      >
                        Positions
                      </TabButton>
                    </div>

                    {/* Content */}
                    <div className="h-[calc(100vh-500px)] overflow-auto">
                      {selectedTab === "positions" && (
                        <OpenPositions
                          data={positionsData?.filter(
                            (item) => item.user_broker === userDetails.user_broker
                          )}
                        />
                      )}
                      {selectedTab === "holdings" && (
                        <Holdings
                          userDetails={userDetails}
                          data={holdingsData?.filter(
                            (item) => item.user_broker === userDetails.user_broker
                          )}
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* Model Portfolio Section */}
                {modelPortfolioStrategy?.length > 0 && (
                  <div className="space-y-4">
                    <h2 className="text-2xl font-bold text-gray-900">
                      Model Portfolio Overview
                    </h2>
                    <ModalPFList
                      modelPortfolioStrategy={modelPortfolioStrategy}
                      modelPortfolioRepairTrades={modelPortfolioRepairTrades}
                      userEmail={userEmail}
                    />
                  </div>
                )}

                {/* Empty State */}
                {!modelPortfolioStrategy?.length &&
                  !holdingDataLength.length &&
                  !positionsDataLength.length && (
                    <div className="flex flex-col items-center justify-center py-16 space-y-8">
                      <div className="w-32 h-32 bg-blue-50 rounded-full flex items-center justify-center">
                        <img
                          src={PortfolioEmptyStateIcon}
                          alt="Empty Portfolio"
                          className="w-16 h-16"
                        />
                      </div>
                      <div className="text-center space-y-3">
                        <h3 className="text-2xl font-bold text-gray-900">
                          No Portfolio Data
                        </h3>
                        <p className="text-gray-600 max-w-md">
                          Place Orders now to seize opportunities & book profits
                        </p>
                      </div>
                      <div className="flex flex-col sm:flex-row gap-4">
                        <Link
                          to="/subscriptions"
                          className="px-8 py-3 text-base font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
                        >
                          Connect your broker
                        </Link>
                        {stockRecoNotExecuted?.length > 0 && (
                          <Link
                            to="/stock-recommendation"
                            className="px-8 py-3 text-base font-medium text-blue-700 bg-blue-50 border-2 border-blue-600 rounded-lg hover:bg-blue-100 transition-colors"
                          >
                            View Investment Advices
                          </Link>
                        )}
                      </div>
                    </div>
                )}
              </div>
            </div>
          ) : (
            <EmptyState
              stockRecoNotExecuted={stockRecoNotExecuted}
            />
          )}
        </>
      )}

      {/* Token Expiry Modal */}
      {isFundsEmpty && openTokenExpiryModel && (
        <TokenExpireBrokarModal
          openTokenExpireModel={openTokenExpiryModel}
          setOpenTokenExpireModel={setOpenTokenExpiryModel}
          userId={userId}
          apiKey={apiKey}
          secretKey={secretKey}
          checkValidApiAnSecret={checkValidApiAnSecret}
          clientCode={clientCode}
          my2pin={my2pin}
          panNumber={panNumber}
          mobileNumber={mobileNumber}
          broker={broker}
          getUserDetails={getUserDetails}
        />
      )}
    </>
  );
};

// Tab Button Component - Updated with blue theme
const TabButton = ({ active, onClick, children, count }) => (
  <button
    onClick={onClick}
    className={`flex items-center space-x-2 px-6 py-4 text-sm font-medium flex-1 justify-center transition-all ${
      active
        ? "border-b-2 border-blue-600 text-blue-600 bg-blue-50"
        : "text-gray-500 hover:text-gray-900 hover:bg-gray-50"
    }`}
  >
    <span>{children}</span>
    {count > 0 && (
      <span className={`px-2.5 py-1 text-xs font-medium rounded-full ${
        active ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600"
      }`}>
        {count}
      </span>
    )}
  </button>
);

// Empty State Component
const EmptyState = ({ stockRecoNotExecuted }) => (
  <div className="w-full min-h-[calc(100vh-64px)] bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-6">
    <div className="w-full max-w-2xl mx-auto text-center">
      <div className="flex flex-col items-center justify-center space-y-10">
        {/* Icon Container */}
        <div className="relative">
          <div className="absolute inset-0 bg-blue-100 rounded-full blur-xl opacity-50"></div>
          <div className="relative w-40 h-40 bg-blue-50 rounded-full flex items-center justify-center">
            <img
              src={PortfolioEmptyStateIcon}
              alt="Empty Portfolio"
              className="w-20 h-20 object-contain"
            />
          </div>
        </div>

        {/* Text Content */}
        <div className="space-y-4">
          <h2 className="text-3xl font-bold text-gray-900">
            No Portfolio Data
          </h2>
          <div className="space-y-2">
            <p className="text-lg text-gray-600 max-w-md mx-auto">
              Place Orders now to seize opportunities & book profits
            </p>
            <p className="text-base text-gray-500">
              Connect your broker for ease of execution
            </p>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4 w-full max-w-md mx-auto pt-4">
          <Link
            to="/subscriptions"
            className="w-full sm:w-auto px-8 py-4 text-base font-semibold text-white bg-blue-600 rounded-xl hover:bg-blue-700 transition-all duration-200 shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30"
          >
            Connect your broker
          </Link>
          {stockRecoNotExecuted?.length > 0 && (
            <Link
              to="/stock-recommendation"
              className="w-full sm:w-auto px-8 py-4 text-base font-semibold text-blue-700 bg-blue-50 border-2 border-blue-200 rounded-xl hover:bg-blue-100 hover:border-blue-300 transition-all duration-200"
            >
              View Investment Advices
            </Link>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
