import { useEffect, useState } from "react";
import { CircleX } from "lucide-react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  createPlanGroup,
  deletePlanGroup,
  updatePlanGroup,
} from "../../services/PlanGroupService";

import "./styles/form-control.css";
import "./styles/alert.css";
import "./styles/card.css";
import "./styles/modal.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

const OrderPlanGroupComponent = ({
  planGroupPlans,
  setPlanGroupPlans,
  bespokePlans,
}) => {
  const removeIndex = (idx) => {
    const pgps = [].concat(...planGroupPlans);
    pgps.splice(idx, 1);
    setPlanGroupPlans(pgps);
  };

  return (
    <div className="flex flex-col border-[1px] border-[#000000]/10 rounded-md">
      {planGroupPlans.map((e, i) => (
        <div
          key={`pgp-${i}`}
          className="flex justify-between items-center border-b-[1px] last:border-b-0"
        >
          <Card className="flex grow">
            <Card.Body>
              <Card.Title>
                {e.name} - ₹ {e.amount} ({e.duration} days)
              </Card.Title>
            </Card.Body>
          </Card>
          {e.planGroup && (
            <Button
              className="pr-3 py-1.5 mb-2 rounded-lg text-sm font-medium text-[#EF5350]"
              onClick={() => removeIndex(i)}
            >
              <CircleX />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

const PlanGroupModal = ({ plan, bespokePlans, planGroups }) => {
  const [show, setShow] = useState(false);
  const [selectedPlanGroup, setSelectedPlanGroup] = useState(
    plan.planGroup && planGroups[plan.planGroup]
      ? [{ label: planGroups[plan.planGroup].name }]
      : []
  );
  const [planGroupPlans, setPlanGroupPlans] = useState([]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleSavedChanges = async () => {
    const planGroupName = selectedPlanGroup[0].label;
    const advisorId = plan.adminId;
    const advisorEmail = plan.advisor_email;
    const linkedPlans = [].concat(planGroupPlans.map((plan) => plan._id));

    if (linkedPlans.length > 0) {
      if (selectedPlanGroup[0]?.customOption === true) {
        await createPlanGroup(
          planGroupName,
          advisorId,
          advisorEmail,
          linkedPlans
        );
      } else {
        const planGroup = Object.values(planGroups).find(
          (pg) => pg.name === planGroupName
        );
        await updatePlanGroup(
          planGroup.groupId,
          advisorId,
          advisorEmail,
          linkedPlans
        );
      }
    } else {
      const planGroup = Object.values(planGroups).find(
        (pg) => pg.name === planGroupName
      );
      await deletePlanGroup(planGroup.groupId);
    }

    setShow(false);
    window.location.reload();
  };

  useEffect(() => {
    const pg = selectedPlanGroup[0]?.label;

    if (pg === null) {
      setPlanGroupPlans([]);
      return;
    }

    const pgp = [];
    const maybePlanGroup = Object.values(planGroups).find(
      (group) => group.name === pg
    );

    if (maybePlanGroup) {
      pgp.push(
        ...maybePlanGroup.plans.map((planId) =>
          bespokePlans.find((plan) => planId === plan._id)
        )
      );
    }

    if (!plan.planGroup) {
      pgp.push(plan);
    }

    setPlanGroupPlans(pgp);
  }, [plan, bespokePlans, planGroups, selectedPlanGroup]);

  return (
    <>
      <Button
        className="px-3 py-1.5 mb-2 rounded-lg text-white text-sm font-medium border-[1px] border-[#000000]/10"
        style={{ background: "#0070D0" }}
        onClick={handleShow}
      >
        {plan.planGroup ? "Update Plan Group" : "Group Plan"}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Link to a Plan Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            Select an existing Plan Group, or create one:
          </div>
          <InputGroup className="my-3">
            <InputGroup.Text id="basic-addon1">Plan Group</InputGroup.Text>
            <Typeahead
              allowNew
              clearButton
              id="plan-group-typeahead"
              newSelectionPrefix="New Plan Group: "
              options={Object.values(planGroups).map((group) => {
                return { label: group?.name };
              })}
              placeholder="Search existing plan groups"
              onChange={setSelectedPlanGroup}
              selected={selectedPlanGroup}
              disabled={plan?.planGroup}
            />
          </InputGroup>
          <div className="mt-7 mb-2">Plans within the Plan Group:</div>
          {plan?.planGroup && planGroupPlans?.length === 0 && selectedPlanGroup[0] ? (
            <Alert variant="danger">
              Saving changes now would delete the plan group {selectedPlanGroup[0].label}.
            </Alert>
          ) : (
            <OrderPlanGroupComponent
              planGroupPlans={planGroupPlans}
              setPlanGroupPlans={setPlanGroupPlans}
              bespokePlans={bespokePlans}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="px-3 py-1.5 mb-2 rounded-lg text-sm font-medium border-[1px] border-[#000000]/10"
            style={{ background: "#EEEEEE" }}
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="px-3 py-1.5 mb-2 rounded-lg text-sm font-medium border-[1px] border-[#000000]/10"
            style={{
              background:
                selectedPlanGroup.length === 0 ? "#6c757d" : "#EEEEEE",
              color: selectedPlanGroup.length === 0 ? "#fff" : "#000",
            }}
            onClick={handleSavedChanges}
            disabled={selectedPlanGroup.length === 0}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlanGroupModal;
