import { useState, useEffect, useCallback, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 5000; // 5 seconds

const useWebSocketCurrentPrice = (symbols) => {
  const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_WEBSOCKET_URL;
  const [ltp, setLtp] = useState([]);
  const socketRef = useRef(null);
  const subscribedSymbolsRef = useRef(new Set());
  const failedSubscriptionsRef = useRef({});

  useEffect(() => {
    socketRef.current = io("wss://ccxt.alphaquark-case.com", {
      transports: ["websocket"],
      query: { EIO: "4" },
    });

    socketRef.current.on("market_data", (data) => {
      setLtp((prev) => {
        const index = prev.findIndex(
          (item) => item.tradingSymbol === data.stockSymbol
        );
        if (index !== -1) {
          const newLtp = [...prev];
          newLtp[index] = {
            ...newLtp[index],
            lastPrice: data.last_traded_price,
          };
          return newLtp;
        } else {
          return [
            ...prev,
            {
              tradingSymbol: data.stockSymbol,
              lastPrice: data.last_traded_price,
            },
          ];
        }
      });
    });

    const pingInterval = setInterval(() => {
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit("ping");
      }
    }, 20000);

    return () => {
      if (socketRef.current) socketRef.current.disconnect();
      clearInterval(pingInterval);
    };
  }, []);

  const subscribeToSymbol = useCallback(async (symbol) => {
    if (subscribedSymbolsRef.current.has(symbol.tradingSymbol)) return;

    try {
      await axios.post(`${ccxtUrl}/websocket/subscribe`, {
        symbol: symbol.tradingSymbol,
        exchange: symbol.exchange,
      });
      subscribedSymbolsRef.current.add(symbol.tradingSymbol);
      delete failedSubscriptionsRef.current[symbol.tradingSymbol];
    } catch (error) {
      console.error(`Failed to subscribe to ${symbol.tradingSymbol}:`, error);
      failedSubscriptionsRef.current[symbol.tradingSymbol] =
        (failedSubscriptionsRef.current[symbol.tradingSymbol] || 0) + 1;

      if (
        failedSubscriptionsRef.current[symbol.tradingSymbol] <
        MAX_RETRY_ATTEMPTS
      ) {
        setTimeout(() => subscribeToSymbol(symbol), RETRY_DELAY);
      } else {
        console.warn(`Max retry attempts reached for ${symbol.tradingSymbol}`);
      }
    }
  }, []);

  const subscribeToNewSymbols = useCallback(() => {
    if (!symbols || symbols.length === 0) return;

    const newSymbols = symbols
      ?.map((item) => {
        const symbol = item.symbol || item.Symbol;
        const isNSE = symbol?.includes("-EQ") || symbol?.includes("-BE");
        return {
          exchange: isNSE ? "NSE" : "BSE",
          tradingSymbol: symbol,
        };
      })
      .filter(
        (symbol) =>
          !subscribedSymbolsRef.current.has(symbol.tradingSymbol) &&
          (!failedSubscriptionsRef.current[symbol.tradingSymbol] ||
            failedSubscriptionsRef.current[symbol.tradingSymbol] <
              MAX_RETRY_ATTEMPTS)
      );

    newSymbols.forEach(subscribeToSymbol);
  }, [symbols, subscribeToSymbol]);

  useEffect(() => {
    subscribeToNewSymbols();
  }, [symbols, subscribeToNewSymbols]);

  const getLTPForSymbol = useCallback(
    (symbol) => {
      const ltpOne = ltp.find(
        (item) => item.tradingSymbol === symbol
      )?.lastPrice;
      return ltpOne ? ltpOne.toFixed(2) : "-";
    },
    [ltp]
  );

  return {
    ltp,
    getLTPForSymbol,
  };
};

export default useWebSocketCurrentPrice;
