import React, { useState, useEffect } from "react";
import { X, Send } from "lucide-react";
import server from "../../../../utils/serverConfig";
import axios from "axios";
import toast from "react-hot-toast";
import ClosePositionPopup from "./ClosePositionPopUp";
import ClosureAdvicePopup from "./ClosureAdvicePopup";

import TableHeader from "../../../TableComponent/TableHeader";
import TableRow from "../../../TableComponent/TableRow";
import Loader from "../../../../components/LoadingSpinner";

const StickyFooter = ({
  selectedAdvices = new Set(),
  onSendAdvices,
  handleSendClosureAdvice,
}) => {
  return (
    <div className="px-6 py-4 w-full border-t bg-gray-50 sticky bottom-0 z-10 flex items-center">
      <div className="flex items-center space-x-4">
        <button
          onClick={handleSendClosureAdvice}
          className={`px-4 py-2 text-white rounded-md transition-colors flex items-center ${
            selectedAdvices.size === 0
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-600 hover:bg-blue-700"
          }`}
          disabled={selectedAdvices.size === 0}
        >
          <Send className="w-4 h-4 mr-2" />
          Send closure advice
        </button>
        <span className="text-md text-gray-600 font-medium">
          {selectedAdvices.size} advice{selectedAdvices.size !== 1 ? "s" : ""}{" "}
          selected
        </span>
      </div>
    </div>
  );
};

const EquityAdvice = ({ onClose }) => {
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const advisorSpecifier = process.env.REACT_APP_ADVISOR_SPECIFIER;

  const [combinedAdviceData, setCombinedAdviceData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState(new Set());
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [adviceTypes, setAdviceTypes] = useState({});
  const [emailPopupOpen, setEmailPopupOpen] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [orderTypes, setOrderTypes] = useState({});
  const [rationales, setRationales] = useState({});
  const [comments, setComments] = useState({});
  const [comments2, setComments2] = useState({});
  const [mergedData, setMergedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [closePositionPopup, setClosePositionPopup] = useState({
    isOpen: false,
    type: null,
    advice: null,
  });
  const [isClosureAdvicePopupOpen, setIsClosureAdvicePopupOpen] =
    useState(false);
  const handleSendClosureAdvice = () => {
    setIsClosureAdvicePopupOpen(true);
  };

  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchAdviceData = async () => {
    try {
      setIsLoading(true); // Start loading
      const response = await fetch(
        `${server.ccxtServer.baseUrl}comms/reco/payload/advisor-name/${process.env.REACT_APP_ADVISOR_SPECIFIC_TAG}`
      );
      const data = await response.json();
      const advices = data.reco || [];
      const allEmails = data?.recoEmails || [];
      // Group advices by advice_reco_id
      const groupedAdvices = advices.reduce((acc, advice) => {
        if (!acc[advice.advice_reco_id]) {
          acc[advice.advice_reco_id] = [];
        }
        acc[advice.advice_reco_id].push(advice);
        return acc;
      }, {});

      const convertedData = Object.keys(groupedAdvices).map((id) => ({
        id,
        advices: groupedAdvices[id],
      }));
      setCombinedAdviceData(convertedData);

      setEmailData(allEmails);
    } catch (error) {
      console.error("Error fetching advice data:", error);
      setErrorMessage("Failed to fetch advice data. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  useEffect(() => {
    fetchAdviceData();
  }, []);

  const mergeEmailData = (emailData) => {
    const mergedData = {};

    Object.keys(emailData).forEach((adviceId) => {
      if (!mergedData[adviceId]) {
        mergedData[adviceId] = [];
      }
      mergedData[adviceId] = [...mergedData[adviceId], ...emailData[adviceId]];
    });

    return mergedData;
  };
  const mergedEmails = mergeEmailData(emailData);

  // Function to get emails by adviceId
  const getEmailsByAdviceId = (adviceId) => {
    return mergedEmails[adviceId] || []; // Return emails for the given ID, or an empty array if not found
  };

  // Example Usage
  console.log(getEmailsByAdviceId("0ab5a7cc-6b8e-47af-b4ad-4f7fabd573b4"));

  const handleCheckboxChange = (id, adviceIndex) => {
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const key = `${id}-${adviceIndex}`;

      // Find the related advice data
      const item = combinedAdviceData.find((item) => item.id === id);
      if (!item) return prevSelected;

      const advice = item.advices[adviceIndex];
      if (!advice || advice.closurestatus === "fullClose") {
        return prevSelected; // Skip selection
      }

      if (newSelected.has(key)) {
        newSelected.delete(key);
        // Remove merged data if the checkbox is unchecked
        setMergedData((prevMergedData) => {
          const newMergedData = { ...prevMergedData };
          delete newMergedData[key];
          return newMergedData;
        });
      } else {
        newSelected.add(key);

        const emailData = item?.emails?.find(
          (email) => email?.advice_reco_id === id
        );

        // Merge the advice data with the email data
        const mergedAdviceData = {
          ...advice,
          ...emailData,
          symbol: advice.symbol,
          type: adviceTypes[key] || advice.type,
          price: advice.price_when_send_advice,
          exchange: advice.exchange,
          date: advice.date,
          quantity: quantities[key] || advice.quantity,
          order_type: orderTypes[key] || advice.order_type || advice.orderType,
          product_type: advice.product_type || advice.productType,
          rationale: rationales[key] || advice.rationale,
          comments: comments[key] || advice.comments,
          comment2: comments2[key] || advice.comment2,
        };

        // Remove old `orderType` and `productType` keys
        delete mergedAdviceData.orderType;
        delete mergedAdviceData.productType;

        // Store the merged data
        setMergedData((prevMergedData) => ({
          ...prevMergedData,
          [key]: mergedAdviceData,
        }));
      }

      setIsAllSelected(
        newSelected.size ===
          combinedAdviceData.reduce((sum, item) => sum + item.advices.length, 0)
      );

      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setSelectedAdvices(new Set());
    } else {
      const allKeys = new Set(
        combinedAdviceData.flatMap(
          (item) =>
            item.advices
              .map((advice, index) =>
                advice?.closurestatus !== "fullClose"
                  ? `${item?.id}-${index}`
                  : null
              )
              .filter(Boolean) // Remove null values (i.e., fullClose advices)
        )
      );
      setSelectedAdvices(allKeys);
    }
    setIsAllSelected(!isAllSelected);
  };

  const handleClosePosition = (id, adviceIndex, type) => {
    const key = `${id}-${adviceIndex}`;
    setSelectedAdvices((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (!newSelected.has(key)) {
        newSelected.add(key);
      }
      return newSelected;
    });

    // Find the advice data
    const item = combinedAdviceData.find((item) => item.id === id);
    const advice = item.advices[adviceIndex];

    // Merge the advice data
    const mergedAdviceData = {
      ...advice,
      symbol: advice.symbol,
      type: adviceTypes[key] || advice.type,
      price: advice.price_when_send_advice,
      exchange: advice.exchange,
      date: advice.date,
      quantity: quantities[key] || advice.quantity,
      order_type: orderTypes[key] || advice.order_type || advice.orderType,
      product_type: advice.product_type || advice.productType,
      rationale: rationales[key] || advice.rationale,
      comments: comments[key] || advice.comments,
      comment2: comments2[key] || advice.comment2,
    };

    setMergedData((prevMergedData) => ({
      ...prevMergedData,
      [key]: mergedAdviceData,
    }));

    setClosePositionPopup({
      isOpen: true,
      type,
      selectedAdvices: [mergedAdviceData],
    });
  };

  const handleConfirmClosePosition = async (type, selectedAdvices) => {
    try {
      const formattedSelectedAdvices = selectedAdvices.map(
        (advice, index) => `${advice?.advice_reco_id}-${index}` // Ensure id-index format
      );
      await handleSendAdvices({
        closurestatus: type === "full" ? "fullClose" : "partialClose",
        selectedAdvices: formattedSelectedAdvices, // Ensure keys match mergedData
      });

      setClosePositionPopup({ isOpen: false, type: null, selectedAdvices: [] });
    } catch (error) {
      console.error("Error closing positions:", error);
    }
  };

  const toggleType = (originalType) => {
    return originalType === "BUY" ? "SELL" : "BUY";
  };

  const handleSendAdvices = async (options = {}) => {
    setLoading(true);
    try {
      const selectedAdvicesArray = Array.from(
        options.selectedAdvices || selectedAdvices
      );
      const uniqueAdviceIds = [
        ...new Set(
          selectedAdvicesArray
            .map((key) => mergedData[key]?.advice_reco_id)
            .filter(Boolean)
        ),
      ];

      const emailResponses = await Promise.all(
        uniqueAdviceIds.map(async (id) => {
          const response = await fetch(
            `${server.ccxtServer.baseUrl}comms/reco/sent-emails/${id}`
          );
          return { id, data: await response.json() };
        })
      );

      const emailDataMap = Object.fromEntries(
        emailResponses.map(({ id, data }) => [id, data.sentReco || []])
      );

      const selectedAdvicesData = selectedAdvicesArray
        .map((key) => {
          const advice = mergedData[key];
          if (!advice) {
            console.error(`Advice not found for ID: ${key}`);
            return null;
          }

          const emails = emailDataMap[advice.advice_reco_id] || [];

          const baseAdvice = {
            email: "",
            Symbol: advice.symbol,
            Exchange: advice.exchange,
            Type: toggleType(advice.type),
            OrderType: advice.order_type,
            ProductType: advice.product_type,
            Segment: advice.segment,
            Price: advice.price,
            date: advice.date,
            Quantity: advice.quantity,
            Advised_Range_Lower: advice.Advised_Range_Lower || 0,
            Advised_Range_Higher: advice.Advised_Range_Higher || 0,
            rationale: advice.rationale,
            comments: advice.comments,
            comment2: advice.comment2,
            advisorType: advisorSpecifier || "",
            price_when_send_advice: advice.price_when_send_advice,
            group: advice.group,
            advice_id: advice.advice_reco_id,
            closurestatus: options.closurestatus || null,
          };

          return emails.length === 0
            ? [baseAdvice]
            : emails.map((email) => ({
                ...baseAdvice,
                email: email?.email,
                userName: email?.clientName || "",
                phoneNumber: email?.phone || "",
                country_code: email?.country_code || "91",
                advisor_name: advisorTag,
              }));
        })
        .flat()
        .filter(Boolean);

      if (selectedAdvicesData.length === 0) {
        setLoading(false);
        toast.error("No valid advices found to send!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        return;
      }

      const config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}comms/send-reco`,
        headers: {
          "Content-Type": "application/json",
        },
        data: selectedAdvicesData,
      };

      await axios.request(config);
      setLoading(false);
      fetchAdviceData();
      toast.success("Advice has been sent successfully", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#4CAF50",
          secondary: "#FFFAEE",
        },
      });

      setTimeout(() => {
        setIsClosureAdvicePopupOpen(false);
        setSelectedAdvices(new Set());
      }, 2000);
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Error in sending Advice!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  };

  const selectedAdvicesData = Array.from(selectedAdvices).map((key) => {
    return mergedData[key];
  });

  const handleOpenEmailPopup = (id) => {
    setEmailPopupOpen(id);
  };

  const handleCloseEmailPopup = () => {
    setEmailPopupOpen(null);
  };

  const handleAddNewEmail = (id, newEmailToAdd) => {
    if (newEmailToAdd.trim()) {
      setCombinedAdviceData((prevData) => {
        return prevData.map((item) => {
          if (item.id === id) {
            const emailExists = item.emails.some(
              (email) => email.trade_given_by === newEmailToAdd
            );
            if (!emailExists) {
              return {
                ...item,
                emails: [
                  ...item.emails,
                  {
                    trade_given_by: newEmailToAdd,
                    date: new Date().toISOString(),
                  },
                ],
              };
            }
          }
          return item;
        });
      });
    } else {
    }
  };

  const handleTypeChange = (id, index, newType) => {
    setAdviceTypes((prevTypes) => ({
      ...prevTypes,
      [`${id}-${index}`]: newType,
    }));
  };

  const handleResetType = (id, index) => {
    setAdviceTypes((prevTypes) => {
      const newTypes = { ...prevTypes };
      delete newTypes[`${id}-${index}`];
      return newTypes;
    });
  };

  const handleQuantityChange = (id, index, newQuantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [`${id}-${index}`]: newQuantity,
    }));
  };

  const handleResetQuantity = (id, index) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[`${id}-${index}`];
      return newQuantities;
    });
  };

  const handleOrderTypeChange = (id, index, newOrderType) => {
    setOrderTypes((prevOrderTypes) => ({
      ...prevOrderTypes,
      [`${id}-${index}`]: newOrderType,
    }));
  };

  const handleResetOrderType = (id, index) => {
    setOrderTypes((prevOrderTypes) => {
      const newOrderTypes = { ...prevOrderTypes };
      delete newOrderTypes[`${id}-${index}`];
      return newOrderTypes;
    });
  };

  const handleRationaleChange = (id, index, newRationale) => {
    setRationales((prevRationales) => ({
      ...prevRationales,
      [`${id}-${index}`]: newRationale,
    }));
  };

  const handleResetRationale = (id, index) => {
    setRationales((prevRationales) => {
      const newRationales = { ...prevRationales };
      delete newRationales[`${id}-${index}`];
      return newRationales;
    });
  };

  const handleCommentChange = (id, index, newComment) => {
    setComments((prevComments) => ({
      ...prevComments,
      [`${id}-${index}`]: newComment,
    }));
  };

  const handleResetComment = (id, index) => {
    setComments((prevComments) => {
      const newComments = { ...prevComments };
      delete newComments[`${id}-${index}`];
      return newComments;
    });
  };

  const handleComment2Change = (id, index, newComment2) => {
    setComments2((prevComments2) => ({
      ...prevComments2,
      [`${id}-${index}`]: newComment2,
    }));
  };

  const handleResetComment2 = (id, index) => {
    setComments2((prevComments2) => {
      const newComments2 = { ...prevComments2 };
      delete newComments2[`${id}-${index}`];
      return newComments2;
    });
  };

  return (
    <div className="w-full h-screen bg-white rounded-xl shadow-xl overflow-hidden flex flex-col font-sans">
      <div className="flex items-center justify-between px-6 py-4 border-b bg-gray-50 sticky top-0 z-10">
        <h2 className="text-xl font-bold text-gray-400 font-poppins">
          Close any of your open equity advices
        </h2>
        <button
          onClick={onClose}
          className="p-2 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full transition-colors font-poppins"
        >
          <X className="w-6 h-6" />
        </button>
      </div>

      <div className="flex-1 overflow-auto">
        {isLoading ? (
          <div className="text-center justify-center py-12">
            <Loader />
          </div>
        ) : combinedAdviceData?.length === 0 ? (
          <div className="text-center font-bold text-xl py-12">
            {" "}
            No Data Available
          </div>
        ) : (
          <table className="w-full">
            <TableHeader
              isAllSelected={isAllSelected}
              handleSelectAllChange={handleSelectAllChange}
            />
            <TableRow
              combinedAdviceData={combinedAdviceData}
              handleCheckboxChange={handleCheckboxChange}
              selectedAdvices={selectedAdvices}
              handleClosePosition={handleClosePosition}
              adviceTypes={adviceTypes}
              handleTypeChange={handleTypeChange}
              quantities={quantities}
              handleQuantityChange={handleQuantityChange}
              orderTypes={orderTypes}
              handleOrderTypeChange={handleOrderTypeChange}
              handleResetOrderType={handleResetOrderType}
              emailPopupOpen={emailPopupOpen}
              handleCloseEmailPopup={handleCloseEmailPopup}
              handleAddNewEmail={handleAddNewEmail}
              handleOpenEmailPopup={handleOpenEmailPopup}
              handleResetQuantity={handleResetQuantity}
              handleResetType={handleResetType}
              getEmailsByAdviceId={getEmailsByAdviceId}
              exchange={["NSE", "BSE"]}
            />
          </table>
        )}

        {errorMessage && (
          <div className="flex items-center justify-center h-full font-poppins text-3xl text-gray-400">
            {errorMessage}
          </div>
        )}
      </div>

      <ClosePositionPopup
        isOpen={closePositionPopup.isOpen}
        onClose={() =>
          setClosePositionPopup({
            isOpen: false,
            type: null,
            selectedAdvices: [],
          })
        }
        onConfirm={handleConfirmClosePosition}
        type={closePositionPopup.type}
        selectedAdvices={closePositionPopup.selectedAdvices}
        loading={loading}
        adviceTypes={adviceTypes}
        handleTypeChange={handleTypeChange}
        handleResetType={handleResetType}
        orderTypes={orderTypes}
        handleOrderTypeChange={handleOrderTypeChange}
        handleResetOrderType={handleResetOrderType}
        rationales={rationales}
        handleRationaleChange={handleRationaleChange}
        handleResetRationale={handleResetRationale}
        comments={comments}
        handleCommentChange={handleCommentChange}
        handleResetComment={handleResetComment}
        comments2={comments2}
        handleComment2Change={handleComment2Change}
        handleResetComment2={handleResetComment2}
      />
      <ClosureAdvicePopup
        isOpen={isClosureAdvicePopupOpen}
        onClose={() => setIsClosureAdvicePopupOpen(false)}
        selectedAdvices={selectedAdvicesData}
        onSend={handleSendAdvices}
      />
      <StickyFooter
        selectedAdvices={selectedAdvices}
        onSendAdvices={() => handleSendAdvices()}
        handleSendClosureAdvice={handleSendClosureAdvice}
      />
    </div>
  );
};

export default EquityAdvice;
