import React, { useState, useEffect, forwardRef } from "react";
import { XIcon, Calendar } from "lucide-react";
import axios from "axios";
import CryptoJS from "crypto-js";

import toast, { Toaster } from "react-hot-toast";
import formatCurrency from "../../utils/formatCurrency";

import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import IsMarketHours from "../../utils/isMarketHours";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { fetchFunds } from "../../FunctionCall/fetchFunds";

const UserStrategySubscribeModal = ({
  fileName,
  userEmail,
  clientCode,
  apiKey,
  secretKey,
  jwtToken,
  viewToken,
  sid,
  serverId,
  broker,
  strategyDetails,
  setOpenSubscribeModel,
  latestRebalance,
  setOpenSucessModal,
  setOrderPlacementResponse,
  setBrokerModel,
  setOpenTokenExpireModel,
  userDetails,
  todayDate,
  expireTokenDate,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);

  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    latestRebalance.adviceEntries
  );

  const checkValidApiAnSecret = (details) => {
    try {
      const bytesKey = CryptoJS.AES.decrypt(details, "ApiKeySecret");
      const Key = bytesKey.toString(CryptoJS.enc.Utf8); // Convert to UTF-8 string

      if (Key) {
        return Key;
      } else {
        throw new Error("Decryption failed or invalid key.");
      }
    } catch (error) {
      console.error("Error during decryption:", error.message);
      return null;
    }
  };

  const [funds, setFunds] = useState(null);

  useEffect(() => {
    const getFunds = async () => {
      const fetchedFunds = await fetchFunds(
        broker,
        clientCode,
        apiKey,
        jwtToken,
        secretKey
      );
      if (fetchedFunds) {
        setFunds(fetchedFunds);
      } else {
        console.error("Failed to fetch funds.");
      }
    };

    // Call the function when the component mounts or when relevant props change
    if (broker && (clientCode || jwtToken)) {
      getFunds();
    }
  }, [broker, clientCode, apiKey, jwtToken, secretKey]);

  const [calculatedPortfolioData, setCaluculatedPortfolioData] = useState([]);
  const [calculatedLoading, setCalculateLoading] = useState(false);

  const calculateRebalance = () => {
    const isMarketHours = IsMarketHours();
    setCalculateLoading(true);
    if (broker === undefined) {
      setBrokerModel(true);
      setCalculateLoading(false);
    } else if (
      userDetails?.user_broker === "Kotak"
        ? todayDate > expireTokenDate
        : funds?.status === false || funds?.status === 1
    ) {
      setOpenTokenExpireModel(true);
      setCalculateLoading(false);
    }
    // else if (!isMarketHours) {
    //   setCalculateLoading(false);
    //   toast.error("Orders cannot be placed outside Market hours.", {
    //     duration: 3000,
    //     style: {
    //       background: "white",
    //       color: "#1e293b",
    //       maxWidth: "500px",
    //       fontWeight: 600,
    //       fontSize: "13px",
    //       padding: "10px 20px",
    //     },
    //     iconTheme: {
    //       primary: "#e43d3d",
    //       secondary: "#FFFAEE",
    //     },
    //   });
    //   return;
    // }
    else {
      let payload = {
        userEmail: userEmail,
        userBroker: broker,
        modelName: strategyDetails?.model_name,
        advisor: strategyDetails?.advisor,
        model_id: latestRebalance.model_Id,
        userFund: funds?.data?.availablecash ? funds?.data?.availablecash : "0",
      };
      if (broker === "IIFL Securities") {
        payload = {
          ...payload,
          clientCode: clientCode,
        };
      } else if (broker === "ICICI Direct") {
        payload = {
          ...payload,
          apiKey: checkValidApiAnSecret(apiKey),
          secretKey: checkValidApiAnSecret(secretKey),
          sessionToken: jwtToken,
        };
      } else if (broker === "Upstox") {
        payload = {
          ...payload,
          clientCode: clientCode,
          apiKey: checkValidApiAnSecret(apiKey),
          apiSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
        };
      } else if (broker === "Angel One") {
        payload = {
          ...payload,
          apiKey: "EfkDdJMH",
          jwtToken: jwtToken,
        };
      } else if (broker === "kotak") {
        payload = {
          ...payload,
          consumerKey: checkValidApiAnSecret(apiKey),
          consumerSecret: checkValidApiAnSecret(secretKey),
          accessToken: jwtToken,
          viewToken: viewToken,
          sid: sid,
          serverId: serverId,
        };
      } else if (broker === "Hdfc Securities") {
        payload = {
          ...payload,
          apiKey: checkValidApiAnSecret(apiKey),
          accessToken: jwtToken,
        };
      } else if (broker === "Fyers") {
        payload = {
          ...payload,
          clientId: clientCode,
          accessToken: jwtToken,
        };
      } else if (broker === "AliceBlue") {
        payload = {
          ...payload,
          clientId: clientCode,
          apiKey: apiKey,
          accessToken: jwtToken,
        };
      }
      let config = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}rebalance/calculate`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data) {
            setCaluculatedPortfolioData(response.data);
            setCalculateLoading(false);
            setConfirmOrder(true);
          } else {
            setCaluculatedPortfolioData([]);
            setCalculateLoading(false);
            setConfirmOrder(false);
          }
        })
        .catch((error) => {
          setCalculateLoading(false);
          console.log(error);
        });
    }
  };

  const dataArray =
    calculatedPortfolioData?.length !== 0
      ? [
          ...calculatedPortfolioData?.buy.map((item) => ({
            symbol: item.symbol,
            qty: item.quantity, // Adjust according to the API response
            orderType: "BUY",
            exchange: item.exchange, // Use directly from the API response
          })),
          ...calculatedPortfolioData?.sell.map((item) => ({
            symbol: item.symbol,
            qty: item.quantity, // Adjust according to the API response
            orderType: "SELL",
            exchange: item.exchange, // Based on your condition
          })),
        ]
      : [];

  const totalInvestmentValue = dataArray
    .filter((item) => item.orderType === "BUY")
    .reduce((total, item) => {
      const currentPrice = getLTPForSymbol(item.symbol);
      const investment = item.qty * currentPrice;
      return total + investment;
    }, 0);

  const convertResponse = (dataArray) => {
    return dataArray.map((item) => {
      return {
        transactionType: item.orderType,
        exchange: item.exchange,
        segment: "EQUITY",
        productType: "DELIVERY",
        orderType: "MARKET",
        price: 0,
        tradingSymbol: item.symbol,
        quantity: item.qty,
        priority: 0,
        user_broker: broker,
      };
    });
  };

  const stockDetails = convertResponse(dataArray);

  const placeOrder = () => {
    setLoading(true);
    const getBasePayload = () => ({
      modelName: strategyDetails?.model_name,
      advisor: strategyDetails?.advisor,
      model_id: latestRebalance.model_Id,
      unique_id: calculatedPortfolioData?.uniqueId,
      user_broker: broker,
      user_email: userEmail,
      trades: stockDetails,
    });

    const getBrokerSpecificPayload = () => {
      switch (broker) {
        case "IIFL Securities":
          return { clientCode };
        case "ICICI Direct": //Question - why does it do nothing
        case "Upstox":
          return {
            apiKey: checkValidApiAnSecret(apiKey),
            secretKey: checkValidApiAnSecret(secretKey),
            [broker === "Upstox" ? "accessToken" : "sessionToken"]: jwtToken,
          };
        case "Angel One":
          return { apiKey, jwtToken };
        case "Hdfc Securities":
          return {
            apiKey: checkValidApiAnSecret(apiKey),
            accessToken: jwtToken,
          };
        case "Dhan":
          return {
            clientId: clientCode,
            accessToken: jwtToken,
          };
        case "Kotak":
          return {
            consumerKey: checkValidApiAnSecret(apiKey),
            consumerSecret: checkValidApiAnSecret(secretKey),
            accessToken: jwtToken,
            viewToken: viewToken,
            sid: sid,
            serverId: serverId,
          };
        case "AliceBlue":
          return {
            clientId: clientCode,
            accessToken: jwtToken,
            apiKey: checkValidApiAnSecret(apiKey),
          };
        case "Fyers":
          return {
            clientId: clientCode,
            accessToken: jwtToken,
          };
        default:
          return {};
      }
    };

    const payload = {
      ...getBasePayload(),
      ...getBrokerSpecificPayload(),
    };

    const config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}rebalance/process-trade`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    };

    axios
      .request(config)
      .then((response) => {
        setOrderPlacementResponse(response.data.results);
        setLoading(false);
        setOpenSucessModal(true);
        setOpenSubscribeModel(false);
        const updateData = {
          modelId: latestRebalance.model_Id,
          orderResults: response.data.results,
          modelName: strategyDetails?.model_name,
          userEmail: userEmail,
        };
        return axios.post(
          `${server.server.baseUrl}api/model-portfolio-db-update`,
          updateData
        );
      })
      .then(() => {})
      .catch((error) => {
        console.error("Error in placeOrder:", error);
        setLoading(false);
        // Consider adding error handling here, e.g., showing an error modal
      });
  };

  const getBasePayload = () => ({
    modelName: strategyDetails?.model_name,
    advisor: strategyDetails?.advisor,
    model_id: latestRebalance.model_Id,
    unique_id: calculatedPortfolioData?.uniqueId,
    broker: broker,
  });

  const additionalPayload = getBasePayload();

  const handleZerodhaRedirect = async () => {
    setLoading(true);
    localStorage.setItem(
      "additionalPayload",
      JSON.stringify(additionalPayload)
    );

    const apiKey = "b0g1r806oitsamoe";
    const basket = stockDetails.map((stock) => {
      let baseOrder = {
        variety: "regular",
        tradingsymbol: stock.tradingSymbol,
        exchange: stock.exchange,
        transaction_type: stock.transactionType,
        order_type: stock.orderType,
        quantity: stock.quantity,
        readonly: false,
      };

      // Get the LTP for the current stock
      const ltp = getLTPForSymbol(stock.tradingSymbol);

      // If LTP is available and not '-', use it as the price
      if (ltp !== "-") {
        baseOrder.price = parseFloat(ltp);
      }

      // If it's a LIMIT order, use the LTP as the price
      if (stock.orderType === "LIMIT" || stock.orderType === "STOP") {
        // For LIMIT orders, always use the limit price specified
        baseOrder.price = parseFloat(stock.price || 0);
      } else if (stock.orderType === "MARKET") {
        // For MARKET orders, get LTP
        const ltp = getLTPForSymbol(stock.tradingSymbol);
        if (ltp !== "-") {
          baseOrder.price = parseFloat(ltp);
          baseOrder.variety = "regular";
        } else {
          baseOrder.variety = "regular";
          baseOrder.price = stock.limitPrice || 0; // Use limitPrice if available, or set to 0
        }
      }

      if (stock.quantity > 100) {
        baseOrder.readonly = true;
      }

      return baseOrder;
    });

    const form = document.createElement("form");
    form.method = "POST";

    form.action = `https://kite.zerodha.com/connect/basket`;

    // form.target = "_blank";

    const apiKeyInput = document.createElement("input");
    apiKeyInput.type = "hidden";
    apiKeyInput.name = "api_key";
    apiKeyInput.value = apiKey;

    const dataInput = document.createElement("input");
    dataInput.type = "hidden";
    dataInput.name = "data";
    dataInput.value = JSON.stringify(basket);

    const redirectParams = document.createElement("input");
    redirectParams.type = "hidden";
    redirectParams.name = "redirect_params";
    redirectParams.value = "test,rebalance=true";

    form.appendChild(apiKeyInput);
    form.appendChild(dataInput);
    form.appendChild(redirectParams);

    document.body.appendChild(form);

    const currentISTDateTime = new Date();
    try {
      // Update the database with the current IST date-time
      await axios
        .post(
          `${server.server.baseUrl}api/zerodha/model-portfolio/update-reco-with-zerodha-model-pf`,
          {
            stockDetails: stockDetails,
            leaving_datetime: currentISTDateTime,
            email: userEmail,
            trade_given_by: "demoadvisor@alphaquark.in",
          }
        )
        .then((res) => {
          const allStockDetails = res?.data?.data;
          const filteredStockDetails = allStockDetails.map((detail) => ({
            user_email: detail.user_email,
            trade_given_by: detail.trade_given_by,
            tradingSymbol: detail.Symbol,
            transactionType: detail.Type,
            exchange: detail.Exchange,
            segment: detail.Segment,
            productType: detail.ProductType,
            orderType: detail.OrderType,
            price: detail.Price,
            quantity: detail.Quantity,
            priority: detail.Priority,
            tradeId: detail.tradeId,
            user_broker: "Zerodha", // Manually adding this field
          }));

          setLoading(false);
          localStorage.setItem(
            "stockDetailsZerodhaOrder",
            JSON.stringify(filteredStockDetails)
          );
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
        });
      // Submit the form after the database is updated
      form.submit();
      setLoading(false);
    } catch (error) {
      console.error("Failed to update trade recommendation:", error);
      setLoading(false);
    }
  };

  const [selectedYear, setSelectedYear] = useState(null);
  const [sipType, setSipType] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const yearOptions = ["1Y", "3Y", "5Y", "10Y"];

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative w-full">
      <input
        value={value}
        onClick={onClick}
        readOnly
        ref={ref}
        placeholder="dd/mm/yy"
        className="w-full h-12 rounded-md border border-gray-300 px-3 py-2 pr-10 font-poppins text-sm font-light leading-6 placeholder-gray-400"
      />
      <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
    </div>
  ));

  // console.log("latestRebalance", latestRebalance);
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 px-2 overflow-y-auto">
      <div className="relative bg-white w-full max-w-[622px] rounded-lg shadow-lg  my-8">
        <div
          className="absolute right-2 top-6  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
          onClick={() => setOpenSubscribeModel(false)}
        >
          <XIcon className="w-6 h-6 mr-2  " />
        </div>

        <div className="flex flex-col w-full h-[470px] lg:w-[622px] lg:h-full   bg-white  border-[#000000]/20 rounded-md pt-6 overflow-hidden">
          <div className="capitalize flex  w-full pb-4 text-[16px] lg:text-[22px]  text-[#000000] font-sans font-bold px-4 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] ">
            {confirmOrder === true ? "Invest in" : "Review Trade Details of"}{" "}
            {fileName?.replaceAll(/_/g, " ")}
          </div>

          <div className="flex flex-col space-y-3 h-[480px]  overflow-y-scroll pb-4 ">
            <div>
              {confirmOrder === true ? (
                <>
                  {dataArray?.length !== 0 ? (
                    <table className={`w-full px-2 lg:px-8`}>
                      <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                        <tr className="border-b-[1px]   border-[#000000]/10">
                          <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                            Constituents
                          </th>

                          <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                            Current Price (₹)
                          </th>

                          <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                            Quantity
                          </th>

                          <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                            Order Type
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataArray?.map((ele, i) => {
                          return (
                            <tr
                              key={i}
                              className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                            >
                              <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                                {ele.symbol}
                              </td>

                              <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                                ₹ {getLTPForSymbol(ele.symbol)}
                              </td>

                              <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                                {ele.qty}
                              </td>

                              <td
                                className={
                                  ele?.orderType?.toLowerCase() === "buy"
                                    ? "text-[#16A085] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                    : ele?.orderType?.toLowerCase() === "sell"
                                    ? "text-[#E43D3D] text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                    : "text-[#000000]/80 text-[15px] font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 capitalize"
                                }
                              >
                                {ele.orderType?.toLowerCase()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="pt-12 space-y-4">
                      <div className="w-full flex flex-row justify-center text-xl font-semibold text-center items-center">
                        Something Went Wrong
                      </div>
                      <div className="w-full flex flex-row justify-center text-base text-[#000000]/50 font-semibold text-center items-center">
                        We ran into an issue with your broker. Please try again
                        later in sometime.
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <table className={`w-full px-2 lg:px-8`}>
                  <thead className="bg-[#f5f5f5]  sticky top-0 z-20 ">
                    <tr className="border-b-[1px] border-t-[1px]  border-[#000000]/10">
                      <th className="text-[12px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Constituents
                      </th>

                      <th className="text-[12px] min-w-[120px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Current Price (₹)
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Weights(%)
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[14px] text-[#000000]/60 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {latestRebalance?.adviceEntries.map((ele, i) => {
                      return (
                        <tr
                          key={i}
                          className={`border-b-[1px]   border-[#000000]/10 last-of-type:border-none`}
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 ">
                            {ele.symbol}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-normal text-center py-3 px-5 ">
                            ₹ {getLTPForSymbol(ele.symbol)}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-2 lg:px-5 ">
                            {parseFloat(ele.value * 100).toFixed(2)}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5 ">
                            Buy
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4 w-full  shadow-[0px_-4px_4px_0px_#0000000f] py-4 px-2 lg:px-8 ">
          {confirmOrder === true ? (
            <div className="flex flex-row justify-between">
              <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0 space-y-2 sm:space-y-0 sm:space-x-4">
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-500">
                    Required Fund
                  </span>

                  <span className="text-lg font-semibold text-gray-900">
                    ₹ {parseFloat(totalInvestmentValue).toFixed(2)}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-500">
                    Available Fund
                  </span>
                  <span
                    id="cash-balance-value"
                    className={`
                        ${
                          funds?.data?.availablecash > 0
                            ? " text-[#16A085] "
                            : funds?.data?.availablecash < 0
                            ? " text-[#EA2D3F]"
                            : "text-black"
                        }
                   text-lg font-semibold text-gray-900 font font-poppins`}
                  >
                    {funds?.data?.availablecash &&
                    !isNaN(funds?.data?.availablecash) ? (
                      <>
                        ₹
                        {formatCurrency(
                          parseFloat(funds?.data?.availablecash).toFixed(2)
                        )}
                      </>
                    ) : broker === "Kotak" ? (
                      <span className="text-[13px] font-semibold text-gray-900 font font-poppins">
                        NA
                      </span>
                    ) : (
                      <span className="text-[13px] font-semibold text-gray-900 font font-poppins">
                        NA
                      </span>
                    )}
                  </span>
                </div>
              </div>
              {broker === "Zerodha" ? (
                <div className="flex justify-end space-x-5 ">
                  <button
                    className=" disabled:bg-[#000000]/50 bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                    onClick={handleZerodhaRedirect}
                  >
                    {loading === true ? (
                      <LoadingSpinner />
                    ) : (
                      <span className="text-[18px] font-medium text-[#ffffff] font-poppins ">
                        Place Order
                      </span>
                    )}
                  </button>
                </div>
              ) : (
                <div className="flex justify-end space-x-5 ">
                  {userDetails?.noBrokerRequired === true ? (
                    <></>
                  ) : (
                    <button
                      className=" disabled:bg-[#000000]/50 ml-auto bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                      onClick={placeOrder}
                    >
                      {loading === true ? (
                        <LoadingSpinner />
                      ) : (
                        <span className="text-[18px] font-medium text-[#ffffff] font-poppins ">
                          Place Order
                        </span>
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col sm:flex-row items-start sm:items-center mb-4 sm:mb-0 space-y-2 sm:space-y-0 sm:space-x-4">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-500">
                  Required Fund
                </span>
                <span className="text-lg font-semibold text-gray-900">
                  {" "}
                  {parseFloat(latestRebalance?.totalInvestmentvalue).toFixed(2)}
                </span>
              </div>

              <div className="flex flex-col">
                <span className="text-sm font-medium text-gray-500">
                  Available Fund
                </span>
                <span
                  id="cash-balance-value"
                  className={`
                                                ${
                                                  funds?.data?.availablecash > 0
                                                    ? " text-[#16A085] "
                                                    : funds?.data
                                                        ?.availablecash < 0
                                                    ? " text-[#EA2D3F]"
                                                    : "text-black"
                                                }
                    text-lg font-semibold text-gray-900 font font-poppins`}
                >
                  {funds?.data?.availablecash &&
                  !isNaN(funds?.data?.availablecash) ? (
                    <>
                      ₹
                      {formatCurrency(
                        parseFloat(funds?.data?.availablecash).toFixed(2)
                      )}
                    </>
                  ) : broker === "Kotak" ? (
                    <span className="text-[13px] font-semibold text-gray-900 font font-poppins">
                      NA
                    </span>
                  ) : (
                    <span className="text-[13px] font-semibold text-gray-900 font font-poppins">
                      NA
                    </span>
                  )}
                </span>
              </div>

              <div className="flex flex-1 justify-end">
                <button
                  className="w-42 disabled:bg-[#000000]/50  ml-auto bg-[#000000] rounded-[4px] py-2 px-3 lg:py-3 lg:px-8 text-[14px] lg:text-[16px] text-center text-[#ffffff] font-poppins font-medium disabled:cursor-not-allowed"
                  onClick={calculateRebalance}
                >
                  {calculatedLoading === true ? (
                    <LoadingSpinner />
                  ) : (
                    "Confirm Details"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserStrategySubscribeModal;
