import { TrendingUp, Clock, AlertTriangle } from "lucide-react"
import EditPlanButton from "./EditPlanButton"
import Alpha100 from "../../assests/alpha-100.png"
import server from "../../utils/serverConfig"
import formatCurrency from "../../utils/formatCurrency"
import PlanGroupModal from "./PlanGroupModal"

const PlanCard = ({ plan, bespokePlans, planGroups, onEdit, onView }) => {
  const handleEdit = (e) => {
    e.stopPropagation()
    onEdit(plan)
  }

  const handleCardClick = (e) => {
    if (e.target.closest('[data-edit-button]')) return;
    onView(plan);
  };


  const getFees = () => {
    if (plan.is_single_payment) {
      return `₹ ${formatCurrency(plan.amount)}`
    }
    if (plan.pricing) {
      const fees = Object.entries(plan.pricing)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}: ₹${formatCurrency(value)}`)
        .join(", ")
      return fees || "-"
    }
    return "-"
  }

  return (
    <div
      className="relative font-poppins w-full rounded-t-none rounded-b-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] pb-2 cursor-pointer hover:shadow-lg transition-shadow"
      onClick={handleCardClick}
    >
      <div className="flex">
        <div className="p-2 font-medium bg-[#0070D0] text-white font-poppins tracking-wide rounded-t-none rounded-b-full text-center text-xs uppercase w-full">
          {plan?.type}
        </div>
      </div>
      <div className="flex items-start gap-x-3 p-4">
        <div className="flex flex-shrink-0 overflow-hidden rounded-[4px]">
          <img
            src={plan?.image ? `${server.server.baseUrl}${plan.image}` : Alpha100}
            alt={plan?.name}
            className="object-cover w-[49.18px] h-[48px]"
          />
        </div>
        <div className="flex flex-col grow">
          <h2 className="text-lg font-semibold text-[#000000]">{plan?.name}</h2>
          <p className="mt-1 text-[10px] md:text-[13px] text-[#95989C] font-poppins text-left font-normal">
            {plan?.description?.split(" ").slice(0, 10).join(" ")}
          </p>
        </div>
      </div>

      <div className="flex w-full border-t-[1px] border-b-[1px] border-[#000000]/10">
        <div className="flex flex-col justify-center items-center w-2/4 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins capitalize">CAGR</div>
            <p className="text-[13px] lg:text-[16px] text-[#16A085] font-poppins font-bold text-center">-</p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-2/4 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins">2Y Returns</div>
            <p className="text-[13px] lg:text-[16px] text-[#16A085] font-poppins font-bold text-center">-</p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center w-2/4 py-1.5 lg:px-1 rounded-md">
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins">Min. investment</div>
            <p className="text-[13px] lg:text-[16px] text-[#000000] font-poppins font-medium">
              ₹{formatCurrency(plan.minInvestment)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full px-4 mt-4">
        <div className="flex flex-col text-base text-[#000000]/80 font-sans w-full">
          <div className="flex items-center">
            <TrendingUp size={16} className="text-[#4CAF50]" />
            <span className="text-[13px] px-2 font-medium">Fees: {getFees()}</span>
          </div>
          <div className="flex items-center">
            <Clock size={15} className="text-[#2196F3]" />
            <span className="text-[13px] px-2 font-medium">Duration: {plan?.duration || "-"} days</span>
          </div>
          <div className="flex items-center">
            <AlertTriangle size={14} className="text-[#FFC107]" />
            <span className="text-[13px] px-2 py-0 font-medium">Subject to market risk</span>
          </div>
        </div>
        <div className="flex flex-col min-w-[120px] gap-2">
         <div data-edit-button>
          <EditPlanButton  Plan={plan?.name} />
          {/* {plan?.type === "bespoke" && (
            <PlanGroupModal plan={plan} bespokePlans={bespokePlans} planGroups={planGroups} />
          )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlanCard

