"use client";

import React, { useEffect, useState, useRef, forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

const Card = forwardRef(
  (
    { ele, handlePricingCardClick, globalConsent, handleGlobalConsent },
    ref
  ) => {
    const { headerRef, statsRef, buttonRef } = ref;

    const [isConsentPopupOpen, setIsConsentPopupOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [selectedPricing, setSelectedPricing] = useState("monthly");

    const handleCAGRClick = (e) => {
      e.stopPropagation();
      if (!globalConsent) {
        setIsConsentPopupOpen(true);
      }
    };

    const handleConsentAccept = () => {
      handleGlobalConsent();
      setIsConsentPopupOpen(false);
    };

    const handleCardClick = () => {
      if (!ele?.subscription) {
        handlePricingCardClick(ele);
      }
    };

    // Calculate months from days
    const calculateMonths = (days) => {
      if (!days) return 12;
      return Math.round(days / 30);
    };

    // Get available pricing options
    const getPricingOptions = () => {
      if (ele?.amount) {
        return [
          {
            label: `${calculateMonths(ele.duration)} months`,
            value: ele.amount,
          },
        ];
      }

      const options = [];
      if (ele?.pricing?.monthly)
        options.push({
          period: "monthly",
          label: "Monthly",
          value: ele.pricing.monthly,
        });
      if (ele?.pricing?.quarterly)
        options.push({
          period: "quarterly",
          label: "Quarterly",
          value: ele.pricing.quarterly,
        });
      if (ele?.pricing?.["half-yearly"])
        options.push({
          period: "half-yearly",
          label: "6 Months",
          value: ele.pricing["half-yearly"],
        });
      if (ele?.pricing?.yearly)
        options.push({
          period: "yearly",
          label: "Yearly",
          value: ele.pricing.yearly,
        });

      return options;
    };

    const pricingOptions = getPricingOptions();
    const currentPrice =
      ele?.amount ||
      pricingOptions.find((opt) => opt.period === selectedPricing)?.value ||
      0;
    const originalPrice = currentPrice ? Math.round(currentPrice / 0.81) : 0;
    const discount =
      originalPrice && currentPrice
        ? Math.round(((originalPrice - currentPrice) / originalPrice) * 100)
        : 0;
    const descriptionLines = ele?.description
      ?.split("-")
      .map((point) => point.trim());

    return (
      <div className="w-[360px] bg-white rounded-xl shadow-lg overflow-hidden">
        {/* Header with Price */}
        <div
          ref={headerRef}
          className="p-6 cursor-pointer hover:bg-gray-50 transition-colors"
          onClick={handleCardClick}
        >
          <div className="flex items-baseline gap-2 mb-4">
            <h2 className="text-2xl font-bold text-gray-900">{ele?.name}</h2>
          </div>

          {/* Pricing Options */}
          {pricingOptions.length > 1 ? (
            <div className="mb-4">
              <div className="flex gap-2 mb-3">
                {pricingOptions.map((option) => (
                  <button
                    key={option.period}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedPricing(option.period);
                    }}
                    className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                      selectedPricing === option.period
                        ? "bg-blue-100 text-blue-600"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
              <div className="flex items-baseline gap-2">
                <span className="text-lg text-gray-500 line-through">
                  ₹{originalPrice?.toLocaleString()}
                </span>
                <span className="text-3xl font-bold">
                  ₹{currentPrice?.toLocaleString()}
                </span>
                <span className="text-sm font-medium text-green-600 bg-green-50 px-2 py-1 rounded">
                  Save {discount}%
                </span>
              </div>
            </div>
          ) : (
            <div className="flex items-baseline gap-2 mb-6">
              <span className="text-lg text-gray-500 line-through">
                ₹{originalPrice?.toLocaleString()}
              </span>
              <span className="text-3xl font-bold">
                ₹{currentPrice?.toLocaleString()}
              </span>
              <span className="text-sm font-medium text-green-600 bg-green-50 px-2 py-1 rounded">
                Save {discount}%
              </span>
            </div>
          )}

          {/* Stats Section */}
          <div
            ref={statsRef}
            className="grid grid-cols-3 gap-4 mb-6 p-4 bg-gray-50 rounded-lg"
          >
            <div>
              <div className="text-sm text-gray-500">CAGR</div>
              <p
                className={`text-sm font-semibold text-green-600 ${
                  !globalConsent ? "blur-sm" : ""
                } cursor-pointer`}
                onClick={handleCAGRClick}
              >
                New Portfolio
              </p>
            </div>
            <div>
              <div className="text-sm text-gray-500">2Y Returns</div>
              <p
                className={`text-sm font-semibold text-green-600 ${
                  !globalConsent ? "blur-sm" : ""
                } cursor-pointer`}
                onClick={handleCAGRClick}
              >
                New Portfolio
              </p>
            </div>
            <div>
              <div className="text-sm text-gray-500">Min. Investment</div>
              <p className="text-sm font-semibold">
                ₹{ele?.minInvestment?.toLocaleString()}
              </p>
            </div>
          </div>

          {/* Description List */}
          <div className="mb-6">
            <ul className="space-y-2">
              {descriptionLines
                ?.slice(0, showMore ? descriptionLines.length : 7)
                .map((point, index) => (
                  <li
                    key={index}
                    className="text-gray-600 flex items-start gap-2"
                  >
                    <span className="text-gray-400 mt-1">-</span>
                    <span>{point}</span>
                  </li>
                ))}
            </ul>
            {descriptionLines?.length > 7 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMore(!showMore);
                }}
                className="mt-2 text-blue-600 text-sm font-medium hover:underline"
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            )}
          </div>

          {/* Expandable Content */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
            className="w-full flex items-center justify-center py-2 text-sm text-gray-600 hover:bg-gray-50"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUpIcon className="ml-1 w-4 h-4" />
              </>
            ) : (
              <>
                Show More <ChevronDownIcon className="ml-1 w-4 h-4" />
              </>
            )}
          </button>

          {isExpanded && (
            <div className="mt-4 space-y-6">
              <div>
                <h3 className="text-sm font-semibold mb-2">Key Features</h3>
                <ul className="space-y-2">
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Professionally managed portfolio
                  </li>
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Regular portfolio rebalancing
                  </li>
                  <li className="text-gray-600 flex items-center gap-2">
                    <div className="w-1.5 h-1.5 bg-green-500 rounded-full" />
                    Optimal stock allocation and monitoring
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-sm font-semibold mb-2">
                  Additional Benefits
                </h3>
                <p className="text-sm text-gray-600">
                  Get alerts via email/WhatsApp and update portfolio in your
                  broker with a mouse click.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Action Button */}
        <div ref={buttonRef} className="p-6 bg-gray-50">
          <button
            onClick={() =>
              ele?.subscription ? undefined : handlePricingCardClick(ele)
            }
            className="w-full py-3 px-4 bg-blue-600 text-white text-sm font-semibold rounded-lg hover:bg-blue-700 transition-colors"
          >
            Invest Now
          </button>
        </div>

        {/* Consent Popup */}
        {isConsentPopupOpen && (
          <>
            <div className="fixed inset-0 bg-black bg-opacity-40 z-50" />
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Consent Required</h2>
                <p className="mb-6">
                  By clicking to view performance, I understand that historical
                  performance data is NOT a promise of future returns. For new
                  portfolio, data would be limited and will not show CAGR.
                </p>
                <div className="flex justify-end space-x-4">
                  <button
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                    onClick={() => setIsConsentPopupOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                    onClick={handleConsentAccept}
                  >
                    I Agree
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

const PortfolioSection = ({ allPricingPlan = [], handlePricingCardClick }) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [globalConsent, setGlobalConsent] = useState(false);
  // Comment: Changed rowRefs to cardRefs
  const cardRefs = useRef([]);

  const handleGlobalConsent = () => {
    setGlobalConsent(true);
  };

  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(allPricingPlan);
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
    }
  }, [isLoading, pricingPlans]);

  // Comment: Updated adjustCardHeights function
  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    cardRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === cardRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const sections = ["headerRef", "statsRef", "infoRef", "buttonRef"];
      sections.forEach((section) => {
        const maxHeight = Math.max(
          ...row.map((card) => card[section]?.offsetHeight || 0)
        );
        row.forEach((card) => {
          if (card[section]) {
            card[section].style.height = `${maxHeight}px`;
          }
        });
      });
    });
  };

  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }
  return (
    <div className="pt-12 pb-32" id="modelPortfolio">
      <h1 className="text-[28px] sm:text-3xl md:text-4xl font-bold text-black text-center mt-4 sm:mt-6 md:mt-8 px-4 leading-tight">
        Model Portfolios for Every Investor
      </h1>

      <div className="mx-auto px-2 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading
            ? Array(3)
                .fill(0)
                .map((_, i) => <SkeletonCard key={i} />)
            : pricingPlans.map((ele, i) => (
                // Comment: Added ref to Card component
                <Card
                  key={i}
                  ele={ele}
                  handlePricingCardClick={handlePricingCardClick}
                  globalConsent={globalConsent}
                  handleGlobalConsent={handleGlobalConsent}
                  ref={(el) => {
                    cardRefs.current[i] = el;
                  }}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
