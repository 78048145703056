import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import HeaderSection from "./HeaderSection";
import PortfolioSection from "./PortfolioSection";
import SubscriptionSection from "./SubscriptionSection";
import AboutSection from "./AboutSection";
import ProcessSection from "./ProcessSection";
import FaqSection from "./FaqSection";
import Footer from "./Footer";

import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import server from "../utils/serverConfig";
import { auth } from "../firebase";
import FormatDateTime from "../utils/formatDateTime";
import PlanSubscribeModal from "./PlanSubscribeModal";
import PaymentSuccessModal from "./PaymentSuccessModal";

const LandingPage = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [allPricingPlan, setAllPricingPlan] = useState([]);
  const [planDescription, setPlanDescription] = useState("");

  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const appUrl = process.env.REACT_APP_URL;
  const getAllPlans = () => {
    if (userEmail) {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/bespoke/${userEmail}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPricingPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let config = {
        method: "get",
        url: `${server.server.baseUrl}api/all-plans/specific-advisor/plan/bespoke/${advisorTag}`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios
        .request(config)
        .then((response) => {
          setAllPricingPlan(response.data);
          if (response.data && response.data.length > 0) {
            setPlanDescription(response.data[0].description || "");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const [paymentModal, setPaymentModal] = useState(false);
  const [specificPlan, setSpecificPlan] = useState();

  const handlePricingCardClick = (modelName) => {
    setPaymentModal(true);
    setSpecificPlan(modelName);
    setPlanDescription(modelName.description || "");
  };

  const [strategyDetails, setStrategyDetails] = useState(null);

  const getStrategyDetails = () => {
    if (specificPlan) {
      axios
        .get(
          `${server.server.baseUrl}api/admin/plan/landing/subscription/detail/specific/${specificPlan?._id}`
        )
        .then((res) => {
          setStrategyDetails(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (specificPlan) {
      getStrategyDetails();
    }
  }, [specificPlan]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [invetAmount, setInvestAmount] = useState();
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [panNumber, setPanNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const formattedName = specificPlan?.name
    ? specificPlan.name.includes(" ") // Check if there are spaces
      ? specificPlan.name.toLowerCase().replace(/\s+/g, "_") // If spaces, replace them
      : specificPlan.name.toLowerCase() // If no spaces, just lowercase
    : "";

  const handleCardClick = (item) => {
    setSelectedCard(item); // Set the selected card
    // Call your subscribe function
  };

  const handleOk = () => {
    let sip_amount;
    if (strategyDetails?.frequency?.length !== 0) {
      subscribeToPlan(selectedCard, sip_amount);
    } else {
      handleSinglePayment(strategyDetails?.amount);
    }
  };

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  async function subscribeToPlan(frequency, sip_amount) {
    try {
      setLoading(true);
      // Fetch subscription details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription`,
        {
          plan_id: specificPlan?._id,
          frequency,
          user_email: userEmail,
          sip_amount,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const subscriptionData = response.data.data;

      // console.log(subscriptionData, "subscriptionData");

      if (subscriptionData.razorpay_subscription_id) {
        // Initialize Razorpay with the subscription details
        const options = {
          key: razorPayKey, // Your Razorpay Key ID
          subscription_id: subscriptionData.razorpay_subscription_id, // The subscription ID from Razorpay
          name: subscriptionData.plan_id.name, // Plan or product name
          description: subscriptionData.razorpay_subscription_id, // Description of the plan
          amount: subscriptionData.amount, // Amount in smallest unit (paise for INR)
          currency: "INR", // Currency (e.g., INR)
          handler: function (response) {
            // This will execute after successful payment
            // console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            // console.log(
            //   "Razorpay Subscription ID:",
            //   response.razorpay_subscription_id
            // );
            // console.log("Razorpay Signature:", response.razorpay_signature);

            // Now, you can call the backend to save the subscription status and other details

            completeSubscription(response);
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "", // User's name
            email: userEmail, // User's email
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching subscription data");
        setLoading(true);
      }
    } catch (error) {
      console.error("Error subscribing to plan:", error);
      setLoading(true);
    }
  }

  // Function to handle the backend call after successful payment
  async function completeSubscription(paymentDetails) {
    try {
      // Send payment details to the backend to finalize the subscription
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/complete-payment`,
        paymentDetails
      );

      const data = response.data;

      setPaymentSuccess(true);

      // Send notifications
      try {
        await sendNotifications({
          email: userEmail || email,
          phoneNumber: mobileNumber,
          countryCode: countryCode || "+91",
          planDetails: {
            isRenewal: false,
            duration:
              specificPlan?.frequency === "monthly"
                ? "30"
                : specificPlan?.frequency === "quarterly"
                ? "90"
                : "365",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // Add this parameter
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
      }

      const newSubscription = {
        subId: uuidv4().slice(0, 10),
        startDate: FormatDateTime(new Date()),
        plan: formattedName || "", // Assuming the response contains a plan
        capital: data.subscription.capital || 0, // Assuming the response contains capital
        charges: data.subscription.amount || 0, // Assuming the response contains charges
        invoice: data.subscription.razorpay_subscription_id || "", // Assuming the response contains invoice
        expiry: FormatDateTime(new Date(data.expiry)), // Assuming the response contains expiry date
      };
      const newClientData = {
        clientId: uuidv4().slice(0, 7), // Generate a new client ID
        clientName: data.subscription.name || "", // Assuming the response contains a client name
        email: data.subscription.user_email || "", // Assuming the response contains an email
        phone: data.subscription.mobileNumber || "", // Assuming the response contains a phone number
        groups: [`All Client`, formattedName], // Add formatted name dynamically
        location: data.location || "", // Assuming the response contains a location
        telegram: data.telegram || "", // Assuming the response contains a Telegram ID
        pan: data.pan || "", // Assuming the response contains a PAN number
        creationDate: FormatDateTime(new Date()), // Current date
        comments: data.comments || "", // Assuming the response contains comments
        subscriptions: [
          {
            ...newSubscription, // Attach the new subscription here
          },
        ],
      };

      try {
        // Send a POST request to add the new client
        const response = await fetch(
          `${server.server.baseUrl}api/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: specificPlan?.adminId,
              clientData: newClientData,
            }),
          }
        );

        const result = await response.json();
        setInvestAmount();
        setEmail(null);
        if (response.ok) {
        } else {
        }
      } catch (error) {
        console.error("Error adding client:", error);
      }
    } catch (error) {
      console.error("Error completing subscription:", error);
    }
  }

  async function handleSinglePayment(amount) {
    try {
      setLoading(true);
      // Fetch one-time payment details from the backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription`,
        {
          plan_id: specificPlan?._id,
          user_email: email,
          amount,
        }
      );
      setLoading(false);
      setPaymentModal(false);
      const paymentData = response.data.data;

      if (paymentData.razorpay_order_id) {
        // Initialize Razorpay with the order details
        const options = {
          key: razorPayKey,
          order_id: paymentData.razorpay_order_id,
          name: paymentData.plan_id.name,
          description: paymentData.razorpay_order_id,
          amount: paymentData.amount,
          currency: "INR",
          handler: function (response) {
            // console.log("Razorpay Payment ID:", response.razorpay_payment_id);
            // console.log("Razorpay Order ID:", response.razorpay_order_id);
            // console.log("Razorpay Signature:", response.razorpay_signature);

            completeSinglePayment(response);
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "",
            email: email,
          },
          theme: {
            color: "#F37254",
          },
        };

        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching one-time payment data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
      setLoading(false);
    }
  }

  // First, add these notification functions at the top of the file after the imports
  // Email notification function using the correct API
  const sendEmailNotification = async (
    email,
    planDetails,
    userName,
    advisorName,
    tradingPlatform
  ) => {
    try {
      console.log("Sending email notification to:", email);

      const emailData = [
        {
          template_name: "new_plan",
          template_body_values: [
            userName || email,
            new Date().toISOString(), // subscription start date
            new Date(
              new Date().setDate(
                new Date().getDate() + (planDetails.duration || 30)
              )
            ).toISOString(), // end date
          ],
          trade_given_by: advisorName,
          recipient_email: email,
          plan_name: planDetails.name,
        },
      ];

      const emailResponse = await axios.post(
        `${server.ccxtServer.baseUrl}/comms/email/send-template-messages/supported-broker`,
        emailData
      );

      if (emailResponse.data.status !== 0) {
        console.error("Email sending failed:", emailResponse.data);
      }

      return emailResponse;
    } catch (error) {
      console.error("Email notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        email: email,
      });
      // Don't throw error to prevent disrupting the main flow
    }
  };

  const sendWhatsAppNotification = async (
    phoneNumber,
    countryCode,
    planDetails,
    userName,
    email,
    advisorName
  ) => {
    try {
      let formattedPhone: *;
      formattedPhone = phoneNumber.toString().replace(/\D/g, "");
      let formattedCountryCode = countryCode; //.toString().replace(/\D/g, '');
      formattedCountryCode = formattedCountryCode.startsWith("+")
        ? formattedCountryCode
        : `+${formattedCountryCode}`;

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      const trackUserResponse = await axios.post(
        `${server.ccxtServer.baseUrl}comms/whatsapp/track-user`,
        {
          phone_number: formattedPhone,
          country_code: formattedCountryCode,
          user_traits: {
            name: userName || email,
            email: email,
            advisor: advisorName,
            advisor_codomain: getAdvisorCodomain(advisorName),
            whatsapp_opted_in: true,
          },
          tags: ["alphaquark", "internal_team"],
        }
      );

      console.log("Track user response:", trackUserResponse.data);

      if (trackUserResponse.data.result.result === true) {
        console.log("Sending WhatsApp template message...");
        const templateResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          {
            phone_number: formattedPhone,
            country_code: formattedCountryCode,
            template_name: "new_plan",
            template_body_values: [
              userName || email,
              planDetails.isRenewal ? "renewed" : "subscribed",
              new Date().toISOString(),
              new Date().toISOString() + planDetails.duration || "30",
              advisorName,
            ],
            template_button_values: [getAdvisorCodomain(advisorName)],
            template_header_values: [
              planDetails.isRenewal ? "Renewed" : "Subscribed",
            ],
            callback_data: "Standard Callback",
            language_code: "en",
          }
        );
        console.log("Template response:", templateResponse.data);
      } else {
        console.error("Track user failed:", trackUserResponse.data);
      }
    } catch (error) {
      console.error("WhatsApp notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        phoneNumber,
        countryCode,
      });
    }
  };

  const sendNotifications = async (notificationData) => {
    const {
      email,
      phoneNumber,
      countryCode,
      planDetails,
      userName,
      advisorName,
      tradingPlatform,
    } = notificationData;

    try {
      // Send email first
      await sendEmailNotification(
        email,
        planDetails,
        userName,
        advisorName,
        tradingPlatform
      );

      // Then send WhatsApp notification
      await sendWhatsAppNotification(
        phoneNumber,
        countryCode,
        planDetails,
        userName,
        email,
        advisorName
      );
    } catch (error) {
      console.error("Notification error:", error);
    }
  };

  async function completeSinglePayment(paymentDetails) {
    try {
      // Send payment details to the backend to finalize the one-time payment
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription/complete-one-time-payment`,
        paymentDetails
      );

      const data = response.data;
      setPaymentSuccess(true);

      // Send notifications
      try {
        let countryCode;
        await sendNotifications({
          email: email,
          phoneNumber: mobileNumber,
          countryCode: countryCode || "+91",
          planDetails: {
            isRenewal: false,
            duration: specificPlan?.duration || "30",
            name: specificPlan?.name,
            amount: specificPlan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // or 'unsupported-broker' based on your needs
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
      }

      const newSubscription = {
        subId: uuidv4().slice(0, 10),
        startDate: FormatDateTime(new Date()),
        plan: formattedName || "", // Assuming the response contains a plan
        capital: invetAmount || 0, // Assuming the response contains capital
        charges: specificPlan?.amount || 0, // Assuming the response contains charges
        invoice: data.subscription.razorpay_order_id || "", // Assuming the response contains invoice
        expiry: FormatDateTime(new Date(data.subscription.end_date)), // Assuming the response contains expiry date
      };
      const newClientData = {
        clientId: uuidv4().slice(0, 7), // Generate a new client ID
        clientName: name || "", // Assuming the response contains a client name
        email: email || "", // Assuming the response contains an email
        phone: mobileNumber || "", // Assuming the response contains a phone number
        groups: [`All Client`, formattedName], // Add formatted name dynamically
        location: data.location || "", // Assuming the response contains a location
        telegram: data.telegram || "", // Assuming the response contains a Telegram ID
        pan: panNumber || "", // Assuming the response contains a PAN number
        creationDate: FormatDateTime(new Date()), // Current date
        comments: data.comments || "", // Assuming the response contains comments
        subscriptions: [
          {
            ...newSubscription, // Attach the new subscription here
          },
        ],
      };

      // Add new client to groups
      try {
        const response = await fetch(
          `${server.server.baseUrl}api/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: specificPlan?.adminId,
              clientData: newClientData,
            }),
          }
        );

        const result = await response.json();
      } catch (error) {
        console.error("Error adding client:", error);
      }
      const emailData = [
        {
          template_name: "new_plan",
          template_body_values: [
            name,
            `${data.subscription.start_date}`,
            `${data.subscription.end_date}`,
          ],
          trade_given_by: whiteLabelText,
          recipient_email: email,
          plan_name: specificPlan?.name,
        },
      ];

      const emailConfig = {
        method: "post",
        url: `${server.ccxtServer.baseUrl}comms/email/send-template-messages/supported-broker`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(emailData),
      };

      axios
        .request(emailConfig)
        .then((response) => {
          setName(null);
          setEmail(null);
          setPanNumber(null);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error completing one-time payment:", error);
    }
  }
  const [countryCode, setCountryCode] = useState("+91");

  return (
    <div>
      <Navbar />
      <main className="w-full max-w-full overflow-x-hidden mt-12 md:mt-16 ">
        <HeaderSection id="headerSection" />
        {/* <PortfolioSection id="modelPortfolio" />
         <AboutSection id="aboutSection" />
        */}

        <SubscriptionSection
          id="subscriptionSection"
          allPricingPlan={allPricingPlan}
          handlePricingCardClick={handlePricingCardClick}
          planDescription={planDescription}
        />

        <ProcessSection id="processSection" />
        <FaqSection id="faqSection" />
      </main>
      <Footer />
      {paymentModal === true ? (
        <PlanSubscribeModal
          userEmail={email}
          strategyDetails={strategyDetails}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleCardClick={handleCardClick}
          selectedCard={selectedCard}
          handleOk={handleOk}
          loading={loading}
          invetAmount={invetAmount}
          setInvestAmount={setInvestAmount}
          setEmail={setEmail}
          name={name}
          setName={setName}
          panNumber={panNumber}
          setPanNumber={setPanNumber}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          setCountryCode={setCountryCode}
          countryCode={countryCode}
        />
      ) : null}
      {paymentSuccess === true ? (
        <PaymentSuccessModal
          specificPlanDetails={specificPlan}
          setPaymentSuccess={setPaymentSuccess}
          setSelectedCard={setSelectedCard}
        />
      ) : null}
    </div>
  );
};

export default LandingPage;
