import React from "react";
import { Link } from "react-router-dom";
import { AlertCircle } from "lucide-react";
import HoldingCard from "./HoldingCard";

const Holdings = ({ data, check }) => {
  return (
    <section
      className={
        check === true ? "h-full lg:h-[calc(100vh-210px)] lg:mt-0" : "lg:mt-0"
      }
    >
      {data && data.length !== 0 ? (
        <div
          className={
            check === true
              ? "bg-white hidden lg:block w-full h-[calc(100vh-380px)] pb-4 lg:pb-2 lg:h-[calc(100vh-330px)] overflow-auto custom-scroll"
              : "bg-white hidden lg:block w-full pb-4 lg:pb-2 overflow-auto custom-scroll"
          }
        >
          <table className="w-full">
            <thead className="bg-gray-50 text-sm text-left sticky top-0 z-10">
              <tr className="border-b border-gray-200">
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Stock
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Qty
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  LTP
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Avg. Cost
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Invested
                </th>
                <th className="whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  Unrealized P&L
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data?.map((ele, i) => {
                const pnl =
                  (ele?.bespoke_holding?.ltp - ele?.bespoke_holding?.avgPrice) *
                  ele?.bespoke_holding?.quantity;
                const value =
                  ele?.bespoke_holding?.ltp * ele?.bespoke_holding?.quantity;
                return (
                  <tr
                    key={i}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">
                      {ele?.bespoke_holding?.symbol || "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      {ele?.bespoke_holding?.quantity || "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{ele?.bespoke_holding?.ltp
                        ? parseFloat(ele?.bespoke_holding?.ltp).toFixed(2)
                        : "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{ele?.bespoke_holding?.avgPrice
                        ? parseFloat(ele?.bespoke_holding?.avgPrice).toFixed(2)
                        : "-"}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-600 text-center">
                      ₹{value ? parseFloat(value).toFixed(2) : "-"}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-center">
                      {pnl > 0 ? (
                        <span className="text-emerald-600">
                          +₹{pnl ? pnl.toFixed(2) : "-"}
                        </span>
                      ) : pnl < 0 ? (
                        <span className="text-red-600">
                          -₹{Math.abs(pnl).toFixed(2)}
                        </span>
                      ) : (
                        <span className="text-gray-600">0</span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="hidden lg:flex flex-col items-center justify-center h-[calc(100vh-360px)]">
          <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            No Holdings Yet
          </h3>
          <p className="text-gray-500 text-center mb-6">
            Start placing orders to view your holdings here.
          </p>
          <Link
            to="/stock-recommendation"
            className="inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
          >
            View Trade Advices
          </Link>
        </div>
      )}

      {/* Mobile View */}
      <div className="lg:hidden flex flex-col w-full h-full">
        {data.length !== 0 ? (
          data.map((ele, i) => {
            const value =
              ele?.bespoke_holding?.avgPrice * ele?.bespoke_holding?.quantity;
            const pnl =
              (ele?.bespoke_holding?.ltp - ele?.bespoke_holding?.avgPrice) *
              ele?.bespoke_holding?.quantity;
            return (
              <HoldingCard
                key={i}
                qty={ele?.bespoke_holding?.quantity}
                avgPrice={ele?.bespoke_holding?.avgPrice}
                symbol={ele?.bespoke_holding?.symbol}
                pnl={pnl}
                totalInvested={value}
                ltp={ele?.bespoke_holding?.ltp}
              />
            );
          })
        ) : (
          <div className="flex flex-col items-center justify-center px-4 py-16">
            <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
            <h3 className="text-xl font-semibold text-gray-900 mb-2">
              No Holdings Yet
            </h3>
            <p className="text-gray-500 text-center mb-6">
              Start placing orders to view your holdings here.
            </p>
            <Link
              to="/stock-recommendation"
              className="inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
            >
              View Trade Advices
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

export default Holdings;