import { useState } from "react";
import { X, RotateCcw } from "lucide-react";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import TableHeader from "../../../TableComponent/TableHeader";
const showQuantityColumn = process.env.REACT_APP_ADVISOR_SPECIFIER;
const ClosePositionPopup = ({
  isOpen,
  onClose,
  loading,
  onConfirm,
  type,
  selectedAdvices,
  adviceTypes,
  handleTypeChange,
  handleResetType,
  orderTypes,
  handleOrderTypeChange,
  handleResetOrderType,
  rationales,
  handleRationaleChange,
  handleResetRationale,
  comments,
  handleCommentChange,
  handleResetComment,
  comments2,
  handleComment2Change,
  handleResetComment2,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [quantities, setQuantities] = useState({});

  if (!isOpen) return null;

  const handleInitialConfirm = () => {
    setShowConfirmation(false);
    setShowTable(true);
    const initialQuantities = {};
    selectedAdvices?.forEach((advice) => {
      initialQuantities[advice?.id] = advice?.quantity;
    });
    setQuantities(initialQuantities);
  };

  const handleFinalConfirm = () => {
    const updatedAdvices = selectedAdvices.map((advice) => ({
      ...advice,
      quantity: quantities[advice.id] || advice.quantity,
    }));
    onConfirm(type, updatedAdvices);
  };

  const handleQuantityChange = (id, newQuantity) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.min(
        Number(newQuantity),
        selectedAdvices?.find((a) => a.id === id).quantity
      ),
    }));
  };
  const toggleType = (originalType) => {
    return originalType === "BUY" ? "SELL" : "BUY";
  };
  return (
    <div
      className={`fixed inset-0 bg-black/50 z-50 flex items-center justify-center ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div
        className={`bg-white rounded-lg p-6 w-full ${
          showTable ? "max-w-5xl" : "max-w-2xl"
        } max-h-[90vh] overflow-auto`}
      >
        {" "}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {type === "full"
              ? "Close Positions Fully"
              : "Close Positions Partially"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        {showConfirmation ? (
          <>
            <p className="mb-4">
              Are you sure you want to {type === "full" ? "fully" : "partially"}{" "}
              close the selected positions?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleInitialConfirm}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Confirm
              </button>
            </div>
          </>
        ) : showTable ? (
          <>
            <div className="flex-grow overflow-auto">
              <table className="w-full mb-4">
                <TableHeader closePositionPopUp={false} />
                <tbody>
                  {selectedAdvices?.map((advice, adviceIndex) => (
                    <tr key={advice?.id}>
                      <td className="py-2 px-4 w-40 font-poppins">
                        {advice?.symbol}
                      </td>
                      <td className="py-2 px-4 font-poppins">
                        <div className="flex items-center space-x-2">
                          <select
                            value={
                              adviceTypes[`${advice.id}-${adviceIndex}`] ||
                              (advice.type === "BUY" ? "SELL" : "BUY")
                            }
                            onChange={(e) =>
                              handleTypeChange(
                                advice.id,
                                adviceIndex,
                                e.target.value
                              )
                            }
                            className="border rounded px-2 py-1 font-poppins text-md w-24"
                          >
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                          </select>
                          {adviceTypes[`${advice.id}-${adviceIndex}`] && (
                            <button
                              onClick={() =>
                                handleResetType(advice.id, adviceIndex)
                              }
                              className="p-1 text-gray-500 hover:text-gray-700"
                              title="Reset to original value"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </td>
                      {showQuantityColumn === "RIA" ? (
                        <td className="py-2 px-4 font-poppins">
                          <input
                            type="number"
                            readOnly
                            value={quantities[advice?.id] || advice?.quantity}
                            onChange={(e) =>
                              handleQuantityChange(advice.id, e.target.value)
                            }
                            className="border rounded px-2 py-1 w-20"
                            max={advice?.quantity}
                            disabled={type === "full"}
                          />
                        </td>
                      ) : null}

                      <td className="py-2 px-4 font-poppins">
                        {advice?.exchange}
                      </td>
                      <td className="py-2 px-4 font-poppins">
                        <div className="flex items-center space-x-2">
                          <select
                            value={
                              orderTypes[`${advice.id}-${adviceIndex}`] ||
                              advice.order_type
                            }
                            onChange={(e) =>
                              handleOrderTypeChange(
                                advice.id,
                                adviceIndex,
                                e.target.value
                              )
                            }
                            className="border rounded px-2 py-1 font-poppins text-md w-32"
                          >
                            <option value="MARKET">MARKET</option>
                            <option value="LIMIT">LIMIT</option>
                            <option value="STOP">STOP</option>
                          </select>
                          {orderTypes[`${advice.id}-${adviceIndex}`] && (
                            <button
                              onClick={() =>
                                handleResetOrderType(advice.id, adviceIndex)
                              }
                              className="p-1 text-gray-500 hover:text-gray-700"
                              title="Reset to original value"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </td>
                      <td className="py-2 px-4 font-poppins">
                        {advice?.product_type}
                      </td>
                      <td className="py-2 px-4 font-poppins">
                        {advice?.segment}
                      </td>
                      <td className="py-2 px-4 w-96 h-24">
                        <div className="flex items-center space-x-2">
                          <textarea
                            value={
                              rationales[`${advice.id}-${adviceIndex}`] ||
                              advice.rationale
                            }
                            onChange={(e) =>
                              handleRationaleChange(
                                advice.id,
                                adviceIndex,
                                e.target.value
                              )
                            }
                            className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                          />
                          {rationales[`${advice.id}-${adviceIndex}`] && (
                            <button
                              onClick={() =>
                                handleResetRationale(advice.id, adviceIndex)
                              }
                              className="p-1 text-gray-500 hover:text-gray-700"
                              title="Reset to original value"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </td>
                      <td className="py-2 px-4 font-poppins w-96// h-24">
                        <div className="flex items-center space-x-2">
                          <textarea
                            value={
                              comments[`${advice.id}-${adviceIndex}`] ||
                              advice.comments
                            }
                            onChange={(e) =>
                              handleCommentChange(
                                advice.id,
                                adviceIndex,
                                e.target.value
                              )
                            }
                            className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                          />
                          {comments[`${advice.id}-${adviceIndex}`] && (
                            <button
                              onClick={() =>
                                handleResetComment(advice.id, adviceIndex)
                              }
                              className="p-1 text-gray-500 hover:text-gray-700"
                              title="Reset to original value"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </td>
                      <td className="py-2 px-4 w-96 h-24">
                        <div className="flex items-center space-x-2">
                          <textarea
                            value={
                              comments2[`${advice.id}-${adviceIndex}`] ||
                              advice.comment2
                            }
                            onChange={(e) =>
                              handleComment2Change(
                                advice.id,
                                adviceIndex,
                                e.target.value
                              )
                            }
                            className="border rounded px-2 py-1 font-poppins text-md w-80 h-24 resize-y"
                          />
                          {comments2[`${advice.id}-${adviceIndex}`] && (
                            <button
                              onClick={() =>
                                handleResetComment2(advice.id, adviceIndex)
                              }
                              className="p-1 text-gray-500 hover:text-gray-700"
                              title="Reset to original value"
                            >
                              <RotateCcw className="h-4 w-4" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-end space-x-2 mt-4 pt-4 border-t">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleFinalConfirm}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                {loading === true ? <LoadingSpinner /> : "Confirm"}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ClosePositionPopup;
