import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { ChevronDown, ChevronRight, X, AlertCircle } from "lucide-react";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import NestedTablePositions from "./NestedTablePostions";


const OpenPositionsTable = ({
  data,
  groupedData,
  adminEmail,
  advisorName,
  email_password,
  getLTPForSymbol,
  advisorSpecifier,
}) => {
  const [openRow, setOpenRow] = useState(null);
  const [openRecommendationModel, setOpenRecommendationModel] = useState(false);
  const [allUserDetails, setAllUserDetails] = useState();
  const [selectSymbol, setSelectSymbol] = useState();
  const [selectUserEmail, setSelectEmail] = useState();
  const [loading, setLoading] = useState(false);

  const toggleRow = (symbol) => {
    setOpenRow(openRow === symbol ? null : symbol);
  };

  const handleSendAdvice = async () => {
    if (!allUserDetails) return;

    setLoading(true);
    const adviceData = allUserDetails.map((user) => ({
      email: user._id.email,
      trade_given_by: adminEmail,
      email_password: email_password,
      advisor_name: advisorName,
      Symbol: user.Symbol,
      Exchange: user.Exchange,
      Type: "SELL",
      OrderType: "MARKET",
      ProductType: "DELIVERY",
      Segment: "EQUITY",
      Price: 0,
      advisorType: advisorSpecifier,
      date: moment(user.last_updated).format(),
      Quantity: user.tradedQty,
      Advised_Range_Lower: 0,
      Advised_Range_Higher: 0,
    }));

    try {
      await axios.post(`${server.ccxtServer.baseUrl}comms/send-reco`, adviceData);
      toast.success("Advice has been sent successfully", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
      setOpenRecommendationModel(false);
    } catch (error) {
      console.error(error);
      toast.error("Error in sending Advice!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  if (!data?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-405px)] bg-white">
        <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
        <h3 className="text-xl font-semibold text-gray-900 mb-2">No Active Positions</h3>
        <p className="text-gray-600 mb-6 max-w-md text-center">
          Your clients haven't placed any orders today.
        </p>
        <Link
          to="/admin/send-recommendation-status"
          className="inline-flex items-center px-6 py-2.5 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
        >
          View Sent Advice
        </Link>
      </div>
    );
  }

  return (
    <div className="bg-white h-[calc(100vh-405px)] rounded-lg overflow-hidden">
      <Toaster position="top-center" reverseOrder={false} />

      <div className="h-full overflow-auto">
        <table className="w-full">
          <thead className="bg-gray-50 sticky top-0 z-20">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Stock Name
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                No. of Accounts
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {Object.entries(groupedData).map(([symbol, items]) => {
              const price = getLTPForSymbol(symbol);
              return (
                <React.Fragment key={symbol}>
                  <tr className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => toggleRow(symbol)}
                        className="flex items-center text-sm font-medium text-gray-900"
                      >
                        {openRow === symbol ? (
                          <ChevronDown className="w-4 h-4 mr-2 text-gray-500" />
                        ) : (
                          <ChevronRight className="w-4 h-4 mr-2 text-gray-500" />
                        )}
                        {symbol}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className="text-sm text-gray-900">{items.length}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Buy
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <button
                        onClick={() => {
                          setOpenRecommendationModel(true);
                          setAllUserDetails(items);
                          setSelectSymbol(symbol);
                        }}
                        className="inline-flex items-center px-3 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                      >
                        Sell
                      </button>
                    </td>
                  </tr>
                  {openRow === symbol && (
                    <tr>
                      <td colSpan={4} className="px-6 py-4">
                        <div className="border border-gray-200 rounded-lg overflow-hidden">
                          <NestedTablePositions
                            details={items}
                            setOpenRecommendationModel={setOpenRecommendationModel}
                            setAllUserDeatils={setAllUserDetails}
                            setSelectEmail={setSelectEmail}
                            symbol={symbol}
                            setSelectSymbol={setSelectSymbol}
                            price={price}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Confirmation Modal */}
      {openRecommendationModel && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black/25 backdrop-blur-sm" />
          <div className="relative w-full max-w-lg bg-white rounded-xl shadow-2xl">
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">Confirm Action</h3>
                <button
                  onClick={() => {
                    setOpenRecommendationModel(false);
                    setSelectEmail(undefined);
                    setAllUserDetails(undefined);
                    setSelectSymbol(undefined);
                  }}
                  className="p-2 text-gray-400 hover:text-gray-500 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>

            <div className="px-6 py-4">
              <p className="text-sm text-gray-600">
                {openRow ? (
                  <>
                    Are you sure you want to send a sell recommendation for{" "}
                    <span className="font-medium">{selectSymbol}</span> to client{" "}
                    <span className="font-medium">{selectUserEmail}</span>?
                  </>
                ) : (
                  <>
                    Are you sure you want to send a sell recommendation for{" "}
                    <span className="font-medium">{selectSymbol}</span> to all clients?
                  </>
                )}
              </p>
            </div>

            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => {
                    setOpenRecommendationModel(false);
                    setSelectEmail(undefined);
                    setAllUserDetails(undefined);
                    setSelectSymbol(undefined);
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSendAdvice}
                  disabled={loading}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <>
                      <div className="w-4 h-4 mr-2 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      Processing...
                    </>
                  ) : (
                    "Send Advice"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenPositionsTable;