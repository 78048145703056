import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { Users, AlertTriangle, LayoutDashboard } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { InfoCircleOutlined } from '@ant-design/icons';

import TradeFailureCard from "./TradeFailureCard";
import AdminEmptyState from "./AdminEmptyState";
import NoTradeFailureModal from "./NoTradeFailureModal";
import TradeInconsistencyCard from "./TradeInconsistencyCard";
import OpenPositionsTable from "./OpenPositionsTable";
import HoldingsTable from "./HoldingsTable";
import GroupListBlock from "./GroupListBlock";
import SendEmailTemplate from "./SendEmailTemplate";
import AdminDashboardLayout from "../AdminDashboardLayout";

import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { getAdminDetails } from "../CommonApiCall/getAdminDetails";
import server from "../../utils/serverConfig";

const style = {
  selected:
    "text-[14px] lg:text-[16px] text-black font-bold border-b-[3px] border-black cursor-pointer",
  unselected:
    "font-medium text-[14px] lg:text-[16px] text-[#000000]/40 cursor-pointer",
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-3 py-3 bg-white text-[16px] peer text-gray-900 placeholder:text-gray-400 placeholder:font-normal font-medium rounded-md mt-1.5 ring-1 hover:ring-2 ring-gray-200 hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9] transition ease-in duration-200",
  labelFloat:
    "absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900 hover:bg-[#D9D9D9] hover:text-gray-100 transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
  selected_2:
    "flex items-center text-[16px] leading-[14px] lg:text-[18px] font-poppins text-black font-semibold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected_2:
    "flex items-center text-[16px] leading-[14px] font-medium font-poppins lg:text-[18px] text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};
const AdminHomeSection = () => {
  // Environment variables
  const ccxtUrl = process.env.REACT_APP_CCXT_SERVER_WEBSOCKET_URL;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const adminDetailsString = localStorage.getItem("userDetails");
  const adminDetails = adminDetailsString ? JSON.parse(adminDetailsString) : null;
  const adminEmail = adminDetails?.email;

  // State management
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAdminDetails, setIsLoadingAdminDetails] = useState(true);
  const [AdminDetails, setAdminDetails] = useState();
  const [show, setShow] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [tradeFailureModal, setTradeFailureModal] = useState(false);
  const [tradeInconsistencyModal, setTradeInconsistencyModal] = useState(false);
  const [allRecommendations, setAllRecommendations] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [userEmailCount, setUserEmailCount] = useState("");
  const [selectedTab, setSelectedTab] = useState("missed-trades");
  const [selectedPositionTab, setSelectedPositionTab] = useState("holdings");
  const [allGroupsData, setAllGroupsData] = useState([]);
  const [allClientsData, setAllClientsData] = useState([]);
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [loadingHoldingData, setLoadingHoldingData] = useState(false);
  const [positionsData, setPositionsData] = useState([]);
  const [positionsGroupedData, setPositionsGroupedData] = useState({});
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [openEmailTemplate, setOpenEmailTemplate] = useState(false);

  // Derived state
  const userId = AdminDetails?._id;
  const advisorName = AdminDetails?.advisorName;
  const email_password = AdminDetails?.email_password;
  const adminRationale = AdminDetails?.rationale;
  const advisorSpecifier = AdminDetails?.advisorSpecifier;
  const disclaimerData = AdminDetails;
  const ShowTradeInconsistency = advisorSpecifier === "RIA";

  // WebSocket integration
  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    positionsData.length > 0 ? positionsData : null
  );

  // Data fetching functions
  const getAllGroupsData = useCallback(async (userId) => {
    if (!userId) return;
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-groups/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch groups");
      const data = await response.json();
      setAllGroupsData(data.data || []);
    } catch (error) {
      console.error("Error fetching groups:", error);
      setAllGroupsData([]);
    }
  }, []);

  const getAllClientsData = useCallback(async (userId) => {
    if (!userId) return;
    try {
      const response = await fetch(
        `${server.server.baseUrl}api/all-clients/${userId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch clients");
      const data = await response.json();
      setAllClientsData(data.data || []);
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClientsData([]);
    }
  }, []);

  const getAllRecommendations = useCallback(async () => {
    if (!adminEmail) return;
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by?trade_given_by=${adminEmail}`
      );
      setAllRecommendations(response.data.trades || []);
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      setAllRecommendations([]);
    }
  }, [adminEmail]);

  const getAllHoldingsData = useCallback(async () => {
    if (!adminEmail) return;
    setLoadingHoldingData(true);
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/portfolio_admin`,
        { params: { email: adminEmail } }
      );
      setData(response.data.filteredData);
    } catch (error) {
      console.error("Error fetching holdings:", error);
      setData([]);
    } finally {
      setLoadingHoldingData(false);
    }
  }, [adminEmail]);

  const getAllPositionsData = useCallback(async () => {
    if (!adminEmail) return;
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/positions/admin/specific-admin?email=${adminEmail}`
      );
      setPositionsData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching positions:", error);
      setPositionsData([]);
    }
  }, [adminEmail]);

  // Filtered trades calculations
  const filteredFailuresTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return trade.trade_place_status === "rejected" && tradeDate >= sevenDaysAgo;
  });

  const filteredInconsistencyTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return (
      ((trade.trade_place_status === "Placed" &&
        trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "complete" &&
          trade.Quantity !== trade.tradedQty) ||
        (trade.trade_place_status === "Executed" &&
          trade.Quantity !== trade.tradedQty)) &&
      tradeDate >= sevenDaysAgo
    );
  });

  const filteredMissedTrades = allRecommendations?.filter((trade) => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const tradeDate = new Date(trade.date);
    return (
      trade.trade_place_status === "recommend" && tradeDate >= sevenDaysAgo
    );
  });

  const inconsistencyTradesEmailCounts = Array.from(
    new Set(filteredInconsistencyTrades?.map((trade) => trade.user_email))
  );

  const failuresTradesEmailCounts = Array.from(
    new Set(filteredFailuresTrades?.map((trade) => trade.user_email))
  );

  const missedTradesEmailCounts = Array.from(
    new Set(filteredMissedTrades?.map((trade) => trade.user_email))
  );



  // Effects
  useEffect(() => {
    if (adminEmail) {
      setIsLoadingAdminDetails(true);
      getAdminDetails(adminEmail)
        .then(setAdminDetails)
        .catch(console.error)
        .finally(() => setIsLoadingAdminDetails(false));
    }
  }, [adminEmail]);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsEmptyState(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (userId) {
      getAllGroupsData(userId);
      getAllClientsData(userId);
    }
  }, [userId, getAllGroupsData, getAllClientsData]);

  useEffect(() => {
    getAllRecommendations();
  }, [getAllRecommendations]);

  useEffect(() => {
    getAllHoldingsData();
  }, [getAllHoldingsData]);

  useEffect(() => {
    getAllPositionsData();
  }, [getAllPositionsData]);

  useEffect(() => {
    const grouped = data.reduce((acc, item) => {
      if (item.holding) {
        const symbol = item.holding.symbol;
        if (!acc[symbol]) acc[symbol] = [];
        acc[symbol].push(item);
      }
      return acc;
    }, {});
    setGroupedData(grouped);
  }, [data]);

  useEffect(() => {
    if (positionsData) {
      const grouped = positionsData.reduce((acc, item) => {
        if (item.Symbol) {
          const symbol = item.Symbol;
          if (!acc[symbol]) acc[symbol] = [];
          acc[symbol].push(item);
        }
        return acc;
      }, {});
      setPositionsGroupedData(grouped);
    }
  }, [positionsData]);

  // Loading state
  if (isLoading || isLoadingAdminDetails) {
    return (
      <AdminDashboardLayout>
        <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
        </div>
      </AdminDashboardLayout>
    );
  }

  return (
    <AdminDashboardLayout>
      <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100">
        {/* Info Strip */}
        <div className="bg-[#0070D0] text-white w-full py-4">
          <div className="w-full px-8 mx-auto">
            <h2 className="text-2xl font-semibold mb-2">Investment Portfolio Dashboard</h2>
            <p className="text-blue-100 text-lg">
              Manage client groups, monitor trade status, and track portfolio performance all in one place.
            </p>
          </div>
        </div>

        {allGroupsData.length > 0 ? (
          <div className="w-full px-8 py-6 mx-auto">
            <Toaster position="top-center" reverseOrder={true} />

            <div className="space-y-8">
              {/* Top Section Grid */}
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 w-full">
                {/* Groups Section */}
                <div className="bg-white rounded-xl shadow-lg overflow-hidden flex flex-col flex-1">
                  <div className="px-6 py-4 border-b border-gray-100">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <Users className="w-6 h-6 text-blue-600" />
                        <h3 className="text-xl font-semibold text-gray-900">Send Investments to Client Groups</h3>
                      </div>
                      <button className="group relative p-2 hover:bg-gray-100 rounded-lg">
                        <InfoCircleOutlined className="w-5 h-5 text-gray-400" />
                        <div className="hidden group-hover:block absolute right-0 top-full mt-2 w-72 p-4 bg-white rounded-lg shadow-xl text-base text-gray-600 z-10">
                          Manage and send investment advice to different client groups
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="flex-1 overflow-auto px-4">
                    <GroupListBlock
                      userId={userId}
                      allClientsData={allClientsData}
                      allGroupsData={allGroupsData}
                      adminEmail={adminEmail}
                      advisorName={advisorName}
                      email_password={email_password}
                      adminRationale={adminRationale}
                      advisorSpecifier={advisorSpecifier}
                    />
                  </div>
                </div>

                {/* Trade Status Section */}
                {filteredFailuresTrades?.length === 0 &&
                filteredInconsistencyTrades?.length === 0 &&
                filteredMissedTrades?.length === 0 ? (
                  <NoTradeFailureModal />
                ) : (
                   <div className="flex flex-col shadow-sm w-full  bg-[#ffffff]   rounded-lg">
                            <div className="flex space-x-1 overflow-x-auto md:space-x-6 gap-y-3 gap-x-3 w-full border-b-[1px] border-[#000000]/10  h-[50px] xl:h-[50px] px-4  rounded-t-lg ">
                              {filteredFailuresTrades?.length !== 0 ? (
                                <div
                                  className={`${
                                    selectedTab === "trade-failure"
                                      ? style.selected
                                      : style.unselected
                                  } flex font-sans items-center justify-center min-w-[150px] `}
                                  onClick={() => {
                                    setSelectedTab("trade-failure");
                                  }}
                                >
                                  Trade Failures
                                  {filteredFailuresTrades &&
                                    failuresTradesEmailCounts.length > 0 && (
                                      <span
                                        className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1 justify-center min-w-[1.5rem] h-6 rounded-full px-1 ${
                                          selectedTab !== "trade-failure"
                                            ? "bg-[#000000]/30"
                                            : "bg-[#E43D3D]"
                                        }`}
                                      >
                                        {filteredFailuresTrades
                                          ? failuresTradesEmailCounts.length
                                          : ""}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed  `}
                                >
                                  Trade Failures
                                </div>
                              )}

                              {filteredInconsistencyTrades?.length !== 0 ? (
                                <div
                                  className={`${
                                    selectedTab === "trade-inconsistency"
                                      ? ` ${style.selected}`
                                      : ` ${style.unselected}`
                                  } flex font-sans items-center justify-center min-w-[200px] `}
                                  onClick={() => {
                                    setSelectedTab("trade-inconsistency");
                                  }}
                                >
                                  Trade Inconsistencies
                                  {filteredInconsistencyTrades &&
                                    inconsistencyTradesEmailCounts?.length >
                                      0 && (
                                      <span
                                        className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1 justify-center min-w-[1.5rem] h-6 rounded-full px-1 ${
                                          selectedTab !== "trade-inconsistency"
                                            ? "bg-[#000000]/30"
                                            : "bg-[#E43D3D]"
                                        }`}
                                      >
                                        {filteredInconsistencyTrades
                                          ? inconsistencyTradesEmailCounts?.length
                                          : ""}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed min-w-[160px]`}
                                >
                                  Trade Inconsistencies
                                </div>
                              )}

                              {filteredMissedTrades?.length !== 0 ? (
                                <div
                                  className={`${
                                    selectedTab === "missed-trades"
                                      ? ` ${style.selected}`
                                      : ` ${style.unselected}`
                                  } flex font-sans items-center justify-center min-w-[150px]  `}
                                  onClick={() => {
                                    setSelectedTab("missed-trades");
                                  }}
                                >
                                  Missed Trades
                                  {filteredMissedTrades &&
                                    missedTradesEmailCounts?.length > 0 && (
                                      <span
                                        className={`ml-2 flex items-center text-[14px] font-medium text-[#ffffff] capitalize col-span-1 justify-center min-w-[1.5rem] h-6 rounded-full px-1 ${
                                          selectedTab !== "missed-trades"
                                            ? "bg-[#000000]/30"
                                            : "bg-[#E43D3D]"
                                        }`}
                                      >
                                        {filteredMissedTrades
                                          ? missedTradesEmailCounts?.length
                                          : ""}
                                      </span>
                                    )}
                                </div>
                              ) : (
                                <div
                                  className={`flex font-sans items-center justify-center  font-medium  text-sm xl:text-[16px]  text-[#000000]/40  cursor-not-allowed`}
                                >
                                  Missed Trades
                                </div>
                              )}
                            </div>

                            <div className="w-full grid grid-cols-5 items-center px-4 h-[50px] border-b-[1px] border-[#000000]/10 ">
                              <div className="text-sm text-gray-500  font-poppins font-medium col-span-1">
                                Issues
                              </div>
                              <div className=" text-sm font-medium text-gray-500 font-poppins  col-span-3">
                                Email Id
                              </div>
                              <div className=" text-sm  text-gray-500 text-end font-poppins font-medium col-span-1">
                                Actions
                              </div>
                            </div>

                            {selectedTab === "trade-failure" && (
                              <div className="flex flex-col   overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredFailuresTrades?.map(
                                      (trade) => trade.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredFailuresTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredFailuresTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeFailureCard
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeFailureModal={tradeFailureModal}
                                      setTradeFailureModal={
                                        setTradeFailureModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      setUserEmailCount={setUserEmailCount}
                                      userEmailCount={userEmailCount}
                                      advisorSpecifier={advisorSpecifier}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}

                            {selectedTab === "trade-inconsistency" && (
                              <div className="flex flex-col overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredInconsistencyTrades?.map(
                                      (trade) => trade?.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredInconsistencyTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredInconsistencyTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeInconsistencyCard
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeInconsistencyModal={
                                        tradeInconsistencyModal
                                      }
                                      setTradeInconsistencyModal={
                                        setTradeInconsistencyModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      userEmailCount={userEmailCount}
                                      setUserEmailCount={setUserEmailCount}
                                      advisorSpecifier={advisorSpecifier}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}

                            {selectedTab === "missed-trades" && (
                              <div className="flex flex-col   overflow-y-scroll custom-scroll  ">
                                {Array.from(
                                  new Set(
                                    filteredMissedTrades?.map(
                                      (trade) => trade?.user_email
                                    )
                                  )
                                ).map((email, i) => {
                                  const ele = filteredMissedTrades?.find(
                                    (trade) => trade?.user_email === email
                                  );
                                  const emailCount =
                                    filteredMissedTrades?.filter(
                                      (trade) => trade?.user_email === email
                                    ).length;
                                  return (
                                    <TradeInconsistencyCard
                                      userName="User-1"
                                      userEmail={ele?.user_email}
                                      emailCount={emailCount}
                                      tradeInconsistencyModal={
                                        tradeFailureModal
                                      }
                                      setTradeInconsistencyModal={
                                        setTradeFailureModal
                                      }
                                      selectedTab={selectedTab}
                                      setSelectedTab={setSelectedTab}
                                      key={i}
                                      filteredFailuresTrades={
                                        filteredFailuresTrades &&
                                        filteredFailuresTrades
                                      }
                                      filteredInconsistencyTrades={
                                        filteredInconsistencyTrades &&
                                        filteredInconsistencyTrades
                                      }
                                      filteredMissedTrades={
                                        filteredMissedTrades &&
                                        filteredMissedTrades
                                      }
                                      setSelectedUser={setSelectedUser}
                                      selectedUser={selectedUser}
                                      userEmailCount={userEmailCount}
                                      setUserEmailCount={setUserEmailCount}
                                      whiteLabelText={whiteLabelText}
                                      setOpenEmailTemplate={
                                        setOpenEmailTemplate
                                      }
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>


              {/* Bottom Section - Portfolio */}
              <div className="bg-white rounded-xl shadow-lg overflow-hidden w-full">
                <div className="border-b border-gray-200">
                  <div className="px-6 py-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-4">
                        <LayoutDashboard className="w-6 h-6 text-[#0070D0]" />
                        <h3 className="text-xl font-semibold text-gray-900">Portfolio Overview</h3>
                      </div>
                      <div className="flex items-center space-x-6">
                        <div className="flex gap-4">
                          <button
                            onClick={() => setSelectedPositionTab("holdings")}
                            className={`px-6 py-2 text-base font-medium rounded-lg transition-colors ${
                              selectedPositionTab === "holdings"
                                ? "bg-[#0070D0] text-white"
                                : "text-gray-600 hover:bg-gray-50"
                            }`}
                          >
                            Holdings
                          </button>
                          <button
                            onClick={() => setSelectedPositionTab("positions")}
                            className={`px-6 py-2 text-base font-medium rounded-lg transition-colors ${
                              selectedPositionTab === "positions"
                                ? "bg-indigo-600 text-white"
                                : "text-gray-600 hover:bg-gray-50"
                            }`}
                          >
                            Positions
                          </button>
                        </div>
                        <button className="group relative p-2 hover:bg-gray-100 rounded-lg">
                          <InfoCircleOutlined className="text-gray-400" style={{ fontSize: '20px' }} />
                          <div className="hidden group-hover:block absolute right-0 top-full mt-2 w-72 p-4 bg-white rounded-lg shadow-xl text-base text-gray-600 z-10">
                            View and manage holdings and positions
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Portfolio Content */}
                <div className=" overflow-auto w-full">
                  {selectedPositionTab === "holdings" ? (
                    <div className="w-full">
                      <HoldingsTable
                        adminEmail={adminEmail}
                        advisorName={advisorName}
                        email_password={email_password}
                        groupedData={groupedData}
                        data={data}
                        loadingHoldingData={loadingHoldingData}
                        advisorSpecifier={advisorSpecifier}
                      />
                    </div>
                  ) : (
                    <div className="w-full">
                      <OpenPositionsTable
                        adminEmail={adminEmail}
                        advisorName={advisorName}
                        email_password={email_password}
                        groupedData={positionsGroupedData}
                        data={positionsData}
                        getLTPForSymbol={getLTPForSymbol}
                        advisorSpecifier={advisorSpecifier}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <AdminEmptyState adminEmail={adminEmail} disclaimerData={disclaimerData} />
        )}

        {/* Email Template Modal */}
        {openEmailTemplate && (
          <SendEmailTemplate
            whiteLabelText={whiteLabelText}
            setOpenEmailTemplate={setOpenEmailTemplate}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        )}
      </div>
    </AdminDashboardLayout>
  );
};

// Updated Tab Button Component for desktop
const TabButton = ({ active, onClick, children, count }) => (
  <button
    onClick={onClick}
    className={`flex items-center space-x-3 px-8 py-4 text-base font-medium whitespace-nowrap transition-colors ${
      active
        ? "border-b-2 border-blue-600 text-blue-600"
        : "text-gray-500 hover:text-gray-900"
    }`}
  >
    <span>{children}</span>
    {count > 0 && (
      <span
        className={`flex items-center justify-center w-8 h-8 text-sm font-medium rounded-full ${
          active ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600"
        }`}
      >
        {count}
      </span>
    )}
  </button>
);

export default AdminHomeSection;