import React, { useState, useRef, useEffect  } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import axios from "axios";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";
import {
  EyeIcon,
  EyeOffIcon,
  ArrowLeft,
  X,
  XIcon,
  ChevronRight,
  Search,
  ChevronLeft,
} from "lucide-react";
import YouTube from "react-youtube";
import { ChevronDown, ChevronUp, Check, ClipboardList } from "lucide-react";

import IfflLogo from "../../assests/iifl_logo.png";
import AngelOneLogo from "../../assests/AngleLogo.png";
import Zerodha from "../../assests/Zerodha.png";
import Icici from "../../assests/icici.png";
import Upstox from "../../assests/upstox.svg";
import Kotak from "../../assests/kotak.png";
import Hdfc from "../../assests/hdfc_securities.png";
import Dhan from "../../assests/dhan.png";

import AliceBlue from "../../assests/aliceblue.png";
import Fyers from "../../assests/fyers.png";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import TokenExpireBrokarModal from "../RootSection/TokenExpireBrokarModal";
import Copy from "../../assests/Icon/copy.svg";

import server from "../../utils/serverConfig";
import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import { motion, AnimatePresence } from "framer-motion";
import Checked from "../../assests/checked.svg";

const style = {
  firstHeading: "text-sm text-gray-900 text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-lg  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-1 hover:ring-2 ring-gray-200    hover:ring-[#D9D9D9]  focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full",
};

const initialState = {
  broker: "",
  clientCode: "",
  apiKey: "",
  secretKey: "",
  password: "",
  showApiKey: false,
  showSecretKey: false,
  showPassword: false,
  inputValue: "",
  jwtToken: "",
  isOpen: false,
  selectMethod: "Mobile",
  mobileNumber: "",
  panCard: "",
  mpin: "",
  otp: "",
  openOtpBox: false,
  storeResponse: null,
  qrValue: "",
  loading: false,
  isLoading: false,
  zerodhaLoading: false,
  fyersLoading: false,
};

const ConnectBroker = ({
  uid,
  setBrokerModel,
  broker: initialBroker,
  setBroker,
  brokerModel,
  getUserDetails,
  setWithoutBroker,
  handleAcceptRebalance,
  withoutBrokerLoading,
  setSelectNonBroker,
  withoutBrokerModal,
}) => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [showLetUsKnow, setShowLetUsKnow] = useState(false);

  const [brokers, setBrokers] = useState([]);
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBrokerName, setSelectedBrokerName] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    fetch("/allBrokers.json")
      .then((response) => response.text())
      .then((text) => {
        const cleanedText = text.replace(/^\uFEFF/, "");
        return JSON.parse(cleanedText);
      })
      .then((data) => {
        if (data && data?.Broker) {
          // Extract unique names only
          const uniqueBrokers = Array.from(
            new Set(data?.Broker?.map((broker) => broker?.Name))
          ).map((name) => {
            return data?.Broker?.find((broker) => broker?.Name === name);
          });
          setBrokers(uniqueBrokers);
          setFilteredBrokers(uniqueBrokers);
        } else {
          throw new Error("Invalid data format");
        }
      })
      .catch((error) => {
        console.error("Error fetching brokers:", error);
        setError(error?.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = brokers.filter((broker) =>
      broker.Name.toLowerCase().startsWith(term)
    );
    setFilteredBrokers(filtered);
  };

  const handleSelectBroker = (brokerName) => {
    setSelectedBrokerName((prevSelectedBroker) =>
      prevSelectedBroker === brokerName ? null : brokerName
    );
  };

  const [findBrokerLoader, setFindBrokerLoader] = useState(false);
  const handleBrokerSubmit = () => {
    if (selectedBrokerName) {
      setFindBrokerLoader(true);
      axios
        .put(`${server.ccxtServer.baseUrl}comms/unavailable-broker/save`, {
          userEmail: userEmail,
          broker: selectedBrokerName,
        })
        .then((res) => {
          setShowSuccess(true);
          setFindBrokerLoader(false);
        })
        .catch((err) => {
          setFindBrokerLoader(false);
        });
    }
  };
  const resetStates = () => {
    setShowLetUsKnow(false);
    setShowSuccess(false);
    setSelectedBrokerName(null);
    setSearchTerm("");
    setFilteredBrokers(brokers);
  };

  const [state, setState] = useState(initialState);
  const appURL = process.env.REACT_APP_URL;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const zerodhaApiKey = process.env.REACT_APP_ZERODHA_API_KEY;
  const angelOneApiKey = process.env.REACT_APP_ANGEL_ONE_API_KEY;
  const brokerConnectRedirectURL =
    process.env.REACT_APP_BROKER_CONNECT_REDIRECT_URL;
  const playerRef = useRef(null);
  const opts = {
    height: "300",
    width: "630",
    playerVars: {
      autoplay: 0,
    },
  };

  const handlePlayerReady = (event) => {
    // Pause the video when the player is ready
    event.target.pauseVideo();
  };

  const resetState = () => {
    setState(initialState);
    setBroker(initialBroker);
  };

  useEffect(() => {
    if (!brokerModel) {
      resetState();
    }
  }, [brokerModel, initialBroker]);

  const toggleOpen = () =>
    setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));

  const storeClientCode = () => {
    setState((prev) => ({ ...prev, clientCode: prev.inputValue }));
  };

  const handleSubmit = () => {
    setState((prev) => ({ ...prev, loading: true }));
    let data = JSON.stringify(
      state.broker === "Dhan"
        ? {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            jwtToken: state.jwtToken,
          }
        : state.broker === "AliceBlue"
        ? {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            apiKey: state.apiKey,
          }
        : {
            uid: uid,
            user_broker: state.broker,
            clientCode: state.clientCode,
            qrValue: state.qrValue,
            apiKey: state.apiKey,
            password: state.password,
          }
    );

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/user/connect-broker`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // making new API call to update all model PF brokers
        let newBrokerData = {
          user_email: userEmail,
          user_broker: state.broker,
        };
        let A1_broker = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}rebalance/change_broker_model_pf`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(newBrokerData),
        };
        console.log("called the function and received: ", A1_broker);

        setState((prev) => ({ ...prev, loading: false, isLoading: true }));
        getUserDetails();
      })
      .catch((error) => {
        console.log(error);
        setState((prev) => ({ ...prev, loading: false }));
        getUserDetails();
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  useEffect(() => {
    if (state.isLoading) {
      const timer = setTimeout(() => {
        toast.success("Your broker has been successfully Connected.", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setBrokerModel(false); // Close the modal after 3 seconds
        setState((prev) => ({ ...prev, isLoading: false }));
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [state.isLoading]);

  const checkValidApiAnSecret = (details) => {
    const bytesKey = CryptoJS.AES.encrypt(details, "ApiKeySecret");
    const Key = bytesKey.toString();
    if (Key) {
      return Key;
    }
  };

  const updateSecretKey = () => {
    setState((prev) => ({ ...prev, loading: true }));
    if (state.broker === "Upstox") {
      let data = JSON.stringify({
        uid: uid,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
        redirect_uri: brokerConnectRedirectURL,
      });
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/upstox/update-key`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          if (response) {
            window.location.href = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
          toast.error("Incorrect credential.Please try again", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else if (state.broker === "Hdfc Securities") {
      let data = JSON.stringify({
        uid: uid,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
      });
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/hdfc/update-key`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          if (response) {
            window.location.href = response.data.response;
          }
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
          toast.error("Incorrect credential.Please try again", {
            duration: 5000,
            style: {
              background: "white",
              color: "#e43d3d",
              maxWidth: "500px",
              fontWeight: "bolder",
              fontSize: "14px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          });
        });
    } else {
      let data = JSON.stringify({
        uid: uid,
        user_broker: state.broker,
        apiKey: checkValidApiAnSecret(state.apiKey),
        secretKey: checkValidApiAnSecret(state.secretKey),
      });
      let config = {
        method: "put",
        url: `${server.server.baseUrl}api/icici/update-key`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setState((prev) => ({ ...prev, loading: false }));
          window.location.href = `https://api.icicidirect.com/apiuser/login?api_key=${encodeURIComponent(
            state.apiKey
          )}`;
        })
        .catch((error) => {
          console.log(error);
          setState((prev) => ({ ...prev, loading: false }));
        });
    }
  };

  const isDesktop = useMediaQuery("(min-width: 830px)");

  const updateKotakSecretKey = () => {
    setState((prev) => ({ ...prev, loading: true }));

    let data = {
      uid: uid,
      apiKey: checkValidApiAnSecret(state.apiKey),
      secretKey: checkValidApiAnSecret(state.secretKey),
    };

    // Conditionally add optional fields
    if (state.password) {
      data.password = state.password;
    }

    if (state.clientCode) {
      data.userid = state.clientCode;
    }

    if (state.mobileNumber) {
      data.mobileNumber = state.mobileNumber;
    }

    if (state.panCard) {
      data.pan = state.panCard;
    }

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/kotak/update-key`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({
          ...prev,
          loading: false,
          storeResponse: response.data.response,
          openOtpBox: true,
        }));
        // Handle success response
      })
      .catch((error) => {
        console.log(error);
        setState((prev) => ({ ...prev, loading: false }));
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const submitOtp = () => {
    setState((prev) => ({ ...prev, loading: true }));

    let data = {
      uid: uid,
      apiKey: checkValidApiAnSecret(state.apiKey),
      secretKey: checkValidApiAnSecret(state.secretKey),
      jwtToken: state.storeResponse.access_token,
      password: state.password,
      otp: state.otp,
      sid: state.storeResponse.sid,
      viewToken: state.storeResponse.view_token,
    };

    // Conditionally add optional fields (pan, mobile, userId)
    if (state.panCard) {
      data.pan = state.panCard;
    } else if (state.mobileNumber) {
      data.mobileNumber = state.mobileNumber;
    }

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/kotak/connect-broker`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));
        toast.success("You have been successfully logged in to your broker.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        // setBrokerModel
        setBrokerModel(false);
      })
      .catch((error) => {
        console.log(error);
        setState((prev) => ({ ...prev, loading: false }));
        toast.error("Incorrect credential. Please try again", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const connectZerodha = () => {
    setState((prev) => ({ ...prev, zerodhaLoading: true }));
    let data = JSON.stringify({
      apiKey: zerodhaApiKey,
      site: appURL,
    });

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}zerodha/login_url`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, zerodhaLoading: false }));
        window.location.href = `${response.data}`;
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, zerodhaLoading: false }));
        console.log(error);
      });
  };

  const connectFyers = () => {
    setState((prev) => ({ ...prev, loading: true }));
    let data = JSON.stringify({
      uid: uid,
      redirect_url: brokerConnectRedirectURL,
      clientCode: state.clientCode,
      secretKey: checkValidApiAnSecret(state.secretKey),
    });

    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/fyers/update-key`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));
        window.location.href = `${response.data.response}`;
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, loading: false }));
        console.log(error);
      });
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Copied to clipboard!", {
          duration: 4000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      },
      () => {
        toast.error("Failed to copy text", {
          duration: 5000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      }
    );
  };

  const handleContinueWithoutBroker = () => {
    axios
      .put(`${server.ccxtServer.baseUrl}comms/no-broker-required/save`, {
        userEmail: userEmail,
        noBrokerRequired: true,
      })
      .then((res) => {
        setBrokerModel(false);
        setWithoutBroker(true);
        handleAcceptRebalance();
        setSelectNonBroker(true);
      })
      .catch((err) => {
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };

  const [withoutBrokerLoader, setWithoutBrokerLoader] = useState(false);
  const handleContinueWithoutBrokerSave = () => {
    setWithoutBrokerLoader(true);
    axios
      .put(`${server.ccxtServer.baseUrl}comms/no-broker-required/save`, {
        userEmail: userEmail,
        noBrokerRequired: true,
      })
      .then((res) => {
        setWithoutBrokerLoader(false);
        getUserDetails();
        setBrokerModel(false);
        toast.success("Your preference has been stored successfully.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
            fontFamily: "Poppins",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((err) => {
        setWithoutBrokerLoader(false);
        toast.error("Something went wrong. Please try again.", {
          duration: 5000,
          style: {
            background: "white",
            color: "#e43d3d",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "14px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
      });
  };




  const connectIIFL = () => {
    // Get current page URL as redirect destination
    const currentURL = window.location.origin + window.location.pathname; 
    // Encode it to be URL-safe
    const redirectUrl = encodeURIComponent(currentURL);
    window.location.href = `https://markets.iiflcapital.com/?v=1&appkey=nHjYctmzvrHrYWA&redirect_url=${redirectUrl}`;
  };
  
  
 //  IIFL postback params
 useEffect(() => {
  const handleIIFLLogin = async () => {
    const params = new URLSearchParams(window.location.search);
    const authCode = params.get('authcode');
    const clientId = params.get('clientid');

    if (!authCode || !clientId) return;
    
    setIsLoading(true);
    try {
      const response = await axios.post(`${server.ccxtServer.baseUrl}/iifl/login/client`, {
        auth_token: authCode,
        client_code: clientId
      });

      const accessToken = response.data.sessionToken;
      

      localStorage.setItem('iiflAccessToken', accessToken);
      localStorage.setItem('iiflClientCode', clientId);

     
      setState(prev => ({
        ...prev,
        iiflClientCode: clientId,
        accessToken: accessToken,
        brokerName: 'iifl'
      }));

      toast.success('Successfully connected to IIFL');
      setBrokerModel(false);

    } catch (error) {
      console.error("IIFL Login failed:", error);
      toast.error('Failed to connect with IIFL');
    } finally {
      setIsLoading(false);
    }
  };

  handleIIFLLogin();
}, [location]);


  // if (isDesktop) {
  return (
    <>
      <Dialog open={brokerModel} onOpenChange={setBrokerModel}>
        <DialogContent className="w-auto flex justify-center animate-modal">
          {!state.broker && !state.clientCode ? (
            <AnimatePresence mode="wait">
              {!showLetUsKnow ? (
                <motion.div
                  key="brokerSelection"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="relative  min-w-[340px]  rounded-[12px] py-6 ">
                    <div className="flex flex-col space-y-3 px-0 sm:px-6 items-center justify-center text-black text-center mb-4">
                      <span className="text-black text-center text-[20px] font-bold font-poppins">
                        Select your broker for connection
                      </span>
                      <span className="text-[#000000]/70 font-poppins text-[14px] text-center leading-[20px] font-medium">
                        For seamless execution post your approval, please connect your broker
                      </span>
                    </div>
                    <div className="grid grid-cols-4 h-[230px] custom-scroll overflow-y-auto gap-2 px-0 sm:px-4">
                      <div className="w-full">
                        <div  onClick={connectIIFL}
                          // onClick={() =>
                          //   setState((prev) => ({
                          //     ...prev,
                          //     broker: "IIFL Securities",
                          //   }))
                          // }
                          
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer will-change-transform"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={IfflLogo}
                              alt="IIFL Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            More (coming soon)
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <a
                          href={`https://smartapi.angelbroking.com/publisher-login?api_key=${angelOneApiKey}`}
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={AngelOneLogo}
                              alt="AngelOne Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            AngelOne
                          </span>
                        </a>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={connectZerodha}
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Zerodha}
                              alt="Zerodha"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            {state.zerodhaLoading ? (
                              <LoadingSpinner />
                            ) : (
                              "Zerodha"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "ICICI Direct",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Icici}
                              alt="ICICI Direct"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            ICICI
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Upstox" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Upstox}
                              alt="Upstox Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Upstox
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Kotak" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Kotak}
                              alt="Kotak Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Kotak
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "Hdfc Securities",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Hdfc}
                              alt="Hdfc Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            HDFC
                          </span>
                        </div>
                      </div>
                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({ ...prev, broker: "Dhan" }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Dhan}
                              alt="Dhan Logo"
                              className="w-8 h-8 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Dhan
                          </span>
                        </div>
                      </div>

                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "AliceBlue",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={AliceBlue}
                              alt="aliceBlue Logo"
                              className="w-10 h-14 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            AliceBlue
                          </span>
                        </div>
                      </div>

                      <div className="w-full">
                        <div
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              broker: "Fyers",
                            }))
                          }
                          className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
                        >
                          <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
                            <img
                              src={Fyers}
                              alt="fyers Logo"
                              className="w-10 h-10 object-contain"
                            />
                          </div>
                          <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
                            Fyers
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="w-full px-0 sm:px-4 pt-5">
                      {/*<div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">*/}
                      {/*  <div className="flex flex-row justify-between items-center">*/}
                      {/*    <span className="text-gray-800 group-hover:text-gray-900 text-[14px] font-semibold font-poppins">*/}
                      {/*      Can't find <br className="sm:hidden" />*/}
                      {/*      your broker?*/}
                      {/*    </span>*/}
                      {/*    <button*/}
                      {/*      onClick={() => setShowLetUsKnow(true)}*/}
                      {/*      className="flex items-center text-[12px] font-medium border-none text-[#55A7F1] font-poppins"*/}
                      {/*    >*/}
                      {/*      Nothing to worry*/}
                      {/*      <ChevronRight className="ml-1 h-4 w-4" />*/}
                      {/*    </button>*/}
                      {/*  </div>*/}
                      {/*  <span className="text-gray-600 group-hover:text-gray-700 text-[12px] font-medium font-poppins">*/}
                      {/*    You will continue to receive recommendations from your*/}
                      {/*    advisor on your email and/or whatsapp/telegram. As we*/}
                      {/*    add more brokers, you can visit the page and add your*/}
                      {/*    broker later.*/}
                      {/*  </span>*/}
                      {/*</div>*/}
                    </div>
                    {withoutBrokerModal === true ? (
                      <div
                        className="flex flex-row justify-end text-center itmes-center w-full px-0 sm:px-4 pt-5 cursor-pointer"
                        onClick={handleContinueWithoutBroker}
                      >
                        <div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">
                          <div className="flex flex-row justify-between items-center">
                            <span className="text-gray-800 group-hover:text-gray-900 text-[8px] font-semibold font-poppins">
                              {withoutBrokerLoading === true ? (
                                <LoadingSpinner />
                              ) : (
                                " Continue without connecting broker"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex flex-row justify-end text-center itmes-center w-full px-0 sm:px-4 pt-5 cursor-pointer"
                        onClick={handleContinueWithoutBrokerSave}
                      >
                        <div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">
                          <div className="flex flex-row justify-between items-center">
                            <span className="text-gray-800 group-hover:text-gray-900 text-[8px] font-semibold font-poppins">
                              {withoutBrokerLoader === true ? (
                                <LoadingSpinner />
                              ) : (
                                " Continue without connecting broker"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="letUsKnow"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="flex flex-col px-0 sm:px-6 items-center justify-center text-black text-center w-[300px]  sm:w-[720px] h-[465px]">
                    {!showSuccess && (
                      <button
                        onClick={() => setShowLetUsKnow(false)}
                        className="self-start flex items-center text-sm font-semibold text-gray-600 transition-colors"
                      >
                        <ChevronLeft className="mr-1" size={22} />
                      </button>
                    )}

                    {showSuccess ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <div className=" flex items-center justify-center mb-1">
                          <img src={Checked} className="w-12 h-12 " />
                        </div>
                        <h2 className="text-[20px] mb-5 font-bold font-poppins text-black">
                          Thank You!
                        </h2>
                        <p className="text-center font-poppins text-gray-600 text-sm mb-1 max-w-[400px]">
                          Although your broker is not supported for direct trade
                          execution, do not worry.
                          <span>
                            You will be receiving advice on your whatsapp and
                            gmail.{" "}
                          </span>
                        </p>
                        {/* <button
                        onClick={resetStates}
                        className=" py-2 px-6 mt-2 bg-black text-[18px] lg:text-base  text-white font-medium rounded-md cursor-pointer"
                      >
                        Back to Broker Selection
                      </button> */}
                      </div>
                    ) : (
                      <>
                        <div className="flex flex-col items-center  mt-0">
                          <h2 className="text-[20px] mb-1 font-bold font-poppins text-black">
                            Find Your Broker
                          </h2>
                          <p className="text-center font-poppins text-gray-600 text-sm mb-4 ">
                            We're expanding our supported brokers. Help us by
                            searching for yours.
                          </p>
                        </div>
                        <div className="relative mb-3 w-full">
                          <input
                            type="text"
                            placeholder="Search for your broker"
                            className="w-full p-2 pl-8 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 text-sm font-poppins"
                            onChange={handleSearch}
                            value={searchTerm}
                          />
                          <Search
                            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
                            size={16}
                          />
                        </div>
                        <div className="w-full h-[300px] sm:h-[500px] font-poppins overflow-y-auto font-medium border border-gray-200 rounded-md bg-white mb-6 relative no-scrollbar">
                          {isLoading ? (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-black"></div>
                            </div>
                          ) : error ? (
                            <p className="text-center py-8 font-poppins text-red-500">
                              {error}
                            </p>
                          ) : filteredBrokers.length === 0 ? (
                            <p className="text-center py-8 font-poppins text-gray-600">
                              No brokers found
                            </p>
                          ) : (
                            <ul className="w-full">
                              {filteredBrokers.map((broker, i) => (
                                <li
                                  key={i}
                                  className={`px-4 py-2 text-left hover:bg-gray-100 transition-colors duration-150 cursor-pointer ${
                                    selectedBrokerName === broker.Name
                                      ? "bg-gray-200"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleSelectBroker(broker.Name)
                                  }
                                >
                                  <div className="flex justify-between items-center">
                                    <p className="font-poppins text-sm text-gray-700">
                                      {broker.Name}
                                    </p>
                                    {selectedBrokerName === broker.Name && (
                                      <Check
                                        size={16}
                                        className="text-green-500"
                                      />
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <div className="w-full flex justify-end">
                          <button
                            onClick={handleBrokerSubmit}
                            disabled={!selectedBrokerName}
                            className="w-full sm:w-1/4 px-4 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200 text-sm font-poppins font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            {findBrokerLoader === true ? (
                              <LoadingSpinner />
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          ) : state.broker === "Dhan" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-0 sm:px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div className="mb-8 rounded-xl">
                  <YouTube
                    videoId={`MhAfqNQKSrQ`}
                    className={`videoIframe rounded-xl`}
                    opts={opts}
                    onReady={handlePlayerReady}
                  />
                </div>
                {/* <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Steps to get the Client Code and Access Token for Dhan:
                </div> */}

                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">
                    Steps to Obtain API and Secret Key for Dhan:
                  </h2>
                  <button
                    onClick={toggleOpen}
                    className="focus:outline-none lg:hidden "
                  >
                    <div className="mr-4 mb-5">
                      {state.isOpen ? (
                        <ChevronUp size={20} strokeWidth={3} />
                      ) : (
                        <ChevronDown size={20} strokeWidth={3} />
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>{" "}
                    <div className="ml-3 mr-2">
                      {" "}
                      Go to
                      {/* <div className="inline-flex items-center flex-wrap">
                      <a
                        href="http://login.dhan.co"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF] "
                      >
                        http://login.dhan.co

                      </a>
                      <img
                        src={Copy}
                        alt="Copy"
                        className="w-4 h-4 cursor-pointer mr-2"
                        onClick={() =>
                          handleCopy(
                            ` http://login.dhan.co
`
                          )
                        }
                      />
                      {" "}
                      and log in using the QR code option.

                    </div> */}
                    </div>
                  </div>
                  <div className="inline-flex flex-col items-start ml-4 text-white font-normal font-poppins">
                    <div className="inline-flex items-center rounded-lg overflow-hidden ml-1 bg-blue-100">
                      <div className="px-1 pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                        <a
                          href="http://login.dhan.co"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500"
                        >
                          http://login.dhan.co
                        </a>
                      </div>
                      <div
                        className="px-1 "
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="COPY"
                      >
                        <ClipboardList
                          className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                          onClick={() => handleCopy("http://login.dhan.co")}
                        />
                      </div>
                    </div>
                    <Tooltip id="copy-tooltip" />
                    <span className="ml-1 text-black font-normal font-poppins">
                      and log in using the QR code option.
                    </span>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      Open this in desktop view ,unavailable in app
                    </div>
                  </div>

                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      Click on your profile picture and choose "My Profile on
                      Dhan". Under the Profile details, you'll find the "Client
                      ID".
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      Then, select "Dhan HQ Trading APIs" from the menu.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>5.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      To generate an access token, click on "+ New Token," enter
                      a name for your app, set the validity to 30 days, and
                      click "Generate Token."
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>6.</div>{" "}
                    <div className="ml-2 items-center">
                      {" "}
                      Copy the access token and paste it into the designated
                      field.
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-2 sm:px-12">
                <div className="text-2xl sm:text-3xl font-bold">
                  Connect {state.broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold font-poppins">
                    Client Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Client Code"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold font-poppins">
                    Access Token:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Access Token"
                      value={state.jwtToken}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          jwtToken: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.jwtToken}
                    onClick={handleSubmit}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "AliceBlue" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-0 sm:px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                    <YouTube
                      videoId={`YaONYqsiwGQ`}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                      className="videoIframe rounded-xl "
                    />

                    <div className="flex items-center justify-between mb-4 mt-8 ">
                      <h2 className="text-lg font-semibold ">
                        Steps to get the User ID and Api Key for Alice Blue:
                      </h2>
                      <button
                        onClick={toggleOpen}
                        className="focus:outline-none lg:hidden "
                      >
                        <div className="mr-4 mb-6">
                          {state.isOpen ? (
                            <ChevronUp size={20} strokeWidth={3} />
                          ) : (
                            <ChevronDown size={20} strokeWidth={3} />
                          )}
                        </div>
                      </button>
                    </div>
                    <div
                      className={`lg:block ${
                        state.isOpen ? "block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>1.</div> <div className="ml-2"> Log in to</div>
                      </div>

                      <div className="inline-flex flex-col items-start ml-2 text-white font-normal font-poppins">
                        <div className="inline-flex items-center rounded-lg overflow-hidden ml-1 bg-blue-100">
                          <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                            <a
                              href={`https://ant.aliceblueonline.com/`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500"
                            >
                              ant.aliceblueonline.com/apps
                            </a>
                          </div>
                          <div
                            className="px-1 "
                            data-tooltip-id="copy-tooltip"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy("ant.aliceblueonline.com/apps")
                              }
                            />
                          </div>
                        </div>
                        <Tooltip id="copy-tooltip" />
                        <span className="ml-1 text-black font-normal font-poppins">
                          with your phone number, password, and TOTP or mobile
                          OTP.
                        </span>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>2.</div>{" "}
                        <div className="ml-2">
                          If prompted with a Risk Disclosure pop-up, click
                          "Proceed."
                        </div>
                      </div>
                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>3.</div>{" "}
                        <div className="ml-2">
                          In the "Apps" tab, select "API Key," click "Copy," and
                          paste it on your platform. Note: This key is valid for
                          24 hours, so generate a new one daily.
                        </div>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>4.</div>{" "}
                        <div className="ml-2">
                          For your User ID, click the profile icon, go to "Your
                          Profile/Settings," and copy the client ID under your
                          name. Paste it onto your platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-0 sm:px-12">
                <div className="text-3xl font-bold">Connect {state.broker}</div>
                <div className="text-lg font-normal">
                  Please login to your AliceBlue account(logout if already
                  logged in and Login again) before attempting to connect.
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    User Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="User Id"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Api Key:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="Api Key"
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.apiKey}
                    onClick={handleSubmit}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "Fyers" ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6 lg:grid-cols-2 rounded-[10px] lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400 ease-linear duration-150 cursor-pointer"
                onClick={() => setState((prev) => ({ ...prev, broker: "" }))}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex">Back</div>
              </div>
              <div className="mt-6 px-[28px] h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                    <YouTube
                      videoId={`blhTiePBIg0`}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                      className="videoIframe rounded-xl "
                    />

                    <div className="flex items-center justify-between mb-4 mt-8 ">
                      <h2 className="text-lg font-semibold ">
                        Fyers: How to get App ID & Secret ID
                      </h2>
                      <button
                        onClick={toggleOpen}
                        className="focus:outline-none lg:hidden "
                      >
                        <div className="mr-4 mb-6">
                          {state.isOpen ? (
                            <ChevronUp size={20} strokeWidth={3} />
                          ) : (
                            <ChevronDown size={20} strokeWidth={3} />
                          )}
                        </div>
                      </button>
                    </div>
                    <div
                      className={`lg:block ${
                        state.isOpen ? "block" : "hidden"
                      }`}
                    >
                      <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>1.</div> <div className="ml-2"> Visit</div>
                      </div>

                      <div className="inline-flex flex-col items-start ml-2 text-white font-normal font-poppins">
                        <div className="inline-flex items-center  rounded-lg overflow-hidden ml-1 bg-blue-100">
                          <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                            <a
                              href="https://myapi.fyers.in/dashboard"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500"
                            >
                              https://myapi.fyers.in/dashboard
                            </a>
                          </div>
                          <div
                            className="px-1 "
                            data-tooltip-id="copy-tooltip"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy("https://myapi.fyers.in/dashboard")
                              }
                            />
                          </div>
                        </div>
                        <Tooltip id="copy-tooltip" />
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>2.</div>{" "}
                        <div className="ml-2">
                          Log in using your phone number, enter the TOTP, and
                          your 4-digit PIN.
                        </div>
                      </div>
                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>3.</div>{" "}
                        <div className="ml-2">
                          Click on the "Create App" button. Provide an app name,
                          paste the redirect URL as specified in the
                          instructions, add a description, and delete the
                          webhook. Grant all app permissions and check the box
                          to accept the API Usage Terms and Conditions. Finally,
                          click on "Create App."
                        </div>
                      </div>

                      <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                        <div>4.</div>{" "}
                        <div className="ml-2">
                          Scroll down to find the newly created app. Copy the
                          App ID and Secret ID and paste them into your
                          platform.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 md:flex flex-col space-y-4 px-12">
                <div className="text-3xl font-bold">Connect {state.broker}</div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    App Id :{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="app id"
                      type="text"
                      value={state.clientCode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          clientCode: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Secret Id:{" "}
                  </div>
                  <div className={`relative w-full md:w-[70%]`}>
                    <input
                      placeholder="secret id"
                      value={state.secretKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          secretKey: e.target.value,
                        }))
                      }
                      className={`w-full px-4 py-2 border border-gray-300 rounded-md  `}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.clientCode || !state.secretKey}
                    onClick={connectFyers}
                  >
                    {state.loading ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "ICICI Direct" ||
            state.broker === "Upstox" ||
            state.broker === "Hdfc Securities" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({ ...prev, broker: "" }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex  ">Back</div>
              </div>
              {state.broker === "Upstox" ? (
                <div className="pb-8 px-0 sm:px-10 rounded-xl pt-8">
                  <YouTube
                    videoId={`yfTXrjl0k3E`}
                    opts={opts}
                    onReady={handlePlayerReady}
                    ref={playerRef}
                    className="videoIframe rounded-xl "
                  />

                  {/* <div
                    className={`mb-2 mt-8  text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Upstox :
                  </div> */}

                  <div className="flex items-center justify-between mb-4 mt-8 ">
                    <h2 className="text-lg font-semibold ">
                      Steps to Obtain API and Secret Key for Upstox :
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-6">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px]  mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>1.</div>{" "}
                      <div className="ml-2">
                        {" "}
                        Visit
                        {/* <div className=" inline-flex items-center flex-wrap">
                        <a
                          href="https://shorturl.at/plWYJ"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF] "
                        >
                          https://shorturl.at/plWYJ

                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 mr-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `https://shorturl.at/plWYJ`
                            )
                          }
                        />
                      </div> */}
                      </div>
                    </div>

                    <div className="inline-flex flex-col items-start ml-2 text-white font-normal font-poppins">
                      <div className="inline-flex items-center border rounded-lg overflow-hidden ml-1 bg-blue-100">
                        <div className="pl-2 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://shorturl.at/plWYJ"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://shorturl.at/plWYJ
                          </a>
                        </div>
                        <div
                          className="px-1 "
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy("https://shorturl.at/plWYJ")
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                      <span className="ml-1 text-black font-normal font-poppins">
                        and log in using your phone number. Verify your identity
                        with the OTP and continue.
                      </span>
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>2.</div>{" "}
                      <div className="ml-2">
                        Enter your 6-digit PIN and continue.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>3.</div>{" "}
                      <div className="ml-2">
                        Click on the "New App" button. Fill in the "App Name"
                        field with "{whiteLabelText}" or a name of your choice.
                        Enter the "Redirect URL" as
                        <span className="inline-flex items-center gap-2 px-2  text-[15px] text-blue-500 bg-blue-100 rounded-lg">
                          {brokerConnectRedirectURL}
                          {/* <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 ml-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(`${brokerConnectRedirectURL}`)
                          }
                        /> */}
                          <div
                            className="flex items-center  border-gray-700 pl-2 flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(brokerConnectRedirectURL)
                              }
                            />
                          </div>
                          <Tooltip id="copy-tooltip-1" />
                        </span>
                      </div>
                    </div>
                    <div className="text-[15px] mb-0.5 ml-5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins">
                      You can skip the Postback URL and Description as they are
                      optional. Accept the Terms & Conditions and click on the
                      "Continue" button. Please ensure that the "Redirect URL"
                      is entered correctly as mentioned above.
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>4.</div>{" "}
                      <div className="ml-2">
                        Review the details (make sure you don't have more than 2
                        apps) and click on the "Confirm Plan" button. Your API
                        is now ready! Click on the "Done" button.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>5.</div>{" "}
                      <div className="ml-2">
                        Click on the newly created app, copy your API and Secret
                        Key, and enter these details on the designated screen.
                      </div>
                    </div>
                  </div>
                </div>
              ) : state.broker === "Hdfc Securities" ? (
                <div className="mt-6 px-0 sm:px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`iziwR2zLLvk`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  {/* <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for Hdfc Securities:
                  </div> */}

                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">
                      Steps to Obtain API and Secret Key for {state.broker}:
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-5">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>1.</div>{" "}
                      <div className="ml-3">
                        {" "}
                        Go to
                        {/* <div className="inline-flex items-center flex-wrap">
                        <a
                          href="https://developer.hdfcsec.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF] "
                        >
                          https://developer.hdfcsec.com/


                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              ` https://developer.hdfcsec.com/`
                            )
                          }
                        />

                      </div> */}
                      </div>
                    </div>

                    <div className="inline-flex flex-col items-start ml-4 text-white font-normal font-poppins">
                      <div className="inline-flex items-center border rounded-lg overflow-hidden ml-1 bg-blue-100">
                        <div className="pl-2 pr-1 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://developer.hdfcsec.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://developer.hdfcsec.com/
                          </a>
                        </div>
                        <div
                          className="px-1  border-gray-500"
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy("https://developer.hdfcsec.com/")
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>2.</div>{" "}
                      <div className="ml-2">
                        Log in with your ID, password, and OTP.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>3.</div>{" "}
                      <div className="ml-2">Accept the *Risk Disclosure*.</div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>4.</div>{" "}
                      <div className="ml-2 items-center">
                        {" "}
                        Click *Create* to make a new app. Enter app name,
                        redirect URL :
                        <span className="px-2 text-blue-500 text-sm py-0.5 rounded-lg bg-blue-100 inline-flex items-center ">
                          {brokerConnectRedirectURL}{" "}
                          <div
                            className="px-1  border-gray-700 flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 ml-1 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(`${brokerConnectRedirectURL}`)
                              }
                            />
                          </div>
                        </span>
                        <Tooltip id="copy-tooltip-1" />
                      </div>
                    </div>
                    <span className="text-black font-normal font-poppins ml-5">
                      and description, then click *Create*.
                    </span>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>5.</div>{" "}
                      <div className="ml-2">
                        Copy the *API* and *Secret Key* and paste them into the{" "}
                        {whiteLabelText} platform to connect your broker.
                      </div>
                    </div>
                  </div>
                </div>
              ) : state.broker === "Kotak" ? (
                <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`Qsv9hRR6eXw`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to get the API and Secret Key for Kotak:
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>1.</div>{" "}
                    <div className="ml-2">
                      {" "}
                      Visit Kotak Securities API Portal -
                      <a
                        href="https://napi.kotaksecurities.com/devportal/apis"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 text-[#0000FF]"
                      >
                        https://napi.kotaksecurities.com/devportal/apis
                      </a>
                      .
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>2.</div>{" "}
                    <div className="ml-2">
                      2. Sign in to your account or create one.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>3.</div>{" "}
                    <div className="ml-2">
                      After logging in, click on *Applications* in the header,
                      then click *Add New Application*. Fill in the required
                      details and save.
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>4.</div>{" "}
                    <div className="ml-2">
                      In your newly created application, go to the
                      *Subscriptions* tab and subscribe to all listed APIs
                      (Brokerage, HSNEOAPI, Login, MasterscripFiles, Portfolio,
                      WebSocket).
                    </div>
                  </div>
                  <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                    <div>5.</div>{" "}
                    <div className="ml-2">
                      5. Navigate to the *Production Keys* tab and click
                      *Generate Keys* to obtain your API & Secret Key.
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-6 px-0 sm:px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                  <div className="mb-8 rounded-xl ">
                    <YouTube
                      videoId={`XFLjL8hOctI`}
                      className={`videoIframe rounded-xl `}
                      opts={opts}
                      onReady={handlePlayerReady}
                      ref={playerRef}
                    />
                  </div>

                  {/* <div
                    className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                  >
                    Steps to Obtain API and Secret Key for ICICI:
                  </div> */}
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-semibold">
                      Steps to Obtain API and Secret Key for {state.broker}:
                    </h2>
                    <button
                      onClick={toggleOpen}
                      className="focus:outline-none lg:hidden "
                    >
                      <div className="mr-4 mb-5">
                        {state.isOpen ? (
                          <ChevronUp size={20} strokeWidth={3} />
                        ) : (
                          <ChevronDown size={20} strokeWidth={3} />
                        )}
                      </div>
                    </button>
                  </div>
                  <div
                    className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                  >
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>1.</div>{" "}
                      <div className="ml-2 inline-flex items-center flex-wrap">
                        {" "}
                        Visit
                        {/* <div className="inline-flex items-center flex-wrap">
                        <a
                          href="https://api.icicidirect.com/apiuser/home"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="px-2 text-[#0000FF]"
                        >
                          https://api.icicidirect.com/apiuser/home
                        </a>
                        <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4  cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `https://api.icicidirect.com/apiuser/home`
                            )
                          }
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="inline-flex flex-col items-start ml-2 text-white font-normal font-poppins">
                      <div className="inline-flex items-center border  rounded-lg overflow-hidden ml-2  bg-blue-100">
                        <div className="pl-3 py-0.5 rounded-md overflow-x-auto text-sm ">
                          <a
                            href="https://api.icicidirect.com/apiuser/home"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500"
                          >
                            https://api.icicidirect.com/apiuser/home{" "}
                          </a>
                        </div>
                        <div
                          className="px-1 "
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-400 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy(
                                "https://api.icicidirect.com/apiuser/home"
                              )
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                    </div>

                    <div className="fex flex-col ml-4 text-black font-normal font-poppins">
                      and log in using your username and password. Verify your
                      identity with the OTP and submit.
                    </div>

                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>2.</div>{" "}
                      <div className="ml-2 inline-flex items-center flex-wrap">
                        Click on the "Register an App" tab, then fill in the
                        "App Name" field with "{whiteLabelText}" or a name of
                        your choice. Enter the "Redirect URL" as
                        <span className="sm:pl-3 py-0.5 text-blue-500 rounded-lg text-sm bg-blue-100 inline-flex items-center">
                          {server.ccxtServer.baseUrl}icici/auth-callback/
                          {appURL}{" "}
                          {/* <img
                          src={Copy}
                          alt="Copy"
                          className="w-4 h-4 ml-2 cursor-pointer"
                          onClick={() =>
                            handleCopy(
                              `${server.ccxtServer.baseUrl}icici/auth-callback/${appURL}`
                            )
                          }
                        /> */}
                          <div
                            className="px-1  flex-shrink-0"
                            data-tooltip-id="copy-tooltip-1"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-400 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(
                                  `${server.ccxtServer.baseUrl}icici/auth-callback/${appURL}`
                                )
                              }
                            />
                          </div>
                          <Tooltip id="copy-tooltip-1" />
                        </span>
                        and click "Submit". Please ensure that "redirect URL"
                        entered correctly as mentioned above.
                      </div>
                    </div>
                    <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                      <div>3.</div>{" "}
                      <div className="ml-2">
                        Navigate to the "View Apps" tab and copy your API and
                        Secret Key- enter these details in the screen.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-10 md:flex flex-col space-y-4 px-2 sm:px-12 ">
                <div className="text-2xl sm:text-3xl font-bold">
                  Connect {state.broker}
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg  font-semibold font-poppins">
                    Api Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Api Key"
                      type={state.showApiKey ? "text" : "password"}
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showApiKey ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showApiKey: false }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showApiKey: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-base sm:text-lg font-semibold font-poppins">
                    Secret Key :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      placeholder="Secret Key"
                      type={state.showSecretKey ? "text" : "password"}
                      value={state.secretKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          secretKey: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showSecretKey ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            showSecretKey: false,
                          }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showSecretKey: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.apiKey || !state.secretKey}
                    onClick={updateSecretKey}
                  >
                    {state.loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker === "Kotak" ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({ ...prev, broker: "" }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-6 px-0 sm:px-[28px] text-base sm:text-lg  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div className="mb-8 rounded-xl ">
                  <YouTube
                    videoId={`JXwnwaxM88k`}
                    className={`videoIframe rounded-xl `}
                    opts={opts}
                    onReady={handlePlayerReady}
                    ref={playerRef}
                  />
                </div>

                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">
                    Steps to obtain the Consumer Key and Consumer Secret for{" "}
                    {state.broker}:
                  </h2>
                  <button
                    onClick={toggleOpen}
                    className="focus:outline-none lg:hidden "
                  >
                    <div className="mr-4 mb-5">
                      {state.isOpen ? (
                        <ChevronUp size={20} strokeWidth={3} />
                      ) : (
                        <ChevronDown size={20} strokeWidth={3} />
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <ol className="list-decimal pl-6 text-black font-poppins">
                    <li className="mb-2">
                      <span className=" font-semibold font-poppins">
                        Obtain Neo TradeAPI Credentials (If you don’t have your
                        client ID and password):
                      </span>
                      <br />- Visit Kotak Neo Trading API platform :{" "}
                      {/* <div className="inline-flex items-center flex-wrap">
                      <a
                        href="https://napi.kotaksecurities.com/devportal/apis"
                        className="text-blue-500 "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://napi.kotaksecurities.com/devportal/apis

                      </a>
                      <img
                        src={Copy}
                        alt="Copy"
                        className="w-4 h-4 ml-2 cursor-pointer"
                        onClick={() =>
                          handleCopy(
                            ` https://napi.kotaksecurities.com/devportal/apis`
                          )
                        }
                      />
                    </div> */}
                    </li>
                    <div className="inline-flex flex-col items-start text-white font-normal font-poppins">
                      <div className="flex items-center border  rounded-lg bg-blue-100 max-w-full">
                        <div className="pl-3 px-1 py-0.5 rounded-md text-sm overflow-hidden">
                          <a
                            href="http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 break-all mb2"
                          >
                            http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/
                          </a>
                        </div>
                        <div
                          className="p-1 pl-2 border-gray-700 flex-shrink-0"
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="COPY"
                        >
                          <ClipboardList
                            className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                            onClick={() =>
                              handleCopy(
                                "   http://kotaksecurities.com/trading-tools/kotak-neo-trading-platform/trading-api/"
                              )
                            }
                          />
                        </div>
                      </div>
                      <Tooltip id="copy-tooltip" />
                    </div>
                    <span className="pb-2 mt-2  font-poppins">
                      Find your Client ID (unique client code) in your Kotak
                      Securities Neo account under account details. Enter your
                      Client ID and click on "Register for Neo TradeAPI". Fill
                      in your Client ID, email, and contact number, then click
                      "Submit." You will receive your Client ID and password via
                      email within 30 minutes.
                    </span>
                    <li className="mb-2 font-poppins">
                      <span className=" font-semibold font-poppins">
                        Log In to the Kotak API Portal:
                      </span>{" "}
                      <br />- Visit Kotak API Portal :{""}
                      <div className="inline-flex flex-col items-start text-white font-normal font-poppins">
                        <div className="flex items-center border  rounded-lg bg-blue-100 max-w-full">
                          <div className="pl-3 px-1 py-0.5 rounded-md text-sm overflow-hidden">
                            <a
                              href="https://napi.kotaksecurities.com/devportal/apis 
"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 break-all"
                            >
                              napi.kotaksecurities.com/devportal/apis
                            </a>
                          </div>
                          <div
                            className="p-1 pl-2 border-gray-700 flex-shrink-0"
                            data-tooltip-id="copy-tooltip"
                            data-tooltip-content="COPY"
                          >
                            <ClipboardList
                              className="w-4 h-4 cursor-pointer text-blue-500 hover:text-white transition-colors"
                              onClick={() =>
                                handleCopy(
                                  "napi.kotaksecurities.com/devportal/apis"
                                )
                              }
                            />
                          </div>
                        </div>
                        <Tooltip id="copy-tooltip" />
                      </div>
                      <br />
                      <span classname="py-1 font-poppins">
                        Log in using your Client ID and password.
                      </span>
                    </li>
                    <li className="mb-2  font-poppins">
                      <span className=" font-semibold font-poppins">
                        Create an Application:{" "}
                      </span>
                      <br />- Navigate to the "Applications" section, click on
                      "Add New Application," and save the details.
                    </li>
                    <li className="mb-2 font-poppins">
                      <span className=" font-semibold font-poppins">
                        Subscribe to APIs:
                      </span>
                      <br />- In the "Subscriptions" tab, subscribe to all the
                      available APIs.
                    </li>
                    <li>
                      <span className=" font-semibold font-poppins">
                        Generate API & Secret Keys:
                      </span>
                      <br />- Go to the "Production Keys" section and click
                      "Generate Keys" to obtain your API Key and Secret Key.
                    </li>
                    <li>
                      <span className=" font-semibold font-poppins">
                        Copy and Enter the Keys:
                      </span>
                      <br />- Copy your Consumer Key and Consumer Secret, and
                      input them in the required fields on the screen.
                    </li>
                  </ol>
                </div>
              </div>
              {state.openOtpBox === true ? (
                <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                  <div className="text-3xl font-bold">
                    Connect {state.broker}
                  </div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Otp :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type="number"
                        value={state.mpin}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            mpin: e.target.value,
                          }))
                        }
                        className={`${style.inputBox}`}
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[40%] text-lg font-semibold font-poppins">
                      Mpin :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type="number"
                        value={state.otp}
                        onChange={(e) =>
                          setState((prev) => ({ ...prev, otp: e.target.value }))
                        }
                        className={`${style.inputBox}`}
                      />
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                      disabled={!state.mpin || !state.otp}
                      onClick={submitOtp}
                    >
                      {state.loading === true ? (
                        <span className="flex flex-row justify-center ">
                          <svg
                            className="h-7 w-7 text-[#ffffff] animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        `Submit Otp`
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="mt-10 md:flex flex-col space-y-4 px-1 sm:px-12 ">
                  <div className="text-2xl sm:text-3xl font-bold">
                    Connect {state.broker}
                  </div>
                  <div className="text-base sm:text-base text-rose">
                    We will not store your login credentials, you will need to
                    enter it while accepting trades
                  </div>
                  <div className="w-[90%] flex flex-row justify-between items-center border-2 border-[#000000]/40 rounded-md">
                    <div
                      className={
                        state.selectMethod === "Mobile"
                          ? "w-1/2 text-center px-8 py-2 sm:py-3 bg-black text-white font-bold text-base sm:text-lg cursor-pointer rounded-l-md"
                          : "w-1/2 text-center px-8 py-2 sm:py-3 bg-[#000000]/40  text-white text-base sm:text-lg  cursor-pointer rounded-l-md"
                      }
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          selectMethod: "Mobile",
                        }))
                      }
                    >
                      Mobile
                    </div>
                    <div
                      className={
                        state.selectMethod === "PAN"
                          ? "w-1/2 text-center px-8 py-2 sm:py-3 bg-black text-white font-bold text-base sm:text-lg  cursor-pointer"
                          : "w-1/2 text-center px-8 py-2 sm:py-3 bg-[#000000]/40  text-white text-base sm:text-lg  cursor-pointer"
                      }
                      onClick={() =>
                        setState((prev) => ({ ...prev, selectMethod: "PAN" }))
                      }
                    >
                      PAN
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[45%] text-base sm:text-lg font-semibold font-poppins">
                      Consumer Key :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Api Key"
                        type={state.showApiKey ? "text" : "password"}
                        value={state.apiKey}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            apiKey: e.target.value,
                          }))
                        }
                        className={`${style.inputBox}`}
                      />
                      {state.showApiKey ? (
                        <EyeIcon
                          onClick={() =>
                            setState((prev) => ({ ...prev, showApiKey: false }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() =>
                            setState((prev) => ({ ...prev, showApiKey: true }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[45%]  text-base sm:text-lg  font-semibold font-poppins">
                      Consumer Secret :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Secret Key"
                        type={state.showSecretKey ? "text" : "password"}
                        value={state.secretKey}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            secretKey: e.target.value,
                          }))
                        }
                        className={`${style.inputBox}`}
                      />
                      {state.showSecretKey ? (
                        <EyeIcon
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              showSecretKey: false,
                            }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              showSecretKey: true,
                            }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  {state.selectMethod === "Mobile" ? (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[45%]  text-base sm:text-lg  font-semibold font-poppins">
                        Mobile Number :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="Mobile Number"
                          type="number"
                          value={state.mobileNumber}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              mobileNumber: e.target.value,
                            }))
                          }
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  ) : state.selectMethod === "PAN" ? (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[45%]  text-base sm:text-lg  font-semibold font-poppins">
                        Pan Card :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="Pan Card"
                          type="text"
                          value={state.panCard}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              panCard: e.target.value,
                            }))
                          }
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="flex md:flex-row flex-col items-center">
                      <div className="w-full md:w-[45%]  text-base sm:text-lg  font-semibold font-poppins">
                        User Id :{" "}
                      </div>
                      <div className={`relative ${style.inputStartDiv} `}>
                        <input
                          placeholder="User Id"
                          type="text"
                          value={state.clientCode}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              clientCode: e.target.value,
                            }))
                          }
                          className={`${style.inputBox}`}
                        />
                      </div>
                    </div>
                  )}

                  <div className="flex md:flex-row flex-col items-center">
                    <div className="w-full md:w-[45%]  text-base sm:text-lg  font-semibold font-poppins">
                      Password :{" "}
                    </div>
                    <div className={`relative ${style.inputStartDiv} `}>
                      <input
                        placeholder="Password"
                        type={state.showPassword ? "text" : "password"}
                        value={state.password}
                        onChange={(e) =>
                          setState((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }))
                        }
                        className={`${style.inputBox}`}
                      />
                      {state.showPassword ? (
                        <EyeIcon
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              showPassword: false,
                            }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              showPassword: true,
                            }))
                          }
                          className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  <div className="">
                    <button
                      className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                      disabled={!state.apiKey || !state.secretKey}
                      onClick={updateKotakSecretKey}
                    >
                      {state.loading === true ? (
                        <span className="flex flex-row justify-center ">
                          <svg
                            className="h-7 w-7 text-[#ffffff] animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        `Connect ${state.broker}`
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : state.broker && !state.clientCode ? (
            <div className="relative bg-[#ffffff] w-[90vw] sm:w-full md:min-w-[90vw] h-[90vh] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8 no-scrollbar">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({ ...prev, broker: "", clientCode: "" }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-4 px-0 sm:px-[28px]  md:h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                {/* <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div> */}

                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-lg font-semibold">
                    Step-by-Step Guide:{" "}
                  </h2>
                  <button
                    onClick={toggleOpen}
                    className="focus:outline-none lg:hidden "
                  >
                    <div className="mr-28 mb-0">
                      {state.isOpen ? (
                        <ChevronUp size={20} strokeWidth={3} />
                      ) : (
                        <ChevronDown size={20} strokeWidth={3} />
                      )}
                    </div>
                  </button>
                </div>
                <div
                  className={`lg:block ${state.isOpen ? "block" : "hidden"}`}
                >
                  <div className="text-lg mb-0.5 leading-[22px] md:text-base md:leading-[36px] text-black font-medium font-poppins ">
                    1. First enter your unique client code in the designated
                    field.
                  </div>
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-4 px-1 sm:px-12 ">
                <div className="text-[20px] font-semibold font-poppins">
                  Connect {state.broker}
                </div>
                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-[16px] font-semibold font-poppins">
                    Client Code :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      id="clientCode"
                      type="text"
                      placeholder="Client Code"
                      className={`${style.inputBox}`}
                      value={state.inputValue}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          inputValue: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    onClick={storeClientCode}
                    disabled={!state.inputValue}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          ) : state.broker && state.clientCode ? (
            <div className="relative bg-[#ffffff] w-full md:min-w-[90vw] h-[560px] overflow-auto grid grid-cols-1 py-6  lg:grid-cols-2 rounded-[10px]  lg:py-8">
              <div
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    clientCode: "",
                    apiKey: "",
                    qrValue: "",
                  }));
                }}
              >
                <div>
                  <ArrowLeft size={25} />
                </div>
                <div className="hidden md:flex ">Back</div>
              </div>

              <div className="mt-6 px-[28px]  h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
                <div
                  className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
                >
                  Step-by-Step Guide:
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    {" "}
                    In the next field, enter your My2PIN, which is your date of
                    birth. Ensure you use the correct format as specified (e.g.,
                    YYYYMMDD).
                  </div>
                </div>
                <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
                  <div>&#x2022;</div>{" "}
                  <div className="ml-2">
                    Type in your account password. Remember, this password is
                    only used for verification purposes and is not stored in the
                    backend, hence you will be prompted for password again once
                    the password token validity expires.
                  </div>
                </div>
              </div>

              <div className="mt-10 md:flex flex-col space-y-4 px-12 ">
                <div className="text-3xl font-bold">Connect {state.broker}</div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    My2Pin :{" "}
                  </div>
                  <div className={style.inputStartDiv}>
                    <input
                      type="number"
                      placeholder="Enter your my2pin"
                      className={`${style.inputBox}`}
                      value={state.apiKey}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          apiKey: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col items-center">
                  <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
                    Password :{" "}
                  </div>
                  <div className={`relative ${style.inputStartDiv} `}>
                    <input
                      id="pass"
                      placeholder="Password"
                      type={state.showPassword ? "text" : "password"}
                      value={state.password}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                      className={`${style.inputBox}`}
                    />
                    {state.showPassword ? (
                      <EyeIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showPassword: false }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    ) : (
                      <EyeOffIcon
                        onClick={() =>
                          setState((prev) => ({ ...prev, showPassword: true }))
                        }
                        className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
                      />
                    )}
                  </div>
                </div>

                <div className="">
                  <button
                    className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-[14px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
                    disabled={!state.apiKey || !state.password}
                    onClick={handleSubmit}
                  >
                    {state.loading === true ? (
                      <span className="flex flex-row justify-center ">
                        <svg
                          className="h-7 w-7 text-[#ffffff] animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      `Connect ${state.broker}`
                    )}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
};

//   ret
//     <Drawer open={brokerModel} onOpenChange={setBrokerModel}>
//       <DrawerContent className="focus:outline-none max-h-[560px] animate-modal">
//         <div
//           onClick={() => {
//             setBrokerModel(false);
//             resetState();
//           }}
//           className="absolute top-4 right-3 flex items-center justify-center "
//         >
//           <XIcon className="h-5 w-5 text-gray-900" />
//         </div>

//         <div className=" py-2 h-full flex flex-col overflow-y-auto no-scrollbar">
//           <Toaster position="top-center" reverseOrder={true} />
//           {!state.broker && !state.clientCode ? (
//             <AnimatePresence mode="wait">
//               {!showLetUsKnow ? (
//                 <motion.div
//                   key="brokerSelection"
//                   initial={{ opacity: 0 }}
//                   animate={{ opacity: 1 }}
//                   exit={{ opacity: 0 }}
//                 >
//                   <div className="relative min-w-[340px] rounded-[12px]  py-6 ">
//                     <div className="flex flex-col space-y-3 items-center justify-center text-black text-center mb-4">
//                       <span className="text-black text-center text-[20px] font-bold font-poppins">
//                         Select Your Broker
//                       </span>
//                       <span className="text-[#000000]/70 font-poppins text-[14px] text-center leading-[20px] font-medium">
//                         Please connect your broker for seamless execution of
//                         your advisor's recommendation.
//                       </span>
//                     </div>
//                     <div className="grid grid-cols-4 max-h-[190px] overflow-auto custom-scroll gap-2 px-4">
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({
//                               ...prev,
//                               broker: "IIFL Securities",
//                             }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={IfflLogo}
//                               alt="IIFL Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             IIFL
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <a
//                           href={`https://smartapi.angelbroking.com/publisher-login?api_key=${angelOneApiKey}`}
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={AngelOneLogo}
//                               alt="AngelOne Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             AngelOne
//                           </span>
//                         </a>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={connectZerodha}
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Zerodha}
//                               alt="Zerodha"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             {state.zerodhaLoading ? (
//                               <LoadingSpinner />
//                             ) : (
//                               "Zerodha"
//                             )}
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({
//                               ...prev,
//                               broker: "ICICI Direct",
//                             }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Icici}
//                               alt="ICICI Direct"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             ICICI
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({ ...prev, broker: "Upstox" }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Upstox}
//                               alt="Upstox Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             Upstox
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({ ...prev, broker: "Kotak" }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Kotak}
//                               alt="Kotak Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             Kotak
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({
//                               ...prev,
//                               broker: "Hdfc Securities",
//                             }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Hdfc}
//                               alt="Hdfc Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             HDFC
//                           </span>
//                         </div>
//                       </div>
//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({ ...prev, broker: "Dhan" }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Dhan}
//                               alt="Dhan Logo"
//                               className="w-8 h-8 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             Dhan
//                           </span>
//                         </div>
//                       </div>

//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({
//                               ...prev,
//                               broker: "aliceBlue",
//                             }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={AliceBlue}
//                               alt="aliceBlue Logo"
//                               className="w-12 h-16 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             AliceBlue
//                           </span>
//                         </div>
//                       </div>

//                       <div className="w-full">
//                         <div
//                           onClick={() =>
//                             setState((prev) => ({ ...prev, broker: "Fyers" }))
//                           }
//                           className="group flex flex-col items-center justify-center p-3 rounded-lg hover:bg-gray-100 transition-all duration-200 cursor-pointer"
//                         >
//                           <div className="w-14 h-14 mb-2 rounded-full bg-gray-50 flex items-center justify-center group-hover:bg-white transition-colors duration-200">
//                             <img
//                               src={Fyers}
//                               alt="fyers Logo"
//                               className="w-10 h-10 object-contain"
//                             />
//                           </div>
//                           <span className="text-[12px] font-medium text-gray-700 group-hover:text-gray-900 transition-colors duration-200">
//                             Fyers
//                           </span>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="w-full px-4 pt-5 ">
//                       <div className="group flex flex-col space-y-2 bg-gray-50 text-gray-800 border border-gray-200 p-4 rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200">
//                         <div className="flex flex-row justify-between items-center">
//                           <span className="text-gray-800 group-hover:text-gray-900 text-[14px] font-semibold font-poppins">
//                             Can't find your Broker?
//                           </span>
//                           <button
//                             onClick={() => setShowLetUsKnow(true)}
//                             className="flex items-center text-[12px] font-medium text-[#55A7F1] transition-colors duration-200 font-poppins"
//                           >
//                             Click here if your broker is not listed
//                             <ChevronRight className="ml-1 h-4 w-4" />
//                           </button>
//                         </div>
//                         <span className="text-gray-600 group-hover:text-gray-700 text-[12px] font-medium font-poppins">
//                           You will continue to receive recommendations from your
//                           advisor on your email and/or whatsapp/telegram. As we
//                           add more brokers, you can visit the page and add your
//                           broker later.
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </motion.div>
//               ) : (
//                 <motion.div
//                   key="letUsKnow"
//                   initial={{ opacity: 0 }}
//                   animate={{ opacity: 1 }}
//                   exit={{ opacity: 0 }}
//                 >
//                   <div className="flex flex-col px-4 sm:px-6 items-center justify-center text-black text-center w-full max-w-[720px] min-h-[465px] mx-auto">
//                     {!showSuccess && (
//                       <button
//                         onClick={() => setShowLetUsKnow(false)}
//                         className="self-start flex items-center text-sm font-semibold text-gray-600 transition-colors mb-4"
//                       >
//                         <ChevronLeft className="mr-1" size={22} />
//                       </button>
//                     )}

//                     {showSuccess ? (
//                       <div className="flex flex-col items-center justify-center h-full w-full max-w-sm">
//                         <div className="flex items-center justify-center mb-4">
//                           <img
//                             src={Checked}
//                             alt="Success"
//                             width={48}
//                             height={48}
//                           />
//                         </div>
//                         <h2 className="text-xl sm:text-2xl mb-2 font-bold font-poppins text-black">
//                           Thank You!
//                         </h2>
//                         <p className="text-center font-poppins text-gray-600 text-sm sm:text-base mb-6 max-w-[300px]">
//                           We've received your broker information. We'll keep you
//                           updated on our progress.
//                         </p>
//                         <button
//                           onClick={resetStates}
//                           className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200 text-sm sm:text-base font-poppins font-semibold"
//                         >
//                           Back to Broker Selection
//                         </button>
//                       </div>
//                     ) : (
//                       <>
//                         <div className="flex flex-col items-center w-full mb-4">
//                           <h2 className="text-xl sm:text-2xl mb-2 font-bold font-poppins text-black">
//                             Find Your Broker
//                           </h2>
//                           <p className="text-center font-poppins text-gray-600 text-sm sm:text-base">
//                             We're expanding our supported brokers. Help us by
//                             searching for yours.
//                           </p>
//                         </div>
//                         <div className="relative mb-4 w-full">
//                           <input
//                             type="text"
//                             placeholder="Search for your broker"
//                             className="w-full p-2 pl-8 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 text-sm font-poppins"
//                             onChange={handleSearch}
//                             value={searchTerm}
//                           />
//                           <Search
//                             className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
//                             size={16}
//                           />
//                         </div>
//                         <div className="w-full h-[250px] sm:h-[400px] font-poppins overflow-y-auto font-medium border border-gray-200 rounded-md bg-white mb-4 relative no-scrollbar">
//                           {isLoading ? (
//                             <div className="absolute inset-0 flex items-center justify-center">
//                               <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-black"></div>
//                             </div>
//                           ) : error ? (
//                             <p className="text-center py-8 font-poppins text-red-500">
//                               {error}
//                             </p>
//                           ) : filteredBrokers.length === 0 ? (
//                             <p className="text-center py-8 font-poppins text-gray-600">
//                               No brokers found
//                             </p>
//                           ) : (
//                             <ul className="w-full">
//                               {(searchTerm ? filteredBrokers : brokers).map(
//                                 (broker) => (
//                                   <li
//                                     key={broker.Name}
//                                     className={`px-4 py-3 text-left hover:bg-gray-100 transition-colors duration-150 cursor-pointer ${
//                                       selectedBrokerName === broker.Name
//                                         ? "bg-gray-200"
//                                         : ""
//                                     }`}
//                                     onClick={() =>
//                                       handleSelectBroker(broker.Name)
//                                     }
//                                   >
//                                     <p className="font-poppins text-sm sm:text-base text-gray-700">
//                                       {broker.Name}
//                                     </p>
//                                   </li>
//                                 )
//                               )}
//                             </ul>
//                           )}
//                         </div>
//                         <div className="w-full flex justify-end">
//                           <button
//                             onClick={handleBrokerSubmit}
//                             disabled={!selectedBrokerName}
//                             className="w-full sm:w-1/3 px-4 py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors duration-200 text-sm sm:text-base font-poppins font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
//                           >
//                             Submit
//                           </button>
//                         </div>
//                       </>
//                     )}
//                   </div>
//                 </motion.div>
//               )}
//             </AnimatePresence>
//           ) : state.broker === "Dhan" ? (
//             <div className="h-[90%] md:h-[560px] pt-6 pb-2 relative bg-[#ffffff] w-full md:w-[90%] grid grid-cols-1 overflow-y-auto no-scrollbar ">
//               <div className="grid grid-cols-1 ">
//                 <div className="relative ">
//                   <div
//                     className="flex flex-row items-center space-x-2   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
//                     onClick={() => {
//                       setState((prev) => ({ ...prev, broker: "" }));
//                     }}
//                   >
//                     <div>
//                       <ArrowLeft size={20} />
//                     </div>
//                     <div className="text-[12px] flex md:text-[16px] ">Back</div>
//                   </div>
//                   <div className="mt-12 md:mt-6">
//                     <div className="mb-8  overflow-hidden max-h-[200px] max-w-[350px] mx-auto cursor-pointer">
//                       <YouTube
//                         videoId={`MhAfqNQKSrQ`}
//                         className={`videoIframe rounded-xl  `}
//                         opts={opts}
//                         onReady={handlePlayerReady}
//                         ref={playerRef}
//                       />
//                     </div>
//                     <div onClick={toggleOpen} className="px-3">
//                       <div className="flex items-center mb-4 ml-2 justify-between w-full text-lg text-black font-semibold font-poppins leading-[22px]">
//                         Steps to get the Client Code and Access Token for Dhan:
//                         <div className="mr-4 mb-5 ">
//                           {state.isOpen ? (
//                             <ChevronUp size={20} strokeWidth={3} />
//                           ) : (
//                             <ChevronDown size={20} strokeWidth={3} />
//                           )}
//                         </div>
//                       </div>
//                       {state.isOpen && (
//                         <div>
//                           <div className="flex flex-row text-[15px] mb-0.5 mt-4 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                             <div>1.</div>{" "}
//                             <div className="ml-2">
//                               {" "}
//                               Go to
//                               <div className="inline-flex items-center flex-wrap">
//                                 <a
//                                   href="http://login.dhan.co"
//                                   target="_blank"
//                                   rel="noopener noreferrer"
//                                   className="px-2 text-[#0000FF] "
//                                 >
//                                   http://login.dhan.co
//                                 </a>
//                                 <img
//                                   src={Copy}
//                                   alt="Copy"
//                                   className="w-4 h-4 cursor-pointer"
//                                   onClick={() =>
//                                     handleCopy(` http://login.dhan.co`)
//                                   }
//                                 />
//                               </div>{" "}
//                               log in using the QR code option.
//                             </div>
//                           </div>

//                           <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                             <div>2.</div>{" "}
//                             <div className="ml-2">
//                               Click on your profile picture and choose "My
//                               Profile on Dhan". Under the Profile details,
//                               you'll find the "Client ID".
//                             </div>
//                           </div>
//                           <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                             <div>3.</div>{" "}
//                             <div className="ml-2">
//                               Then, select "Dhan HQ Trading APIs" from the menu.
//                             </div>
//                           </div>
//                           <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                             <div>4.</div>{" "}
//                             <div className="ml-2 items-center">
//                               {" "}
//                               To generate an access token, click on "+ New
//                               Token," enter a name for your app, set the
//                               validity to 30 days, and click "Generate Token."
//                             </div>
//                           </div>
//                           <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                             <div>5.</div>{" "}
//                             <div className="ml-2">
//                               Copy the access token and paste it into the
//                               designated field.
//                             </div>
//                           </div>
//                         </div>
//                       )}
//                     </div>{" "}
//                   </div>
//                 </div>
//                 <div className="flex flex-col space-y-4">
//                   <h1 className="text-2xl font-bold py-1 px-3">
//                     Connect {state.broker}
//                   </h1>
//                   <div className="space-y-4 px-3">
//                     <div className="flex flex-col md:flex-row md:items-center px-3">
//                       <label
//                         htmlFor="clientCode"
//                         className="w-full md:w-1/3 text-lg font-semibold mb-1 "
//                       >
//                         Client Id:
//                       </label>
//                       <input
//                         id="clientCode"
//                         type="text"
//                         value={state.clientCode}
//                         onChange={(e) =>
//                           setState((prev) => ({
//                             ...prev,
//                             clientCode: e.target.value,
//                           }))
//                         }
//                         placeholder="Client Id"
//                         className="w-full md:w-2/3 px-4 py-2 border border-gray-300 rounded-md  "
//                       />
//                     </div>
//                     <div className="flex flex-col md:flex-row md:items-center px-3">
//                       <label
//                         htmlFor="accessCode"
//                         className="w-full md:w-1/3 text-lg font-semibold mb-2 md:mb-0"
//                       >
//                         Access Token:
//                       </label>
//                       <div className="relative w-full md:w-2/3">
//                         <input
//                           id="accessCode"
//                           value={state.jwtToken}
//                           onChange={(e) =>
//                             setState((prev) => ({
//                               ...prev,
//                               jwtToken: e.target.value,
//                             }))
//                           }
//                           placeholder="Access Token"
//                           className="w-full px-4 py-2 border border-gray-300 rounded-md  "
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="pt-3">
//                     <button
//                       className=" w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
//                       disabled={!state.clientCode || !state.jwtToken}
//                       onClick={handleSubmit}
//                     >
//                       {state.loading ? (
//                         <span className="flex justify-center items-center">
//                           <svg
//                             className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                             xmlns="http://www.w3.org/2000/svg"
//                             fill="none"
//                             viewBox="0 0 24"
//                           >
//                             <circle
//                               className="opacity-25"
//                               cx="12"
//                               cy="12"
//                               r="10"
//                               stroke="currentColor"
//                               strokeWidth="4"
//                             ></circle>
//                             <path
//                               className="opacity-75"
//                               fill="currentColor"
//                               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                             ></path>
//                           </svg>
//                         </span>
//                       ) : (
//                         `Connect ${state.broker}`
//                       )}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ) : state.broker === "ICICI Direct" || state.broker === "Upstox" ? (
//             <div className="h-[90%] px-3  md:h-[560px] pt-6 pb-2 relative bg-[#ffffff] w-full md:w-[90%] grid grid-cols-1 md:grid-cols-2 rounded-[10px] no-scrollbar">
//               <div
//                 className="flex flex-row items-center space-x-2   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
//                 onClick={() => {
//                   setState((prev) => ({ ...prev, broker: "" }));
//                 }}
//               >
//                 <div>
//                   <ArrowLeft size={20} />
//                 </div>
//                 <div className="text-[12px] flex md:text-[16px] ">Back</div>
//               </div>
//               {state.broker === "Upstox" ? (
//                 <div className="h-full pt-4  ">
//                   <div className="mb-8 rounded-xl ">
//                     <YouTube
//                       videoId={`yfTXrjl0k3E`}
//                       className={`videoIframe rounded-xl  `}
//                       opts={opts}
//                       onReady={handlePlayerReady}
//                       ref={playerRef}
//                     />
//                   </div>

//                   <div
//                     className="flex justify-between items-center cursor-pointer mb-4"
//                     onClick={toggleOpen}
//                   >
//                     <div className="flex items-center justify-between w-full text-lg text-black font-semibold font-poppins leading-[22px]">
//                       <span>
//                         Steps to Obtain API and Secret Key for Upstox:
//                       </span>
//                       <div className="mr-5 mb-5 ">
//                         {state.isOpen ? (
//                           <ChevronUp size={20} strokeWidth={3} />
//                         ) : (
//                           <ChevronDown size={20} strokeWidth={3} />
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                   {state.isOpen && (
//                     <div>
//                       <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                         <div>1.</div>
//                         <div className="ml-3">
//                           {" "}
//                           Visit
//                           <div className=" inline-flex items-center flex-wrap">
//                             <a
//                               href="https://shorturl.at/plWYJ"
//                               target="_blank"
//                               rel="noopener noreferrer"
//                               className="px-2 text-[#0000FF] "
//                             >
//                               https://shorturl.at/plWYJ
//                             </a>
//                             <img
//                               src={Copy}
//                               alt="Copy"
//                               className="w-4 h-4 mr-2 cursor-pointer"
//                               onClick={() =>
//                                 handleCopy(`https://shorturl.at/plWYJ`)
//                               }
//                             />
//                           </div>
//                           and log in using your phone number. Verify your
//                           identity with the OTP and continue.
//                         </div>
//                       </div>
//                       <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                         <div>2.</div>
//                         <div className="ml-2">
//                           Enter your 6-digit PIN and continue.
//                         </div>
//                       </div>
//                       <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                         <div>3.</div>{" "}
//                         <div className="ml-2">
//                           Click on the "New App" button. Fill in the "App Name"
//                           field with "{whiteLabelText}" or a name of your
//                           choice. Enter the "Redirect URL" as
//                           <span className=" text-[#0000FF] inline-flex items-center flex-wrap">
//                             {brokerConnectRedirectURL}
//                             <img
//                               src={Copy}
//                               alt="Copy"
//                               className="w-4 h-4 ml-2 cursor-pointer"
//                               onClick={() =>
//                                 handleCopy(`${brokerConnectRedirectURL}`)
//                               }
//                             />
//                           </span>
//                           You can skip the Postback URL and Description as they
//                           are optional. Accept the Terms & Conditions and click
//                           on the "Continue" button. Please ensure that the
//                           "Redirect URL" is entered correctly as mentioned
//                           above.
//                         </div>
//                       </div>
//                       <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                         <div>4.</div>{" "}
//                         <div className="ml-2">
//                           Review the details (make sure you don't have more than
//                           2 apps) and click on the "Confirm Plan" button. Your
//                           API is now ready! Click on the "Done" button.
//                         </div>
//                       </div>
//                       <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                         <div>5.</div>{" "}
//                         <div className="ml-2">
//                           Click on the newly created app, copy your API and
//                           Secret Key, and enter these details on the designated
//                           screen.
//                         </div>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               ) : (
//                 <div className=" h-full pt-4 ">
//                   <div className="mb-8 rounded-xl  ">
//                     <YouTube
//                       videoId={`XFLjL8hOctI`}
//                       className={`videoIframe rounded-xl  `}
//                       opts={opts}
//                       onReady={handlePlayerReady}
//                       ref={playerRef}
//                     />
//                   </div>
//                   <div onClick={toggleOpen}>
//                     <div className="flex items-center justify-between w-full text-lg text-black font-semibold font-poppins leading-[22px]">
//                       <span>Steps to Obtain API and Secret Key for ICICI:</span>
//                       <div className="mr-5 mb-5 ">
//                         {state.isOpen ? (
//                           <ChevronUp size={20} strokeWidth={3} />
//                         ) : (
//                           <ChevronDown size={20} strokeWidth={3} />
//                         )}
//                       </div>
//                     </div>

//                     {state.isOpen && (
//                       <div>
//                         <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                           <div>1.</div>
//                           <div className="ml-3">
//                             Visit
//                             <div className="inline-flex items-center flex-wrap">
//                               <a
//                                 href="https://api.icicidirect.com/apiuser/home"
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="px-2 text-[#0000FF]"
//                               >
//                                 https://api.icicidirect.com/apiuser/home
//                               </a>
//                               <img
//                                 src={Copy}
//                                 alt="Copy"
//                                 className="w-4 h-4 ml-2 cursor-pointer"
//                                 onClick={() =>
//                                   handleCopy(
//                                     `https://api.icicidirect.com/apiuser/home`
//                                   )
//                                 }
//                               />
//                             </div>
//                             and log in using your username and password. Verify
//                             your identity with the OTP and submit.
//                           </div>
//                         </div>
//                         <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                           <div>2.</div>{" "}
//                           <div className="ml-2">
//                             Click on the "Register an App" tab, then fill in the
//                             "App Name" field with "{whiteLabelText}" or a name
//                             of your choice. Enter the "Redirect URL" as
//                             <span className="px-2 text-[#0000FF] inline-flex items-center flex-wrap">
//                               {server.ccxtServer.baseUrl}
//                               icici/auth-callback/test{" "}
//                               <img
//                                 src={Copy}
//                                 alt="Copy"
//                                 className="w-4 h-4 ml-2 cursor-pointer"
//                                 onClick={() =>
//                                   handleCopy(`${server.ccxtServer.baseUrl}
//                             icici/auth-callback/test`)
//                                 }
//                               />
//                             </span>
//                             and click "Submit". Please ensure that "redirect
//                             URL" entered correctly as mentioned above.
//                           </div>
//                         </div>
//                         <div className="flex flex-row text-[13px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                           <div>3.</div>{" "}
//                           <div className="ml-2">
//                             Navigate to the "View Apps" tab and copy your API
//                             and Secret Key- enter these details in the screen.
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               )}

//               <div className="mt-6 md:flex flex-col space-y-4 px-2 ">
//                 <div className="text-2xl font-bold">Connect {state.broker}</div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     Api Key :{" "}
//                   </div>
//                   <div className={`relative ${style.inputStartDiv} `}>
//                     <input
//                       placeholder="Api Key"
//                       type={state.showApiKey ? "text" : "password"}
//                       value={state.apiKey}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           apiKey: e.target.value,
//                         }))
//                       }
//                       className={`${style.inputBox}`}
//                     />
//                     {state.showApiKey ? (
//                       <EyeIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showApiKey: false }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     ) : (
//                       <EyeOffIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showApiKey: true }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     )}
//                   </div>
//                 </div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     Secret Key :{" "}
//                   </div>
//                   <div className={`relative ${style.inputStartDiv} `}>
//                     <input
//                       placeholder="Secret Key"
//                       type={state.showSecretKey ? "text" : "password"}
//                       value={state.secretKey}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           secretKey: e.target.value,
//                         }))
//                       }
//                       className={`${style.inputBox}`}
//                     />
//                     {state.showSecretKey ? (
//                       <EyeIcon
//                         onClick={() =>
//                           setState((prev) => ({
//                             ...prev,
//                             showSecretKey: false,
//                           }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     ) : (
//                       <EyeOffIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showSecretKey: true }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     )}
//                   </div>
//                 </div>

//                 <div className="">
//                   <button
//                     className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
//                     disabled={!state.apiKey || !state.secretKey}
//                     onClick={updateSecretKey}
//                   >
//                     {state.loading === true ? (
//                       <LoadingSpinner />
//                     ) : (
//                       `Connect ${state.broker}`
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : state.broker === "Hdfc Securities" ? (
//             <div className="h-[90%] md:h-[560px] px-3  pt-6 pb-2 relative bg-[#ffffff] w-full md:w-[90%] grid grid-cols-1 md:grid-cols-2 rounded-[10px] ">
//               <div
//                 className="flex flex-row items-center  space-x-2  text-black/80 absolute  md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
//                 onClick={() => {
//                   setState((prev) => ({ ...prev, broker: "" }));
//                 }}
//               >
//                 <div className="flex gap-1">
//                   <div>
//                     <ArrowLeft size={20} />
//                   </div>
//                   <div className="text-[12px] flex md:text-[16px] ">Back</div>
//                 </div>
//               </div>

//               <div className="mb-8 rounded-xl mt-3 ">
//                 <YouTube
//                   videoId={`iziwR2zLLvk`}
//                   className={`videoIframe rounded-xl `}
//                   opts={opts}
//                   onReady={handlePlayerReady}
//                   ref={playerRef}
//                 />
//               </div>

//               <div onClick={toggleOpen}>
//                 <div className="flex items-center ml-2 justify-between w-full text-lg text-black font-semibold font-poppins leading-[22px]">
//                   Steps to Obtain API and Secret Key for Hdfc Securities:
//                   <div className="mr-4 mb-5 ">
//                     {state.isOpen ? (
//                       <ChevronUp size={20} strokeWidth={3} />
//                     ) : (
//                       <ChevronDown size={20} strokeWidth={3} />
//                     )}
//                   </div>
//                 </div>
//                 {state.isOpen && (
//                   <div>
//                     <div className="flex flex-row text-[15px] mb-0.5 mt-4 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                       <div>1.</div>{" "}
//                       <div className="ml-2">
//                         {" "}
//                         Go to
//                         <div className="inline-flex items-center flex-wrap">
//                           <a
//                             href="https://developer.hdfcsec.com/"
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             className="px-2 text-[#0000FF] "
//                           >
//                             https://developer.hdfcsec.com/
//                           </a>
//                           <img
//                             src={Copy}
//                             alt="Copy"
//                             className="w-4 h-4 cursor-pointer"
//                             onClick={() =>
//                               handleCopy(` https://developer.hdfcsec.com/`)
//                             }
//                           />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                       <div>2.</div>{" "}
//                       <div className="ml-2">
//                         Log in with your ID, password, and OTP.
//                       </div>
//                     </div>
//                     <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                       <div>3.</div>{" "}
//                       <div className="ml-2">Accept the *Risk Disclosure*.</div>
//                     </div>
//                     <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                       <div>4.</div>{" "}
//                       <div className="ml-2">
//                         Click *Create* to make a new app. Enter app name,
//                         redirect URL :
//                         <span className="px-2 text-[#0000FF] inline-flex items-center flex-wrap">
//                           {brokerConnectRedirectURL}
//                           <img
//                             src={Copy}
//                             alt="Copy"
//                             className="w-4 h-4 ml-2 cursor-pointer"
//                             onClick={() =>
//                               handleCopy(`${brokerConnectRedirectURL}`)
//                             }
//                           />
//                         </span>
//                         and description, then click *Create*.
//                       </div>
//                     </div>
//                     <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                       <div>5.</div>{" "}
//                       <div className="ml-2">
//                         Copy the *API* and *Secret Key* and paste them into the
//                         {whiteLabelText} platform to connect your broker.
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               <div className="mt-6 md:flex flex-col space-y-4 px-2 ">
//                 <div className="text-2xl font-bold">Connect {state.broker}</div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     Api Key :{" "}
//                   </div>
//                   <div className={`relative ${style.inputStartDiv} `}>
//                     <input
//                       placeholder="Api Key"
//                       type={state.showApiKey ? "text" : "password"}
//                       value={state.apiKey}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           apiKey: e.target.value,
//                         }))
//                       }
//                       className={`${style.inputBox}`}
//                     />
//                     {state.showApiKey ? (
//                       <EyeIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showApiKey: false }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     ) : (
//                       <EyeOffIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showApiKey: true }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     )}
//                   </div>
//                 </div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     Secret Key :{" "}
//                   </div>
//                   <div className={`relative ${style.inputStartDiv} `}>
//                     <input
//                       placeholder="Secret Key"
//                       type={state.showSecretKey ? "text" : "password"}
//                       value={state.secretKey}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           secretKey: e.target.value,
//                         }))
//                       }
//                       className={`${style.inputBox}`}
//                     />
//                     {state.showSecretKey ? (
//                       <EyeIcon
//                         onClick={() =>
//                           setState((prev) => ({
//                             ...prev,
//                             showSecretKey: false,
//                           }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     ) : (
//                       <EyeOffIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showSecretKey: true }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4 cursor-pointer"
//                       />
//                     )}
//                   </div>
//                 </div>

//                 <div className="">
//                   <button
//                     className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
//                     disabled={!state.apiKey || !state.secretKey}
//                     onClick={updateSecretKey}
//                   >
//                     {state.loading === true ? (
//                       <LoadingSpinner />
//                     ) : (
//                       `Connect ${state.broker}`
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : state.broker && !state.clientCode ? (
//             <div className=" bg-[#ffffff] w-full overflow-auto grid grid-cols-1 pt-2 pb-2  lg:grid-cols-2 rounded-[10px]  lg:py-8">
//               <div
//                 className="flex flex-row items-center space-x-2  text-black/80 absolute  left-4 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
//                 onClick={() => {
//                   setState((prev) => ({ ...prev, broker: "", clientCode: "" }));
//                 }}
//               >
//                 <div>
//                   <ArrowLeft size={20} />
//                 </div>
//                 <div className="text-[12px] flex md:text-[16px] ">Back</div>
//               </div>

//               <div className="mt-4 px-2 md:h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
//                 <div
//                   className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
//                 >
//                   Step-by-Step Guide:
//                 </div>
//                 <div className="text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[36px] text-black font-normal font-poppins ">
//                   1. First enter your unique client code in the designated
//                   field.
//                 </div>
//               </div>

//               <div className="mt-10 px-2 md:flex flex-col space-y-3 ">
//                 <div className="text-[20px] font-semibold font-poppins">
//                   Connect {state.broker}
//                 </div>
//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-[16px] font-semibold font-poppins">
//                     Client Code :{" "}
//                   </div>
//                   <div className={style.inputStartDiv}>
//                     <input
//                       id="clientCode"
//                       type="text"
//                       placeholder="Client Code"
//                       className={`${style.inputBox}`}
//                       value={state.inputValue}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           inputValue: e.target.value,
//                         }))
//                       }
//                     />
//                   </div>
//                 </div>

//                 <div className="">
//                   <button
//                     className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
//                     onClick={storeClientCode}
//                     disabled={!state.inputValue}
//                   >
//                     Proceed
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : state.broker && state.clientCode ? (
//             <div className="h-[560px] pt-8 pb-2 relative bg-[#ffffff] w-full  grid grid-cols-1 md:grid-cols-2 rounded-[10px]  overflow-y-auto">
//               <div
//                 className="flex flex-row items-center space-x-2   text-black/80 absolute top-0 left-0 md:left-6 m-1 font-bold hover:text-neutral-400  ease-linear duration-150 cursor-pointer"
//                 onClick={() => {
//                   setState((prev) => ({
//                     ...prev,
//                     clientCode: "",
//                     apiKey: "",
//                     qrValue: "",
//                   }));
//                 }}
//               >
//                 <div>
//                   <ArrowLeft size={20} />
//                 </div>
//                 <div className="text-[12px] flex md:text-[16px] ">Back</div>
//               </div>

//               <div className="px-2 h-full pt-4 xl:pt-0 xl:px-12 xl:border-r xl:border-[#000000]/20">
//                 <div
//                   className={`mb-2 text-lg text-black font-semibold font-poppins leading-[22px]`}
//                 >
//                   Step-by-Step Guide:
//                 </div>
//                 <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                   <div>&#x2022;</div>{" "}
//                   <div className="ml-2">
//                     {" "}
//                     In the next field, enter your My2PIN, which is your date of
//                     birth. Ensure you use the correct format as specified (e.g.,
//                     YYYYMMDD).
//                   </div>
//                 </div>
//                 <div className="flex flex-row text-[15px] mb-0.5 leading-[22px] md:text-base md:leading-[28px] text-black font-normal font-poppins ">
//                   <div>&#x2022;</div>{" "}
//                   <div className="ml-2">
//                     Type in your account password. Remember, this password is
//                     only used for verification purposes and is not stored in the
//                     backend, hence you will be prompted for password again once
//                     the password token validity expires.
//                   </div>
//                 </div>
//               </div>

//               <div className="mt-6 md:flex flex-col space-y-4 px-2">
//                 <div className="text-2xl font-bold">Connect {state.broker}</div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     My2Pin :{" "}
//                   </div>
//                   <div className={style.inputStartDiv}>
//                     <input
//                       type="number"
//                       placeholder="Enter your my2pin"
//                       className={`${style.inputBox}`}
//                       value={state.apiKey}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           apiKey: e.target.value,
//                         }))
//                       }
//                     />
//                   </div>
//                 </div>

//                 <div className="flex md:flex-row flex-col items-center">
//                   <div className="w-full md:w-[30%] text-lg font-semibold font-poppins">
//                     Password :{" "}
//                   </div>
//                   <div className={`relative ${style.inputStartDiv} `}>
//                     <input
//                       id="pass"
//                       placeholder="Password"
//                       type={state.showPassword ? "text" : "password"}
//                       value={state.password}
//                       onChange={(e) =>
//                         setState((prev) => ({
//                           ...prev,
//                           password: e.target.value,
//                         }))
//                       }
//                       className={`${style.inputBox}`}
//                     />
//                     {state.showPassword ? (
//                       <EyeIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showPassword: false }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
//                       />
//                     ) : (
//                       <EyeOffIcon
//                         onClick={() =>
//                           setState((prev) => ({ ...prev, showPassword: true }))
//                         }
//                         className="absolute top-[25px] right-2 text-[#000000]/60 w-4 h-4"
//                       />
//                     )}
//                   </div>
//                 </div>

//                 <div className="">
//                   <button
//                     className="mt-6 w-full disabled:bg-[#000000]/30 disabled:cursor-not-allowed py-3 bg-black text-sm sm:text-[18px] lg:text-lg text-white font-medium rounded-md cursor-pointer"
//                     disabled={!state.apiKey || !state.password}
//                     onClick={handleSubmit}
//                   >
//                     {state.loading === true ? (
//                       <LoadingSpinner />
//                     ) : (
//                       `Connect ${state.broker}`
//                     )}
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ) : null}
//         </div>
//       </DrawerContent>
//     </Drawer>
//   );
// };

export default ConnectBroker;
