import React, { useEffect, useState } from "react";
import {
  Edit2Icon,
  EyeIcon,
  LayersIcon,
  PlusIcon,
  Trash2Icon,
  UsersIcon,
  XIcon,
  BarChart2Icon,
  AlertCircleIcon
} from "lucide-react";
import FormatDateTime from "../../utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import server from "../../utils/serverConfig";
import LoadingSpinner from "../../components/LoadingSpinner";
import toast from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion"

const GroupListTable = ({
  userId,
  allClientsData,
  allGroupsData,
  getAllGroupsData,
  getAllClientsData,
  searchQuery,
  openAddGroupModal,
}) => {
  const navigate = useNavigate();
  const [allData, setAlData] = useState(allGroupsData);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [groupMemberCounts, setGroupMemberCounts] = useState({});
  const [filteredGroupData, setFilteredGroupData] = useState(allData);

  useEffect(() => {
    setAlData(allGroupsData);
    const calculateMemberCounts = () => {
      const counts = {};
      allGroupsData.forEach((group) => {
        counts[group.groupName] = allClientsData.filter((client) =>
          client.groups.includes(group.groupName)
        ).length;
      });
      setGroupMemberCounts(counts);
    };
    calculateMemberCounts();
  }, [allGroupsData, allClientsData]);

  useEffect(() => {
    if (searchQuery) {
      const matchingClients = allClientsData.filter((client) =>
        client.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const matchingGroupNames = new Set(
        matchingClients.flatMap((client) => client.groups)
      );
      const filteredGroupsNames = allData.filter((group) =>
        matchingGroupNames.has(group.groupName)
      );
      setFilteredGroupData(filteredGroupsNames);
    } else {
      setFilteredGroupData(allGroupsData);
    }
  }, [searchQuery, allClientsData, allData]);

  const handleEdit = (group) => {
    setCurrentGroup(group);
    setShowEditModal(true);
  };

  const handleDelete = (group) => {
    setCurrentGroup(group);
    setShowDeleteModal(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${server.server.baseUrl}api/edit-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          groupId: currentGroup?._id,
          newGroupName: currentGroup?.groupName?.replace(/_/g, " "),
        }),
      });

      if (response.ok) {
        getAllGroupsData(userId);
        getAllClientsData(userId);
        setShowEditModal(false);
        toast.success("Group name updated successfully !!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#0066cc",
            secondary: "#FFFFFF",
          },
        });
      } else {
        setShowEditModal(false);
        toast.error("Failed to update group name !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFFFF",
          },
        });
      }
    } catch (error) {
      setShowEditModal(false);
      toast.error("Error in updating group name !", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFFFF",
        },
      });
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`${server.server.baseUrl}api/delete-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupId: currentGroup?._id }),
      });

      if (response.ok) {
        getAllGroupsData(userId);
        getAllClientsData(userId);
        setShowDeleteModal(false);
        toast.success(` ${currentGroup.groupName} deleted successfully !!`, {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#0066cc",
            secondary: "#FFFFFF",
          },
        });
      } else {
        setShowDeleteModal(false);
        toast.error("Failed to delete group!", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFFFF",
          },
        });
      }
    } catch (error) {
      setShowDeleteModal(false);
      toast.error("Error in deleting group!", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFFFF",
        },
      });
    }
  };

  const handleViewFile = (groupName) => {
    navigate(`/admin/client-list/${groupName}`);
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="w-full h-full max-h-[calc(100vh-225px)] lg:max-h-[calc(100vh-50px)] overflow-auto mb-4"
      >
        {/* Info Banner */}
        <div className="bg-[#0066cc] text-white mb-6 rounded-lg shadow-lg">
          <div className="max-w-7xl mx-auto px-6 py-6">
            <div className="flex items-start space-x-4">
              <div className="p-3 bg-white/10 rounded-lg">
                <BarChart2Icon className="h-7 w-7 text-white" />
              </div>
              <div>
                <h2 className="text-2xl font-semibold">Investment Portfolio Dashboard</h2>
                <p className="text-lg text-white/90 mt-2 font-poppins">
                  Manage client groups, monitor trade status, and track portfolio performance all in one place.
                </p>
                <div className="flex gap-6 mt-4">
                  <div className="flex items-center text-sm text-white/90">
                    <UsersIcon className="h-4 w-4 mr-2" />
                    <span>{allClientsData?.length || 0} Active Clients</span>
                  </div>
                  <div className="flex items-center text-sm text-white/90">
                    <LayersIcon className="h-4 w-4 mr-2" />
                    <span>{allGroupsData?.length || 0} Investment Groups</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-[5%] py-4 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <div className="flex justify-center">
                    <button
                      onClick={openAddGroupModal}
                      className="w-8 h-8 bg-[#0066cc] hover:bg-[#0052a3] rounded-lg flex items-center justify-center transition-all duration-200 transform hover:scale-105"
                    >
                      <PlusIcon className="text-white w-5 h-5" />
                    </button>
                  </div>
                </th>
                <th className="w-[25%] px-3 py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Group Name
                </th>
                <th className="w-[20%] px-3 py-4 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Members
                </th>
                <th className="w-[30%] px-3 py-4 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created
                </th>
                <th className="w-[20%] px-3 py-4 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {filteredGroupData &&
                filteredGroupData.map((item, index) => {
                  if (item.groupName.toLowerCase() !== "all client") {
                    return (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 transition-all duration-200"
                      >
                        <td className="px-3 py-4"></td>
                        <td className="px-3 py-4 text-sm text-gray-900 font-medium capitalize">
                          {item.groupName?.replace(/_/g, " ")}
                        </td>
                        <td className="px-3 py-4 text-sm text-right text-gray-600">
                          <div className="flex items-center justify-end">
                            <UsersIcon className="h-4 w-4 mr-2 text-gray-400" />
                            {groupMemberCounts[item.groupName] || 0}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-right text-gray-600">
                          {FormatDateTime(item.createdAt)}
                        </td>
                        <td className="px-3 py-4">
                          <div className="flex items-center justify-center space-x-3">
                            <button
                              onClick={() => handleViewFile(item.groupName)}
                              className="text-gray-400 hover:text-[#0066cc] transition-all duration-200 transform hover:scale-110"
                              title="View Group"
                            >
                              <EyeIcon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleEdit(item)}
                              className="text-gray-400 hover:text-[#0066cc] transition-all duration-200 transform hover:scale-110"
                              title="Edit Group"
                            >
                              <Edit2Icon className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDelete(item)}
                              className="text-gray-400 hover:text-red-500 transition-all duration-200 transform hover:scale-110"
                              title="Delete Group"
                            >
                              <Trash2Icon className="h-5 w-5" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        </div>

        {/* Edit Modal */}
        {showEditModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="relative bg-white rounded-xl shadow-xl w-[450px] p-6"
            >
              <button
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                onClick={() => setShowEditModal(false)}
              >
                <XIcon className="w-6 h-6" />
              </button>
              <div className="flex items-center space-x-3 mb-6">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <Edit2Icon className="h-5 w-5 text-[#0066cc]" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Edit Investment Group</h3>
              </div>
              <div className="space-y-4">
                <label className="block">
                  <span className="text-sm font-medium text-gray-700">Group Name</span>
                  <input
                    type="text"
                    value={currentGroup.groupName}
                    onChange={(e) =>
                      setCurrentGroup({
                        ...currentGroup,
                        groupName: e.target.value,
                      })
                    }
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-[#0066cc] focus:ring focus:ring-[#0066cc] focus:ring-opacity-50"
                  />
                </label>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowEditModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveEdit}
                  className="px-4 py-2 text-sm font-medium text-white bg-[#0066cc] rounded-lg hover:bg-[#0052a3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0066cc] transition-all duration-200"
                >
                  Update Group
                </button>
              </div>
            </motion.div>
          </div>
        )}

        {/* Delete Modal */}
        {showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="relative bg-white rounded-xl shadow-xl w-[450px] p-6"
            >
              <button
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                onClick={() => setShowDeleteModal(false)}
              >
                <XIcon className="w-6 h-6" />
              </button>
              <div className="flex items-center space-x-3 mb-6">
                <div className="p-2 bg-red-50 rounded-lg">
                  <AlertCircleIcon className="h-5 w-5 text-red-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">Delete Investment Group</h3>
              </div>
              <p className="text-sm text-gray-600">
                Are you sure you want to delete the group
                <span className="font-medium text-gray-900 ml-1">
                  {currentGroup.groupName}
                </span>
                ? This action cannot be undone.
              </p>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200"
                >
                  Delete Group
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default GroupListTable;