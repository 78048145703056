import React, { useState, useEffect } from "react";
import axios from "axios";
import { XIcon, TriangleAlert } from "lucide-react";

import server from "../../utils/serverConfig";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;

const style = {
  tableHead: "py-3 px-4 border-b text-left font-semibold text-gray-600 text-sm",
};

const StopLossProfitTarget = () => {
  const [allAlertNames, setAllAlertNames] = useState([]);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    axios
      .get(`${server.ccxtServer.baseUrl}misc/alert-names/${advisorTag}`)
      .then((response) => {
        setAllAlertNames(response?.data?.alertNames);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating Stop Loss & Profit Target", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const fetchAlertDetails = (alertName) => {
    setSelectedAlert(alertName);
    axios
      .get(`${server.ccxtServer.baseUrl}misc/alert-docs/${alertName}`)
      .then((response) => {
        setModalData(response?.data?.alertDocs);
        setIsModalOpen(true); // Open modal after data is fetched
      })
      .catch((error) => {
        console.error("Error fetching alert details", error);
      });
  };

  return (
    <div className="flex flex-col relative  w-full max-h-[calc(100vh-60px)] md:min-h-screen  bg-[#f9f9f9]">
      <div className="flex flex-col  w-full min-h-screen  bg-[#f9f9f9] ">
        {/* Header */}
        <div className="flex flex-row bg-[#131A29] text-white px-6 py-4 w-full justify-between">
          <div>
            <h2 className="text-xl font-semibold mb-2">
              Alert Advice Management System
            </h2>
            <p className="text-blue-100 text-sm">
              Track and manage investment recommendations across multiple
              channels. Monitor delivery status, resend communications, and
              ensure all clients receive timely updates.
            </p>
          </div>
          {/*<button*/}
          {/*  // onClick={() => setAlertModal(true)}*/}
          {/*  className="px-4 py-1 bg-yellow-500 h-[40px] text-white rounded-md  hover:bg-yellow-600"*/}
          {/*>*/}
          {/*  Alert Me*/}
          {/*</button>*/}
        </div>
        {loading === true ? (
          <div className="min-h-screen w-full bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
            <div className="w-10 h-10 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
          </div>
        ) : allAlertNames?.length !== 0 ? (
          <div>
            {allAlertNames?.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="flex flex-col py-2 bg-gray-50 hover:bg-gray-50 my-2 cursor-pointer"
                  onClick={() => fetchAlertDetails(ele)}
                >
                  <div className="text-base font-semibold px-12">{ele}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col space-y-4 lg:space-y-6 lg:pt-[20px] items-center justify-center h-[calc(100vh-240px)] lg:h-full">
            <div className="flex items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
              <TriangleAlert className="w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]" />
            </div>
            <div className="flex flex-col space-y-3 lg:space-y-3 items-center">
              <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                No Alert Available
              </div>
              <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                No alerts have been set by you yet
              </div>
            </div>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-3xl max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold">
                  Details for {selectedAlert}
                </h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="p-1 hover:bg-gray-100 rounded"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
              <div className="h-full overflow-y-auto">
                <table className="w-full">
                  <thead className="bg-gray-100 sticky top-0 z-10">
                    <tr>
                      <th className={style.tableHead}>Symbol</th>
                      <th className={style.tableHead}>Exchange</th>
                      <th className={style.tableHead}>Status</th>
                      <th className={style.tableHead}>Stop Loss</th>
                      <th className={style.tableHead}>Profit Target</th>
                    </tr>
                  </thead>
                  {modalData ? (
                    <tbody className="bg-white divide-y divide-gray-200">
                      {modalData?.map((ele, i) => {
                        return (
                          <tr
                            key={i}
                            className={` ${"bg-white hover:bg-gray-50"}`}
                          >
                            <td className="py-3 px-4 border-b font-poppins">
                              {ele?.stock}
                            </td>
                            <td className="py-3 px-4 border-b font-poppins">
                              {ele?.exchange}
                            </td>
                            <td className="py-3 px-4 border-b font-poppins capitalize">
                              {ele?.state}
                            </td>
                            <td className="py-3 px-4 border-b font-poppins">
                              {ele?.conditions?.price?.movement ===
                              "ltp_crossing_down"
                                ? ele?.conditions?.price?.level
                                : ele?.conditions?.price2?.movement ===
                                  "ltp_crossing_down"
                                ? ele?.conditions?.price2?.level
                                : "-"}
                            </td>
                            <td className="py-3 px-4 border-b font-poppins">
                              {ele?.conditions?.price?.movement ===
                              "ltp_crossing_up"
                                ? ele?.conditions?.price?.level
                                : ele?.conditions?.price1?.movement ===
                                  "ltp_crossing_up"
                                ? ele?.conditions?.price1?.level
                                : "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : (
                    <p>Loading...</p>
                  )}
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StopLossProfitTarget;
